.c-talk-shop {

  .button {
    font-size: 18px;
  }

  * {
    box-sizing: border-box;
  }
}

.c-talk-shop__section-wrapper {
  padding: 20px;
  text-align: center;
}

.c-talk-shop__section {
  max-width: map-get($mq-breakpoints, 'page_width');
  display: inline-block;
}

.c-talk-shop__subscribe-link {
  margin-bottom: .7em;
}