// for the grid of tool icons/links on the landing page

.category-links {
  background-color: $orange;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  padding-bottom: 32px;
  position: relative;
  z-index: 100;

  * {
    box-sizing: border-box;
  }

  @media screen and (min-width: 650px) {
    padding: 2em 0;
  }
}

.category-links__heading {
  font-size: 2em;
  margin: .5em 0;
  margin-bottom: 1em;
}

.category-links__row {
  display: block;
  font-size: 0;
  max-width: 1140px;
  margin: 0 auto;

  &.category-links__row--graphics {
    display: none;

    @media screen and (min-width: 650px) {
      display: block;
    }
  }
}

.category-links__link-wrapper {
  font-size: 2rem;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  padding: 1.5rem;
  margin-top: -1em;

  @media screen and (min-width: 650px) {
    width: 18%;
    font-size: 2vw;
    margin-top: -.75em;
  }

  @media screen and (min-width: 960px) {
    width: 14.2857142857%;
    font-size: 2.3rem;
  }
}

.category-links__link-text {
  background-color: $black;
  display: block;
  padding: .5em 0;
  margin-top: -.25em;
  border: 1px solid $black;
  line-height: 1.1;

  &:hover {
    color: $orange;
    background-color: $white;
  }

  @media screen and (min-width: 650px) {
    display: block;
    margin-top: 0;
    background-color: transparent;
    border: none;
    padding: .75em 0;
    margin-bottom: -2rem;

    &:hover {
      color: $black;
      background-color: transparent;
    }
  }
}

.category-links__link-graphic {
  @include transition();
  width: 100%;
  border: 1px solid rgba($white, .3);
  background-color: rgba($white, .08);
  font-size: 0;
  padding: 1rem;
  display: block;

  &:hover {
    border: 1px solid rgba($white, .7);
    background-color: rgba($white, .3);
  }

  object, svg {
    width: 100%;
    pointer-events: none;
  }
}
