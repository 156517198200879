.u-align--left {
  text-align: left;
}

.u-align--center {
  text-align: center;
}

.u-align--right {
  text-align: right;
}

.u-no-margin {
  margin: 0;
}

.u-nowrap {
  white-space: nowrap !important;
}