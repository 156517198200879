.c-talk-shop__video {
  text-align: left;
  margin: 1.5em 0;
}

.c-talk-shop__video__image {
  width: 100%;
  padding-top: 56.25%;
  height: 0;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.c-talk-shop__video__action {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.c-talk-shop__video__text {
  font-size: 1.5em;
  margin: 0;
  margin-top: .5em;
  line-height: 1.25;
}