.grid--flipper {
  padding-left: 6%;
  padding-right: 6%;

  svg {
    transition: all 1s linear;
  }

  .grid__item:hover,
  .hover {
    .twine-item__head,
    .twine-item__body,
    .twine-item__foot {
      opacity: 1;
    }
  }
}

.grid--zoom-in {
  .hexagon-container {
    opacity: 0;
    transform: scale(0.75);
    transition: opacity 0.2s linear, transform 0.2s linear;
  }
  .zoomed-in .hexagon-container {
    opacity:1;
    transform: scale(1);
  }
}
