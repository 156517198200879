#social {
  background-color: #252525;
  padding-bottom: 5em;
}

#social h1 {
  position:relative;
  z-index:6;
  background-color: #000;
  padding: 0.75em 1em;
}

.loading-gif {
  max-width:229px;
  width: auto;
}
