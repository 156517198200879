.c-talk-shop__section-wrapper--details {
  box-shadow: 0 2px 2px rgba($black, .1);
  position: relative;
  z-index: 1;

  .c-talk-shop__section {
    width: 100%;
  }
}

.c-talk-shop__details__podbean {
  margin: 2em 0;
  margin-bottom: .5em;
  padding: 20px;
  background-color: rgba($orange, .3);
  border-bottom: 5px solid $orange;
  border-radius: 4px;
}

.c-talk-shop__details__guest-image {
  margin-bottom: 2em;
}