.o-icon--boxed {
  @include transition();
  background-color: $orange;
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 1px solid $orange;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: transparent;

    .o-icon {
      fill: $orange;
    }
  }

  .o-icon {
    @include transition();
    width: 90%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    fill: $white;
  }

  &:last-child {
    margin-right: 0;
  }
}

.o-icon--inline-button {
  position: relative;

  .o-icon--wrapper {
    width: 2em;
    height: 1.5em;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 0;
    right: .5em;
    bottom: 0;
    margin: auto;

    .o-icon {
      @include transition();
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      fill: $white;
    }
  }

  &:hover {
    background-color: transparent;

    .o-icon {
      fill: $orange;
    }
  }
}

.o-icon--inline-button--left.button {
  padding-left: 2.5em;
}

.o-icon--inline-button--right.button {
  padding-right: 2.5em;
}

.o-icon--inline-button--left .o-icon--wrapper {
  left: .5em;
  right: auto;
}

.o-icon--inline-button--flip .o-icon {
  transform: rotate(180deg);
}

.o-icon--facebook {
  width: 40px;
  height: 40px;
  background-color: #3b5998;
  border: 0;
  border-radius: 4px;

  &:hover {
    background-color: lighten(#3b5998, 15);

    .o-icon {
      fill: $white;
    }
  }

  .o-icon {
    left: 17%;
    top: 30%;
  }
}

.o-icon--twitter {
  width: 40px;
  height: 40px;
  background-color: #1da1f2;
  border: 0;
  border-radius: 4px;

  &:hover {
    background-color: lighten(#1da1f2, 15);

    .o-icon {
      fill: $white;
    }
  }

  .o-icon {
    padding: .5em;
  }
}