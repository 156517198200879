@charset "UTF-8";
/* ==========================================================================
   INUITCSS
   ========================================================================== */
/**
 * inuitcss, by @csswizardry
 *
 * github.com/inuitcss | inuitcss.com
 */
/**
 * This is an example inuitcss manifest file. DO NOT MODIFY THIS FILE DIRECTLY.
 * Instead, copy and paste it into your own CSS directory and make additions,
 * extensions, and modifications there.
 */
/**
 * CONTENTS
 *
 * SETTINGS
 * Config...............Project-level configuration and feature switches.
 * Core.................inuitcss’ core and setup settings.
 * Global...............Project-wide variables and settings.
 *
 * TOOLS
 * Rem..................Tools for converting pixel inputs into their rem
 *                      equivalents.
 * Font-size............A mixin which guarantees baseline-friendly line-heights.
 * Clearfix.............Micro clearfix mixin.
 * Hidden...............Mixin for hiding elements.
 * Sass MQ..............inuitcss’ default media query manager.
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 * Reset................A tiny reset to complement Normalize.css.
 * Shared...............Sensibly and tersely share some global commonalities
 *                      (particularly useful when managing vertical rhythm).
 *
 * ELEMENTS
 * Page.................Set up our document’s default `font-size` and
 *                      `line-height`.
 * Headings.............Very minimal (i.e. only font-size information) for
 *                      headings 1 through 6.
 * Images...............Base image styles.
 * Tables...............Simple table styles.
 *
 * OBJECTS
 * Wrapper..............Page constraint object.
 * Layout...............Generic layout module.
 * Media................Image- and text-like content side by side. The
 *                      poster-child of OOCSS.
 * Flag.................Table-layout-based advancement on the Media object.
 * List-bare............Lists with no bullets or indents.
 * List-inline..........A list whose items all site in a line.
 * Box..................Simple boxing abstraction.
 * Block................Image-on-top-of-text object.
 * Ratio................A container for maintaining aspect ratio of content.
 * Crop.................Provide a cropping context for media (images, etc.).
 * Tables...............Classes for manipulating `table`s.
 * Pack.................Pack items into available horizontal space.
 *
 * COMPONENTS
 * Buttons..............An example button component, and how it fits into the
 *                      inuitcss framework.
 *
 * UTILITIES
 * Clearfix.............Bind our clearfix onto a utility class.
 * Widths...............Simple width helper classes.
 * Headings.............Reassigning our heading styles to helper classes.
 * Spacing..............Nudge bits of the DOM around with these spacing
 *                      classes.
 * Print................Reset-like styles taken from the HTML5 Boilerplate.
 * Hide.................Helper classes to hide content
 */
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, dd, ol, ul,
figure,
hr,
fieldset, legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
/* stylelint-disable selector-list-comma-newline-after */
address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 24px;
}

/**
 * Consistent indentation for lists.
 */
dd, ol, ul {
  margin-left: 24px;
}

/* stylelint-enable selector-list-comma-newline-after */
/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  max-width: 100%;
  /* [1] */
  font-style: italic;
  /* [2] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  /* [1] */
  max-width: none;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
/* stylelint-disable */
/* stylelint-enable */
.o-wrapper {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}

.o-wrapper:after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* Size variants.
   ========================================================================== */
.o-wrapper--tiny {
  padding-right: 6px;
  padding-left: 6px;
}

.o-wrapper--small {
  padding-right: 12px;
  padding-left: 12px;
}

.o-wrapper--large {
  padding-right: 48px;
  padding-left: 48px;
}

.o-wrapper--huge {
  padding-right: 96px;
  padding-left: 96px;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/**
 * Grid-like layout system.
 *
 * The layout object provides us with a column-style layout system. This file
 * contains the basic structural elements, but classes should be complemented
 * with width utilities, for example:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-1/2">
 *     </div>
 *     <div class="o-layout__item  u-1/2">
 *     </div>
 *   </div>
 *
 * The above will create a two-column structure in which each column will
 * fluidly fill half of the width of the parent. We can have more complex
 * systems:
 *
 *   <div class="o-layout">
 *     <div class="o-layout__item  u-1/1  u-1/3@medium">
 *     </div>
 *     <div class="o-layout__item  u-1/2  u-1/3@medium">
 *     </div>
 *     <div class="o-layout__item  u-1/2  u-1/3@medium">
 *     </div>
 *   </div>
 *
 * The above will create a system in which the first item will be 100% width
 * until we enter our medium breakpoint, when it will become 33.333% width. The
 * second and third items will be 50% of their parent, until they also become
 * 33.333% width at the medium breakpoint.
 *
 * We can also manipulate entire layout systems by adding a series of modifiers
 * to the `.o-layout` block. For example:
 *
 *   <div class="o-layout  o-layout--reverse">
 *
 * This will reverse the displayed order of the system so that it runs in the
 * opposite order to our source, effectively flipping the system over.
 *
 *   <div class="o-layout  o-layout--[right|center]">
 *
 * This will cause the system to fill up from either the centre or the right
 * hand side. Default behaviour is to fill up the layout system from the left.
 *
 * There are plenty more options available to us: explore them below.
 */
/* Default/mandatory classes.
   ========================================================================== */
/**
 * 1. Allows us to use the layout object on any type of element.
 * 2. We need to defensively reset any box-model properties.
 * 3. Use the negative margin trick for multi-row grids:
 *    http://csswizardry.com/2011/08/building-better-grid-systems/
 */
.o-layout {
  display: block;
  /* [1] */
  margin: 0;
  /* [2] */
  padding: 0;
  /* [2] */
  list-style: none;
  /* [1] */
  margin-left: -24px;
  /* [3] */
  font-size: 0;
}

/**
   * 1. Required in order to combine fluid widths with fixed gutters.
   * 2. Allows us to manipulate grids vertically, with text-level properties,
   *    etc.
   * 3. Default item alignment is with the tops of each other, like most
   *    traditional grid/layout systems.
   * 4. By default, all layout items are full-width (mobile first).
   * 5. Gutters provided by left padding:
   *    http://csswizardry.com/2011/08/building-better-grid-systems/
   * 6. Fallback for old IEs not supporting `rem` values.
   */
.o-layout__item {
  box-sizing: border-box;
  /* [1] */
  display: inline-block;
  /* [2] */
  vertical-align: top;
  /* [3] */
  width: 100%;
  /* [4] */
  padding-left: 24px;
  /* [5] */
  font-size: 16px;
  /* [6] */
  font-size: 1rem;
}

/* Gutter size modifiers.
   ========================================================================== */
.o-layout--flush {
  margin-left: 0;
}

.o-layout--flush > .o-layout__item {
  padding-left: 0;
}

.o-layout--tiny {
  margin-left: -6px;
}

.o-layout--tiny > .o-layout__item {
  padding-left: 6px;
}

.o-layout--small {
  margin-left: -12px;
}

.o-layout--small > .o-layout__item {
  padding-left: 12px;
}

.o-layout--large {
  margin-left: -48px;
}

.o-layout--large > .o-layout__item {
  padding-left: 48px;
}

.o-layout--huge {
  margin-left: -96px;
}

.o-layout--huge > .o-layout__item {
  padding-left: 96px;
}

/* Vertical alignment modifiers.
   ========================================================================== */
/**
 * Align all grid items to the middles of each other.
 */
.o-layout--middle > .o-layout__item {
  vertical-align: middle;
}

/**
 * Align all grid items to the bottoms of each other.
 */
.o-layout--bottom > .o-layout__item {
  vertical-align: bottom;
}

/**
 * Stretch all grid items of each row to have an equal-height.
 * Please be aware that this modifier class doesn't take any effect in IE9 and
 * below and other older browsers due to the lack of `display: flex` support.
 */
.o-layout--stretch {
  display: flex;
  flex-wrap: wrap;
}

.o-layout--stretch > .o-layout__item {
  display: flex;
}

/* Fill order modifiers.
   ========================================================================== */
/**
 * Fill up the layout system from the centre.
 */
.o-layout--center {
  text-align: center;
}

.o-layout--center > .o-layout__item {
  text-align: left;
}

/**
 * Fill up the layout system from the right-hand side.
 */
.o-layout--right {
  text-align: right;
}

.o-layout--right > .o-layout__item {
  text-align: left;
}

/**
 * Fill up the layout system from the left-hand side. This will likely only be
 * needed when using in conjunction with `.o-layout--reverse`.
 */
.o-layout--left {
  text-align: left;
}

.o-layout--left > .o-layout__item {
  text-align: left;
}

/**
 * Reverse the rendered order of the grid system.
 */
.o-layout--reverse {
  direction: rtl;
}

.o-layout--reverse > .o-layout__item {
  direction: ltr;
}

/* Auto-widths modifier.
   ========================================================================== */
/**
 * Cause layout items to take up a non-explicit amount of width.
 */
.o-layout--auto > .o-layout__item {
  width: auto;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}

.o-media:after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 24px;
}

.o-media__img > img {
  display: block;
}

.o-media__body {
  overflow: hidden;
  display: block;
}

.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Size variants.
   ========================================================================== */
/**
 * Modify the amount of space between our image and our text. We also have
 * reversible options for all available sizes.
 */
/* Gutterless media objects.
  ========================================================================== */
.o-media--flush > .o-media__img {
  margin-right: 0;
  margin-left: 0;
}

.o-media--tiny > .o-media__img {
  margin-right: 6px;
}

.o-media--tiny.o-media--reverse > .o-media__img {
  margin-right: 0;
  margin-left: 6px;
}

.o-media--small > .o-media__img {
  margin-right: 12px;
}

.o-media--small.o-media--reverse > .o-media__img {
  margin-right: 0;
  margin-left: 12px;
}

.o-media--large > .o-media__img {
  margin-right: 48px;
}

.o-media--large.o-media--reverse > .o-media__img {
  margin-right: 0;
  margin-left: 48px;
}

.o-media--huge > .o-media__img {
  margin-right: 96px;
}

.o-media--huge.o-media--reverse > .o-media__img {
  margin-right: 0;
  margin-left: 96px;
}

/* Reversed media objects.
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-right: 0;
  margin-left: 24px;
}

/* ==========================================================================
   #FLAG
   ========================================================================== */
/**
 * The flag object is a design pattern similar to the media object, however it
 * utilises `display: table[-cell];` to give us control over the vertical
 * alignments of the text and image.
 *
 * http://csswizardry.com/2013/05/the-flag-object/
 *
 * 1. Allows us to control vertical alignments.
 * 2. Force the object to be the full width of its parent. Combined with [1],
 *    this makes the object behave in a quasi-`display: block;` manner.
 * 3. Reset inherited `border-spacing` declarations.
 */
.o-flag {
  display: table;
  /* [1] */
  width: 100%;
  /* [2] */
  border-spacing: 0;
  /* [3] */
}

/**
   * Items within a flag object. There should only ever be one of each.
   *
   * 1. Default to aligning content to their middles.
   */
.o-flag__img,
.o-flag__body {
  display: table-cell;
  vertical-align: middle;
  /* [1] */
}

/**
   * Flag images have a space between them and the body of the object.
   *
   * 1. Force `.flag__img` to take up as little space as possible:
   *    https://pixelsvsbytes.com/2012/02/this-css-layout-grid-is-no-holy-grail/
   */
.o-flag__img {
  width: 1px;
  /* [1] */
  padding-right: 24px;
  /**
     * 1. Fixes problem with images disappearing.
     */
}

.o-flag__img > img {
  max-width: none;
  /* [1] */
}

/**
   * The container for the main content of the flag object.
   *
   * 1. Forces the `.flag__body` to take up all remaining space.
   */
.o-flag__body {
  width: auto;
  /* [1] */
}

.o-flag__body,
.o-flag__body > :last-child {
  margin-bottom: 0;
}

/* Size variants.
   ========================================================================== */
.o-flag--flush > .o-flag__img {
  padding-right: 0;
  padding-left: 0;
}

.o-flag--tiny > .o-flag__img {
  padding-right: 6px;
}

.o-flag--tiny.o-flag--reverse > .o-flag__img {
  padding-right: 0;
  padding-left: 6px;
}

.o-flag--small > .o-flag__img {
  padding-right: 12px;
}

.o-flag--small.o-flag--reverse > .o-flag__img {
  padding-right: 0;
  padding-left: 12px;
}

.o-flag--large > .o-flag__img {
  padding-right: 48px;
}

.o-flag--large.o-flag--reverse > .o-flag__img {
  padding-right: 0;
  padding-left: 48px;
}

.o-flag--huge > .o-flag__img {
  padding-right: 96px;
}

.o-flag--huge.o-flag--reverse > .o-flag__img {
  padding-right: 0;
  padding-left: 96px;
}

/* Reversed flag.
   ========================================================================== */
/**
 * 1. Swap the rendered direction of the object…
 * 2. …and reset it.
 * 3. Reassign margins to the correct sides.
 */
.o-flag--reverse {
  direction: rtl;
  /* [1] */
}

.o-flag--reverse > .o-flag__img,
.o-flag--reverse > .o-flag__body {
  direction: ltr;
  /* [2] */
}

.o-flag--reverse > .o-flag__img {
  padding-right: 0;
  /* [3] */
  padding-left: 24px;
  /* [3] */
}

/* Alignment variants.
   ========================================================================== */
/**
 * Vertically align the image- and body-content differently. Defaults to middle.
 */
.o-flag--top > .o-flag__img,
.o-flag--top > .o-flag__body {
  vertical-align: top;
}

.o-flag--bottom > .o-flag__img,
.o-flag--bottom > .o-flag__body {
  vertical-align: bottom;
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 *
 * Note: Declaring the item-class might not be necessary everywhere but
 * is for example in <dl> lists for the <dd> childs.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

.o-list-bare__item {
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  margin-left: 0;
  list-style: none;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #BOX
   ========================================================================== */
/**
 * The box object simply boxes off content. Extend with cosmetic styles in the
 * Components layer.
 *
 * 1. So we can apply the `.o-box` class to naturally-inline elements.
 */
.o-box {
  display: block;
  /* [1] */
  padding: 24px;
}

.o-box:after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-box > :last-child {
  margin-bottom: 0;
}

/* Size variants.
   ========================================================================== */
.o-box--flush {
  padding: 0;
}

.o-box--tiny {
  padding: 6px;
}

.o-box--small {
  padding: 12px;
}

.o-box--large {
  padding: 48px;
}

.o-box--huge {
  padding: 96px;
}

/* ==========================================================================
   #BLOCK
   ========================================================================== */
/**
 * Stacked image-with-text object. A simple abstraction to cover a very commonly
 * occurring design pattern.
 */
.o-block {
  display: block;
  text-align: center;
}

.o-block__img {
  margin-bottom: 24px;
  /* Size variants.
       ====================================================================== */
}

.o-block--flush > .o-block__img {
  margin-bottom: 0;
}

.o-block--tiny > .o-block__img {
  margin-bottom: 6px;
}

.o-block--small > .o-block__img {
  margin-bottom: 12px;
}

.o-block--large > .o-block__img {
  margin-bottom: 48px;
}

.o-block--huge > .o-block__img {
  margin-bottom: 96px;
}

.o-block__body {
  display: block;
}

/* Alignment variants.
   ========================================================================== */
.o-block--right {
  text-align: right;
}

.o-block--left {
  text-align: left;
}

/* ==========================================================================
   #RATIO
   ========================================================================== */
/**
 * Create ratio-bound content blocks, to keep media (e.g. images, videos) in
 * their correct aspect ratios.
 *
 * http://alistapart.com/article/creating-intrinsic-ratios-for-video
 *
 * 1. Default cropping is a 1:1 ratio (i.e. a perfect square).
 */
.o-ratio {
  position: relative;
  display: block;
  overflow: hidden;
}

.o-ratio:before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 100%;
  /* [1] */
}

.o-ratio__content,
.o-ratio > iframe,
.o-ratio > embed,
.o-ratio > object {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* stylelint-disable */
/* Ratio variants.
   ========================================================================== */
/**
 * Generate a series of ratio classes to be used like so:
 *
 *   <div class="o-ratio  o-ratio--16:9">
 *
 */
.o-ratio--2\:1:before {
  padding-bottom: 50%;
}

.o-ratio--4\:3:before {
  padding-bottom: 75%;
}

.o-ratio--16\:9:before {
  padding-bottom: 56.25%;
}

/* stylelint-enable */
/* ==========================================================================
   #CROP
   ========================================================================== */
/**
 * Provide a cropping container in order to display media (usually images)
 * cropped to certain ratios.
 *
 * 1. Set up a positioning context in which the image can sit.
 * 2. This is the crucial part: where the cropping happens.
 */
.o-crop {
  position: relative;
  /* [1] */
  display: block;
  overflow: hidden;
  /* [2] */
}

/**
   * Apply this class to the content (usually `img`) that needs cropping.
   *
   * 1. Image’s default positioning is top-left in the cropping box.
   * 2. Make sure the media doesn’t stop itself too soon.
   */
.o-crop__content {
  position: absolute;
  top: 0;
  /* [1] */
  left: 0;
  /* [1] */
  max-width: none;
  /* [2] */
}

/**
   * We can position the media in different locations within the cropping area.
   */
.o-crop__content--right {
  right: 0;
  left: auto;
}

.o-crop__content--bottom {
  top: auto;
  bottom: 0;
}

.o-crop__content--center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* stylelint-disable */
/* Crop-ratio variants.
   ========================================================================== */
/**
 * Generate a series of crop classes to be used like so:
 *
 *   <div class="o-crop  o-crop--16:9">
 *
 */
.o-crop--2\:1 {
  padding-bottom: 50%;
}

.o-crop--4\:3 {
  padding-bottom: 75%;
}

.o-crop--16\:9 {
  padding-bottom: 56.25%;
}

/* stylelint-enable */
/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* Size variants.
   ========================================================================== */
.o-table--tiny th,
.o-table--tiny td {
  padding: 6px;
}

.o-table--small th,
.o-table--small td {
  padding: 12px;
}

.o-table--large th,
.o-table--large td {
  padding: 48px;
}

.o-table--huge th,
.o-table--huge td {
  padding: 96px;
}

/* ==========================================================================
   #PACK
   ========================================================================== */
/**
 * The pack object simply causes any number of elements pack up horizontally to
 * automatically fill an equal, fluid width of their parent.
 *
 * 1. Fill all available space.
 * 2. Remove any leftover styling from lists.
 * 3. Cause children to be automatically equally sized.
 */
.o-pack {
  width: 100%;
  /* [1] */
  margin-left: 0;
  /* [2] */
  display: table;
  table-layout: fixed;
  /* [3] */
}

/**
   * 1. Cause children to adopt table-like structure.
   * 2. Default item alignment is with the tops of each other.
   */
.o-pack__item {
  display: table-cell;
  /* [1] */
  vertical-align: top;
  /* [2] */
  /* Vertical alignment variants.
       ====================================================================== */
}

.o-pack--middle > .o-pack__item {
  vertical-align: middle;
}

.o-pack--bottom > .o-pack__item {
  vertical-align: bottom;
}

/* Unequal-width items.
   ========================================================================== */
.o-pack--auto {
  table-layout: auto;
}

/* Size variants.
   ========================================================================== */
.o-pack--tiny {
  border-spacing: 6px;
}

.o-pack--small {
  border-spacing: 12px;
}

.o-pack--default {
  border-spacing: 24px;
}

.o-pack--large {
  border-spacing: 48px;
}

.o-pack--huge {
  border-spacing: 96px;
}

/* Reversed order packs
   ========================================================================== */
.o-pack--reverse {
  direction: rtl;
}

.o-pack--reverse > .o-pack__item {
  direction: ltr;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix:after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #WIDTHS
   ========================================================================== */
/**
 * inuitcss generates a series of utility classes that give a fluid width to
 * whichever element they’re applied, e.g.:
 *
 *   <img src="" alt="" class="u-1/2" />
 *
 * These classes are most commonly used in conjunction with our layout system,
 * e.g.:
 *
 *   <div class="o-layout__item  u-1/2">
 *
 * By default, inuitcss will also generate responsive variants of each of these
 * classes by using your Sass MQ configuration, e.g.:
 *
 *   <div class="o-layout__item  u-1/1  u-1/2@tablet  u-1/3@desktop">
 *
 * Optionally, inuitcss can generate offset classes which can push and pull
 * elements left and right by a specified amount, e.g.:
 *
 *   <div class="o-layout__item  u-2/3  u-pull-1/3">
 *
 * This is useful for making very granular changes to the rendered order of
 * items in a layout.
 *
 * N.B. This option is turned off by default.
 */
/**
 * A series of width helper classes that you can use to size things like grid
 * systems. Classes take a fraction-like format (e.g. `.u-2/3`). Use these in
 * your markup:
 *
 * <div class="u-7/12">
 *
 * The following will generate widths helper classes based on the fractions
 * defined in the `$inuit-fractions` list.
 */
.u-1\/1 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/1 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-1\/1 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/2 {
  width: 50% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/2 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 50% !important;
}

.u-pull-1\/2 {
  position: relative !important;
  right: 50% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/2 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/2 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-2\/2 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/3 {
  width: 33.33333% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/3 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 33.33333% !important;
}

.u-pull-1\/3 {
  position: relative !important;
  right: 33.33333% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/3 {
  width: 66.66667% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/3 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 66.66667% !important;
}

.u-pull-2\/3 {
  position: relative !important;
  right: 66.66667% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/3 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/3 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-3\/3 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/4 {
  width: 25% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/4 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 25% !important;
}

.u-pull-1\/4 {
  position: relative !important;
  right: 25% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/4 {
  width: 50% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/4 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 50% !important;
}

.u-pull-2\/4 {
  position: relative !important;
  right: 50% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/4 {
  width: 75% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/4 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 75% !important;
}

.u-pull-3\/4 {
  position: relative !important;
  right: 75% !important;
  left: auto !important;
  /* [1] */
}

.u-4\/4 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-4\/4 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-4\/4 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/5 {
  width: 20% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/5 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 20% !important;
}

.u-pull-1\/5 {
  position: relative !important;
  right: 20% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/5 {
  width: 40% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/5 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 40% !important;
}

.u-pull-2\/5 {
  position: relative !important;
  right: 40% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/5 {
  width: 60% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/5 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 60% !important;
}

.u-pull-3\/5 {
  position: relative !important;
  right: 60% !important;
  left: auto !important;
  /* [1] */
}

.u-4\/5 {
  width: 80% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-4\/5 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 80% !important;
}

.u-pull-4\/5 {
  position: relative !important;
  right: 80% !important;
  left: auto !important;
  /* [1] */
}

.u-5\/5 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-5\/5 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-5\/5 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/6 {
  width: 16.66667% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/6 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 16.66667% !important;
}

.u-pull-1\/6 {
  position: relative !important;
  right: 16.66667% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/6 {
  width: 33.33333% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/6 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 33.33333% !important;
}

.u-pull-2\/6 {
  position: relative !important;
  right: 33.33333% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/6 {
  width: 50% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/6 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 50% !important;
}

.u-pull-3\/6 {
  position: relative !important;
  right: 50% !important;
  left: auto !important;
  /* [1] */
}

.u-4\/6 {
  width: 66.66667% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-4\/6 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 66.66667% !important;
}

.u-pull-4\/6 {
  position: relative !important;
  right: 66.66667% !important;
  left: auto !important;
  /* [1] */
}

.u-5\/6 {
  width: 83.33333% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-5\/6 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 83.33333% !important;
}

.u-pull-5\/6 {
  position: relative !important;
  right: 83.33333% !important;
  left: auto !important;
  /* [1] */
}

.u-6\/6 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-6\/6 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-6\/6 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/7 {
  width: 14.28571% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/7 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 14.28571% !important;
}

.u-pull-1\/7 {
  position: relative !important;
  right: 14.28571% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/7 {
  width: 28.57143% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/7 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 28.57143% !important;
}

.u-pull-2\/7 {
  position: relative !important;
  right: 28.57143% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/7 {
  width: 42.85714% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/7 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 42.85714% !important;
}

.u-pull-3\/7 {
  position: relative !important;
  right: 42.85714% !important;
  left: auto !important;
  /* [1] */
}

.u-4\/7 {
  width: 57.14286% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-4\/7 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 57.14286% !important;
}

.u-pull-4\/7 {
  position: relative !important;
  right: 57.14286% !important;
  left: auto !important;
  /* [1] */
}

.u-5\/7 {
  width: 71.42857% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-5\/7 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 71.42857% !important;
}

.u-pull-5\/7 {
  position: relative !important;
  right: 71.42857% !important;
  left: auto !important;
  /* [1] */
}

.u-6\/7 {
  width: 85.71429% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-6\/7 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 85.71429% !important;
}

.u-pull-6\/7 {
  position: relative !important;
  right: 85.71429% !important;
  left: auto !important;
  /* [1] */
}

.u-7\/7 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-7\/7 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-7\/7 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/8 {
  width: 12.5% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/8 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 12.5% !important;
}

.u-pull-1\/8 {
  position: relative !important;
  right: 12.5% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/8 {
  width: 25% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/8 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 25% !important;
}

.u-pull-2\/8 {
  position: relative !important;
  right: 25% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/8 {
  width: 37.5% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/8 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 37.5% !important;
}

.u-pull-3\/8 {
  position: relative !important;
  right: 37.5% !important;
  left: auto !important;
  /* [1] */
}

.u-4\/8 {
  width: 50% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-4\/8 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 50% !important;
}

.u-pull-4\/8 {
  position: relative !important;
  right: 50% !important;
  left: auto !important;
  /* [1] */
}

.u-5\/8 {
  width: 62.5% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-5\/8 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 62.5% !important;
}

.u-pull-5\/8 {
  position: relative !important;
  right: 62.5% !important;
  left: auto !important;
  /* [1] */
}

.u-6\/8 {
  width: 75% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-6\/8 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 75% !important;
}

.u-pull-6\/8 {
  position: relative !important;
  right: 75% !important;
  left: auto !important;
  /* [1] */
}

.u-7\/8 {
  width: 87.5% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-7\/8 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 87.5% !important;
}

.u-pull-7\/8 {
  position: relative !important;
  right: 87.5% !important;
  left: auto !important;
  /* [1] */
}

.u-8\/8 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-8\/8 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-8\/8 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/9 {
  width: 11.11111% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/9 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 11.11111% !important;
}

.u-pull-1\/9 {
  position: relative !important;
  right: 11.11111% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/9 {
  width: 22.22222% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/9 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 22.22222% !important;
}

.u-pull-2\/9 {
  position: relative !important;
  right: 22.22222% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/9 {
  width: 33.33333% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/9 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 33.33333% !important;
}

.u-pull-3\/9 {
  position: relative !important;
  right: 33.33333% !important;
  left: auto !important;
  /* [1] */
}

.u-4\/9 {
  width: 44.44444% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-4\/9 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 44.44444% !important;
}

.u-pull-4\/9 {
  position: relative !important;
  right: 44.44444% !important;
  left: auto !important;
  /* [1] */
}

.u-5\/9 {
  width: 55.55556% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-5\/9 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 55.55556% !important;
}

.u-pull-5\/9 {
  position: relative !important;
  right: 55.55556% !important;
  left: auto !important;
  /* [1] */
}

.u-6\/9 {
  width: 66.66667% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-6\/9 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 66.66667% !important;
}

.u-pull-6\/9 {
  position: relative !important;
  right: 66.66667% !important;
  left: auto !important;
  /* [1] */
}

.u-7\/9 {
  width: 77.77778% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-7\/9 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 77.77778% !important;
}

.u-pull-7\/9 {
  position: relative !important;
  right: 77.77778% !important;
  left: auto !important;
  /* [1] */
}

.u-8\/9 {
  width: 88.88889% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-8\/9 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 88.88889% !important;
}

.u-pull-8\/9 {
  position: relative !important;
  right: 88.88889% !important;
  left: auto !important;
  /* [1] */
}

.u-9\/9 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-9\/9 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-9\/9 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/10 {
  width: 10% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 10% !important;
}

.u-pull-1\/10 {
  position: relative !important;
  right: 10% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/10 {
  width: 20% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 20% !important;
}

.u-pull-2\/10 {
  position: relative !important;
  right: 20% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/10 {
  width: 30% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 30% !important;
}

.u-pull-3\/10 {
  position: relative !important;
  right: 30% !important;
  left: auto !important;
  /* [1] */
}

.u-4\/10 {
  width: 40% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-4\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 40% !important;
}

.u-pull-4\/10 {
  position: relative !important;
  right: 40% !important;
  left: auto !important;
  /* [1] */
}

.u-5\/10 {
  width: 50% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-5\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 50% !important;
}

.u-pull-5\/10 {
  position: relative !important;
  right: 50% !important;
  left: auto !important;
  /* [1] */
}

.u-6\/10 {
  width: 60% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-6\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 60% !important;
}

.u-pull-6\/10 {
  position: relative !important;
  right: 60% !important;
  left: auto !important;
  /* [1] */
}

.u-7\/10 {
  width: 70% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-7\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 70% !important;
}

.u-pull-7\/10 {
  position: relative !important;
  right: 70% !important;
  left: auto !important;
  /* [1] */
}

.u-8\/10 {
  width: 80% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-8\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 80% !important;
}

.u-pull-8\/10 {
  position: relative !important;
  right: 80% !important;
  left: auto !important;
  /* [1] */
}

.u-9\/10 {
  width: 90% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-9\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 90% !important;
}

.u-pull-9\/10 {
  position: relative !important;
  right: 90% !important;
  left: auto !important;
  /* [1] */
}

.u-10\/10 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-10\/10 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-10\/10 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/11 {
  width: 9.09091% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 9.09091% !important;
}

.u-pull-1\/11 {
  position: relative !important;
  right: 9.09091% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/11 {
  width: 18.18182% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 18.18182% !important;
}

.u-pull-2\/11 {
  position: relative !important;
  right: 18.18182% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/11 {
  width: 27.27273% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 27.27273% !important;
}

.u-pull-3\/11 {
  position: relative !important;
  right: 27.27273% !important;
  left: auto !important;
  /* [1] */
}

.u-4\/11 {
  width: 36.36364% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-4\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 36.36364% !important;
}

.u-pull-4\/11 {
  position: relative !important;
  right: 36.36364% !important;
  left: auto !important;
  /* [1] */
}

.u-5\/11 {
  width: 45.45455% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-5\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 45.45455% !important;
}

.u-pull-5\/11 {
  position: relative !important;
  right: 45.45455% !important;
  left: auto !important;
  /* [1] */
}

.u-6\/11 {
  width: 54.54545% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-6\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 54.54545% !important;
}

.u-pull-6\/11 {
  position: relative !important;
  right: 54.54545% !important;
  left: auto !important;
  /* [1] */
}

.u-7\/11 {
  width: 63.63636% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-7\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 63.63636% !important;
}

.u-pull-7\/11 {
  position: relative !important;
  right: 63.63636% !important;
  left: auto !important;
  /* [1] */
}

.u-8\/11 {
  width: 72.72727% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-8\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 72.72727% !important;
}

.u-pull-8\/11 {
  position: relative !important;
  right: 72.72727% !important;
  left: auto !important;
  /* [1] */
}

.u-9\/11 {
  width: 81.81818% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-9\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 81.81818% !important;
}

.u-pull-9\/11 {
  position: relative !important;
  right: 81.81818% !important;
  left: auto !important;
  /* [1] */
}

.u-10\/11 {
  width: 90.90909% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-10\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 90.90909% !important;
}

.u-pull-10\/11 {
  position: relative !important;
  right: 90.90909% !important;
  left: auto !important;
  /* [1] */
}

.u-11\/11 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-11\/11 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-11\/11 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

.u-1\/12 {
  width: 8.33333% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-1\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 8.33333% !important;
}

.u-pull-1\/12 {
  position: relative !important;
  right: 8.33333% !important;
  left: auto !important;
  /* [1] */
}

.u-2\/12 {
  width: 16.66667% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-2\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 16.66667% !important;
}

.u-pull-2\/12 {
  position: relative !important;
  right: 16.66667% !important;
  left: auto !important;
  /* [1] */
}

.u-3\/12 {
  width: 25% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-3\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 25% !important;
}

.u-pull-3\/12 {
  position: relative !important;
  right: 25% !important;
  left: auto !important;
  /* [1] */
}

.u-4\/12 {
  width: 33.33333% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-4\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 33.33333% !important;
}

.u-pull-4\/12 {
  position: relative !important;
  right: 33.33333% !important;
  left: auto !important;
  /* [1] */
}

.u-5\/12 {
  width: 41.66667% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-5\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 41.66667% !important;
}

.u-pull-5\/12 {
  position: relative !important;
  right: 41.66667% !important;
  left: auto !important;
  /* [1] */
}

.u-6\/12 {
  width: 50% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-6\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 50% !important;
}

.u-pull-6\/12 {
  position: relative !important;
  right: 50% !important;
  left: auto !important;
  /* [1] */
}

.u-7\/12 {
  width: 58.33333% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-7\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 58.33333% !important;
}

.u-pull-7\/12 {
  position: relative !important;
  right: 58.33333% !important;
  left: auto !important;
  /* [1] */
}

.u-8\/12 {
  width: 66.66667% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-8\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 66.66667% !important;
}

.u-pull-8\/12 {
  position: relative !important;
  right: 66.66667% !important;
  left: auto !important;
  /* [1] */
}

.u-9\/12 {
  width: 75% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-9\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 75% !important;
}

.u-pull-9\/12 {
  position: relative !important;
  right: 75% !important;
  left: auto !important;
  /* [1] */
}

.u-10\/12 {
  width: 83.33333% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-10\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 83.33333% !important;
}

.u-pull-10\/12 {
  position: relative !important;
  right: 83.33333% !important;
  left: auto !important;
  /* [1] */
}

.u-11\/12 {
  width: 91.66667% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-11\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 91.66667% !important;
}

.u-pull-11\/12 {
  position: relative !important;
  right: 91.66667% !important;
  left: auto !important;
  /* [1] */
}

.u-12\/12 {
  width: 100% !important;
}

/**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
.u-push-12\/12 {
  position: relative !important;
  right: auto !important;
  /* [1] */
  left: 100% !important;
}

.u-pull-12\/12 {
  position: relative !important;
  right: 100% !important;
  left: auto !important;
  /* [1] */
}

/**
 * If we’re using Sass-MQ, automatically generate grid system(s) for each of our
 * defined breakpoints, and give them a Responsive Suffix, e.g.:
 *
 * <div class="u-3/12@mobile">
 */
@media (min-width: 25em) {
  .u-1\/1\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/1\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-1\/1\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/2\@mobile {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/2\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-1\/2\@mobile {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/2\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/2\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-2\/2\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/3\@mobile {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/3\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-1\/3\@mobile {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/3\@mobile {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/3\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-2\/3\@mobile {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/3\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/3\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-3\/3\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/4\@mobile {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/4\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-1\/4\@mobile {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/4\@mobile {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/4\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-2\/4\@mobile {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/4\@mobile {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/4\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-3\/4\@mobile {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/4\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/4\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-4\/4\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/5\@mobile {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/5\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-1\/5\@mobile {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/5\@mobile {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/5\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-2\/5\@mobile {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/5\@mobile {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/5\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-3\/5\@mobile {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/5\@mobile {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/5\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-4\/5\@mobile {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/5\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/5\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-5\/5\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/6\@mobile {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/6\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-1\/6\@mobile {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/6\@mobile {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/6\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-2\/6\@mobile {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/6\@mobile {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/6\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-3\/6\@mobile {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/6\@mobile {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/6\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-4\/6\@mobile {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/6\@mobile {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/6\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-5\/6\@mobile {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/6\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/6\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-6\/6\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/7\@mobile {
    width: 14.28571% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/7\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 14.28571% !important;
  }
  .u-pull-1\/7\@mobile {
    position: relative !important;
    right: 14.28571% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/7\@mobile {
    width: 28.57143% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/7\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 28.57143% !important;
  }
  .u-pull-2\/7\@mobile {
    position: relative !important;
    right: 28.57143% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/7\@mobile {
    width: 42.85714% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/7\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 42.85714% !important;
  }
  .u-pull-3\/7\@mobile {
    position: relative !important;
    right: 42.85714% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/7\@mobile {
    width: 57.14286% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/7\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 57.14286% !important;
  }
  .u-pull-4\/7\@mobile {
    position: relative !important;
    right: 57.14286% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/7\@mobile {
    width: 71.42857% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/7\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 71.42857% !important;
  }
  .u-pull-5\/7\@mobile {
    position: relative !important;
    right: 71.42857% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/7\@mobile {
    width: 85.71429% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/7\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 85.71429% !important;
  }
  .u-pull-6\/7\@mobile {
    position: relative !important;
    right: 85.71429% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/7\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/7\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-7\/7\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/8\@mobile {
    width: 12.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/8\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 12.5% !important;
  }
  .u-pull-1\/8\@mobile {
    position: relative !important;
    right: 12.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/8\@mobile {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/8\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-2\/8\@mobile {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/8\@mobile {
    width: 37.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/8\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 37.5% !important;
  }
  .u-pull-3\/8\@mobile {
    position: relative !important;
    right: 37.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/8\@mobile {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/8\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-4\/8\@mobile {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/8\@mobile {
    width: 62.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/8\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 62.5% !important;
  }
  .u-pull-5\/8\@mobile {
    position: relative !important;
    right: 62.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/8\@mobile {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/8\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-6\/8\@mobile {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/8\@mobile {
    width: 87.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/8\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 87.5% !important;
  }
  .u-pull-7\/8\@mobile {
    position: relative !important;
    right: 87.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/8\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/8\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-8\/8\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/9\@mobile {
    width: 11.11111% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/9\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 11.11111% !important;
  }
  .u-pull-1\/9\@mobile {
    position: relative !important;
    right: 11.11111% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/9\@mobile {
    width: 22.22222% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/9\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 22.22222% !important;
  }
  .u-pull-2\/9\@mobile {
    position: relative !important;
    right: 22.22222% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/9\@mobile {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/9\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-3\/9\@mobile {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/9\@mobile {
    width: 44.44444% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/9\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 44.44444% !important;
  }
  .u-pull-4\/9\@mobile {
    position: relative !important;
    right: 44.44444% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/9\@mobile {
    width: 55.55556% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/9\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 55.55556% !important;
  }
  .u-pull-5\/9\@mobile {
    position: relative !important;
    right: 55.55556% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/9\@mobile {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/9\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-6\/9\@mobile {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/9\@mobile {
    width: 77.77778% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/9\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 77.77778% !important;
  }
  .u-pull-7\/9\@mobile {
    position: relative !important;
    right: 77.77778% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/9\@mobile {
    width: 88.88889% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/9\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 88.88889% !important;
  }
  .u-pull-8\/9\@mobile {
    position: relative !important;
    right: 88.88889% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/9\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/9\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-9\/9\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/10\@mobile {
    width: 10% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 10% !important;
  }
  .u-pull-1\/10\@mobile {
    position: relative !important;
    right: 10% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/10\@mobile {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-2\/10\@mobile {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/10\@mobile {
    width: 30% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 30% !important;
  }
  .u-pull-3\/10\@mobile {
    position: relative !important;
    right: 30% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/10\@mobile {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-4\/10\@mobile {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/10\@mobile {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-5\/10\@mobile {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/10\@mobile {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-6\/10\@mobile {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/10\@mobile {
    width: 70% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 70% !important;
  }
  .u-pull-7\/10\@mobile {
    position: relative !important;
    right: 70% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/10\@mobile {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-8\/10\@mobile {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/10\@mobile {
    width: 90% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90% !important;
  }
  .u-pull-9\/10\@mobile {
    position: relative !important;
    right: 90% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/10\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/10\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-10\/10\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/11\@mobile {
    width: 9.09091% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 9.09091% !important;
  }
  .u-pull-1\/11\@mobile {
    position: relative !important;
    right: 9.09091% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/11\@mobile {
    width: 18.18182% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 18.18182% !important;
  }
  .u-pull-2\/11\@mobile {
    position: relative !important;
    right: 18.18182% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/11\@mobile {
    width: 27.27273% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 27.27273% !important;
  }
  .u-pull-3\/11\@mobile {
    position: relative !important;
    right: 27.27273% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/11\@mobile {
    width: 36.36364% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 36.36364% !important;
  }
  .u-pull-4\/11\@mobile {
    position: relative !important;
    right: 36.36364% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/11\@mobile {
    width: 45.45455% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 45.45455% !important;
  }
  .u-pull-5\/11\@mobile {
    position: relative !important;
    right: 45.45455% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/11\@mobile {
    width: 54.54545% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 54.54545% !important;
  }
  .u-pull-6\/11\@mobile {
    position: relative !important;
    right: 54.54545% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/11\@mobile {
    width: 63.63636% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 63.63636% !important;
  }
  .u-pull-7\/11\@mobile {
    position: relative !important;
    right: 63.63636% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/11\@mobile {
    width: 72.72727% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 72.72727% !important;
  }
  .u-pull-8\/11\@mobile {
    position: relative !important;
    right: 72.72727% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/11\@mobile {
    width: 81.81818% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 81.81818% !important;
  }
  .u-pull-9\/11\@mobile {
    position: relative !important;
    right: 81.81818% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/11\@mobile {
    width: 90.90909% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90.90909% !important;
  }
  .u-pull-10\/11\@mobile {
    position: relative !important;
    right: 90.90909% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/11\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/11\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-11\/11\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/12\@mobile {
    width: 8.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 8.33333% !important;
  }
  .u-pull-1\/12\@mobile {
    position: relative !important;
    right: 8.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/12\@mobile {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-2\/12\@mobile {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/12\@mobile {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-3\/12\@mobile {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/12\@mobile {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-4\/12\@mobile {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/12\@mobile {
    width: 41.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 41.66667% !important;
  }
  .u-pull-5\/12\@mobile {
    position: relative !important;
    right: 41.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/12\@mobile {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-6\/12\@mobile {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/12\@mobile {
    width: 58.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 58.33333% !important;
  }
  .u-pull-7\/12\@mobile {
    position: relative !important;
    right: 58.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/12\@mobile {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-8\/12\@mobile {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/12\@mobile {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-9\/12\@mobile {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/12\@mobile {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-10\/12\@mobile {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/12\@mobile {
    width: 91.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 91.66667% !important;
  }
  .u-pull-11\/12\@mobile {
    position: relative !important;
    right: 91.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-12\/12\@mobile {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-12\/12\@mobile {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-12\/12\@mobile {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
}

@media (min-width: 40.625em) {
  .u-1\/1\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/1\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-1\/1\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/2\@tablet {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/2\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-1\/2\@tablet {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/2\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/2\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-2\/2\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/3\@tablet {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/3\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-1\/3\@tablet {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/3\@tablet {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/3\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-2\/3\@tablet {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/3\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/3\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-3\/3\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/4\@tablet {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/4\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-1\/4\@tablet {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/4\@tablet {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/4\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-2\/4\@tablet {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/4\@tablet {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/4\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-3\/4\@tablet {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/4\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/4\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-4\/4\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/5\@tablet {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/5\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-1\/5\@tablet {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/5\@tablet {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/5\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-2\/5\@tablet {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/5\@tablet {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/5\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-3\/5\@tablet {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/5\@tablet {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/5\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-4\/5\@tablet {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/5\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/5\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-5\/5\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/6\@tablet {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/6\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-1\/6\@tablet {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/6\@tablet {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/6\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-2\/6\@tablet {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/6\@tablet {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/6\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-3\/6\@tablet {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/6\@tablet {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/6\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-4\/6\@tablet {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/6\@tablet {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/6\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-5\/6\@tablet {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/6\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/6\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-6\/6\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/7\@tablet {
    width: 14.28571% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/7\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 14.28571% !important;
  }
  .u-pull-1\/7\@tablet {
    position: relative !important;
    right: 14.28571% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/7\@tablet {
    width: 28.57143% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/7\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 28.57143% !important;
  }
  .u-pull-2\/7\@tablet {
    position: relative !important;
    right: 28.57143% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/7\@tablet {
    width: 42.85714% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/7\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 42.85714% !important;
  }
  .u-pull-3\/7\@tablet {
    position: relative !important;
    right: 42.85714% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/7\@tablet {
    width: 57.14286% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/7\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 57.14286% !important;
  }
  .u-pull-4\/7\@tablet {
    position: relative !important;
    right: 57.14286% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/7\@tablet {
    width: 71.42857% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/7\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 71.42857% !important;
  }
  .u-pull-5\/7\@tablet {
    position: relative !important;
    right: 71.42857% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/7\@tablet {
    width: 85.71429% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/7\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 85.71429% !important;
  }
  .u-pull-6\/7\@tablet {
    position: relative !important;
    right: 85.71429% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/7\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/7\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-7\/7\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/8\@tablet {
    width: 12.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/8\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 12.5% !important;
  }
  .u-pull-1\/8\@tablet {
    position: relative !important;
    right: 12.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/8\@tablet {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/8\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-2\/8\@tablet {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/8\@tablet {
    width: 37.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/8\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 37.5% !important;
  }
  .u-pull-3\/8\@tablet {
    position: relative !important;
    right: 37.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/8\@tablet {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/8\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-4\/8\@tablet {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/8\@tablet {
    width: 62.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/8\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 62.5% !important;
  }
  .u-pull-5\/8\@tablet {
    position: relative !important;
    right: 62.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/8\@tablet {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/8\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-6\/8\@tablet {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/8\@tablet {
    width: 87.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/8\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 87.5% !important;
  }
  .u-pull-7\/8\@tablet {
    position: relative !important;
    right: 87.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/8\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/8\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-8\/8\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/9\@tablet {
    width: 11.11111% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/9\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 11.11111% !important;
  }
  .u-pull-1\/9\@tablet {
    position: relative !important;
    right: 11.11111% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/9\@tablet {
    width: 22.22222% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/9\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 22.22222% !important;
  }
  .u-pull-2\/9\@tablet {
    position: relative !important;
    right: 22.22222% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/9\@tablet {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/9\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-3\/9\@tablet {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/9\@tablet {
    width: 44.44444% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/9\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 44.44444% !important;
  }
  .u-pull-4\/9\@tablet {
    position: relative !important;
    right: 44.44444% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/9\@tablet {
    width: 55.55556% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/9\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 55.55556% !important;
  }
  .u-pull-5\/9\@tablet {
    position: relative !important;
    right: 55.55556% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/9\@tablet {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/9\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-6\/9\@tablet {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/9\@tablet {
    width: 77.77778% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/9\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 77.77778% !important;
  }
  .u-pull-7\/9\@tablet {
    position: relative !important;
    right: 77.77778% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/9\@tablet {
    width: 88.88889% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/9\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 88.88889% !important;
  }
  .u-pull-8\/9\@tablet {
    position: relative !important;
    right: 88.88889% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/9\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/9\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-9\/9\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/10\@tablet {
    width: 10% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 10% !important;
  }
  .u-pull-1\/10\@tablet {
    position: relative !important;
    right: 10% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/10\@tablet {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-2\/10\@tablet {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/10\@tablet {
    width: 30% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 30% !important;
  }
  .u-pull-3\/10\@tablet {
    position: relative !important;
    right: 30% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/10\@tablet {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-4\/10\@tablet {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/10\@tablet {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-5\/10\@tablet {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/10\@tablet {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-6\/10\@tablet {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/10\@tablet {
    width: 70% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 70% !important;
  }
  .u-pull-7\/10\@tablet {
    position: relative !important;
    right: 70% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/10\@tablet {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-8\/10\@tablet {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/10\@tablet {
    width: 90% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90% !important;
  }
  .u-pull-9\/10\@tablet {
    position: relative !important;
    right: 90% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/10\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/10\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-10\/10\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/11\@tablet {
    width: 9.09091% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 9.09091% !important;
  }
  .u-pull-1\/11\@tablet {
    position: relative !important;
    right: 9.09091% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/11\@tablet {
    width: 18.18182% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 18.18182% !important;
  }
  .u-pull-2\/11\@tablet {
    position: relative !important;
    right: 18.18182% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/11\@tablet {
    width: 27.27273% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 27.27273% !important;
  }
  .u-pull-3\/11\@tablet {
    position: relative !important;
    right: 27.27273% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/11\@tablet {
    width: 36.36364% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 36.36364% !important;
  }
  .u-pull-4\/11\@tablet {
    position: relative !important;
    right: 36.36364% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/11\@tablet {
    width: 45.45455% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 45.45455% !important;
  }
  .u-pull-5\/11\@tablet {
    position: relative !important;
    right: 45.45455% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/11\@tablet {
    width: 54.54545% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 54.54545% !important;
  }
  .u-pull-6\/11\@tablet {
    position: relative !important;
    right: 54.54545% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/11\@tablet {
    width: 63.63636% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 63.63636% !important;
  }
  .u-pull-7\/11\@tablet {
    position: relative !important;
    right: 63.63636% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/11\@tablet {
    width: 72.72727% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 72.72727% !important;
  }
  .u-pull-8\/11\@tablet {
    position: relative !important;
    right: 72.72727% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/11\@tablet {
    width: 81.81818% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 81.81818% !important;
  }
  .u-pull-9\/11\@tablet {
    position: relative !important;
    right: 81.81818% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/11\@tablet {
    width: 90.90909% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90.90909% !important;
  }
  .u-pull-10\/11\@tablet {
    position: relative !important;
    right: 90.90909% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/11\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/11\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-11\/11\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/12\@tablet {
    width: 8.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 8.33333% !important;
  }
  .u-pull-1\/12\@tablet {
    position: relative !important;
    right: 8.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/12\@tablet {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-2\/12\@tablet {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/12\@tablet {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-3\/12\@tablet {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/12\@tablet {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-4\/12\@tablet {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/12\@tablet {
    width: 41.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 41.66667% !important;
  }
  .u-pull-5\/12\@tablet {
    position: relative !important;
    right: 41.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/12\@tablet {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-6\/12\@tablet {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/12\@tablet {
    width: 58.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 58.33333% !important;
  }
  .u-pull-7\/12\@tablet {
    position: relative !important;
    right: 58.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/12\@tablet {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-8\/12\@tablet {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/12\@tablet {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-9\/12\@tablet {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/12\@tablet {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-10\/12\@tablet {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/12\@tablet {
    width: 91.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 91.66667% !important;
  }
  .u-pull-11\/12\@tablet {
    position: relative !important;
    right: 91.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-12\/12\@tablet {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-12\/12\@tablet {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-12\/12\@tablet {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
}

@media (min-width: 51.25em) {
  .u-1\/1\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/1\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-1\/1\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/2\@site_break {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/2\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-1\/2\@site_break {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/2\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/2\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-2\/2\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/3\@site_break {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/3\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-1\/3\@site_break {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/3\@site_break {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/3\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-2\/3\@site_break {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/3\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/3\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-3\/3\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/4\@site_break {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/4\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-1\/4\@site_break {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/4\@site_break {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/4\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-2\/4\@site_break {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/4\@site_break {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/4\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-3\/4\@site_break {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/4\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/4\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-4\/4\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/5\@site_break {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/5\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-1\/5\@site_break {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/5\@site_break {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/5\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-2\/5\@site_break {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/5\@site_break {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/5\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-3\/5\@site_break {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/5\@site_break {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/5\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-4\/5\@site_break {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/5\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/5\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-5\/5\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/6\@site_break {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/6\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-1\/6\@site_break {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/6\@site_break {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/6\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-2\/6\@site_break {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/6\@site_break {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/6\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-3\/6\@site_break {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/6\@site_break {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/6\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-4\/6\@site_break {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/6\@site_break {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/6\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-5\/6\@site_break {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/6\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/6\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-6\/6\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/7\@site_break {
    width: 14.28571% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/7\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 14.28571% !important;
  }
  .u-pull-1\/7\@site_break {
    position: relative !important;
    right: 14.28571% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/7\@site_break {
    width: 28.57143% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/7\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 28.57143% !important;
  }
  .u-pull-2\/7\@site_break {
    position: relative !important;
    right: 28.57143% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/7\@site_break {
    width: 42.85714% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/7\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 42.85714% !important;
  }
  .u-pull-3\/7\@site_break {
    position: relative !important;
    right: 42.85714% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/7\@site_break {
    width: 57.14286% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/7\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 57.14286% !important;
  }
  .u-pull-4\/7\@site_break {
    position: relative !important;
    right: 57.14286% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/7\@site_break {
    width: 71.42857% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/7\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 71.42857% !important;
  }
  .u-pull-5\/7\@site_break {
    position: relative !important;
    right: 71.42857% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/7\@site_break {
    width: 85.71429% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/7\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 85.71429% !important;
  }
  .u-pull-6\/7\@site_break {
    position: relative !important;
    right: 85.71429% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/7\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/7\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-7\/7\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/8\@site_break {
    width: 12.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/8\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 12.5% !important;
  }
  .u-pull-1\/8\@site_break {
    position: relative !important;
    right: 12.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/8\@site_break {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/8\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-2\/8\@site_break {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/8\@site_break {
    width: 37.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/8\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 37.5% !important;
  }
  .u-pull-3\/8\@site_break {
    position: relative !important;
    right: 37.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/8\@site_break {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/8\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-4\/8\@site_break {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/8\@site_break {
    width: 62.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/8\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 62.5% !important;
  }
  .u-pull-5\/8\@site_break {
    position: relative !important;
    right: 62.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/8\@site_break {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/8\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-6\/8\@site_break {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/8\@site_break {
    width: 87.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/8\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 87.5% !important;
  }
  .u-pull-7\/8\@site_break {
    position: relative !important;
    right: 87.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/8\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/8\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-8\/8\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/9\@site_break {
    width: 11.11111% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/9\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 11.11111% !important;
  }
  .u-pull-1\/9\@site_break {
    position: relative !important;
    right: 11.11111% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/9\@site_break {
    width: 22.22222% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/9\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 22.22222% !important;
  }
  .u-pull-2\/9\@site_break {
    position: relative !important;
    right: 22.22222% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/9\@site_break {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/9\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-3\/9\@site_break {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/9\@site_break {
    width: 44.44444% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/9\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 44.44444% !important;
  }
  .u-pull-4\/9\@site_break {
    position: relative !important;
    right: 44.44444% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/9\@site_break {
    width: 55.55556% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/9\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 55.55556% !important;
  }
  .u-pull-5\/9\@site_break {
    position: relative !important;
    right: 55.55556% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/9\@site_break {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/9\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-6\/9\@site_break {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/9\@site_break {
    width: 77.77778% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/9\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 77.77778% !important;
  }
  .u-pull-7\/9\@site_break {
    position: relative !important;
    right: 77.77778% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/9\@site_break {
    width: 88.88889% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/9\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 88.88889% !important;
  }
  .u-pull-8\/9\@site_break {
    position: relative !important;
    right: 88.88889% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/9\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/9\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-9\/9\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/10\@site_break {
    width: 10% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 10% !important;
  }
  .u-pull-1\/10\@site_break {
    position: relative !important;
    right: 10% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/10\@site_break {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-2\/10\@site_break {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/10\@site_break {
    width: 30% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 30% !important;
  }
  .u-pull-3\/10\@site_break {
    position: relative !important;
    right: 30% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/10\@site_break {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-4\/10\@site_break {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/10\@site_break {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-5\/10\@site_break {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/10\@site_break {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-6\/10\@site_break {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/10\@site_break {
    width: 70% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 70% !important;
  }
  .u-pull-7\/10\@site_break {
    position: relative !important;
    right: 70% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/10\@site_break {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-8\/10\@site_break {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/10\@site_break {
    width: 90% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90% !important;
  }
  .u-pull-9\/10\@site_break {
    position: relative !important;
    right: 90% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/10\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/10\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-10\/10\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/11\@site_break {
    width: 9.09091% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 9.09091% !important;
  }
  .u-pull-1\/11\@site_break {
    position: relative !important;
    right: 9.09091% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/11\@site_break {
    width: 18.18182% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 18.18182% !important;
  }
  .u-pull-2\/11\@site_break {
    position: relative !important;
    right: 18.18182% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/11\@site_break {
    width: 27.27273% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 27.27273% !important;
  }
  .u-pull-3\/11\@site_break {
    position: relative !important;
    right: 27.27273% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/11\@site_break {
    width: 36.36364% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 36.36364% !important;
  }
  .u-pull-4\/11\@site_break {
    position: relative !important;
    right: 36.36364% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/11\@site_break {
    width: 45.45455% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 45.45455% !important;
  }
  .u-pull-5\/11\@site_break {
    position: relative !important;
    right: 45.45455% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/11\@site_break {
    width: 54.54545% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 54.54545% !important;
  }
  .u-pull-6\/11\@site_break {
    position: relative !important;
    right: 54.54545% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/11\@site_break {
    width: 63.63636% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 63.63636% !important;
  }
  .u-pull-7\/11\@site_break {
    position: relative !important;
    right: 63.63636% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/11\@site_break {
    width: 72.72727% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 72.72727% !important;
  }
  .u-pull-8\/11\@site_break {
    position: relative !important;
    right: 72.72727% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/11\@site_break {
    width: 81.81818% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 81.81818% !important;
  }
  .u-pull-9\/11\@site_break {
    position: relative !important;
    right: 81.81818% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/11\@site_break {
    width: 90.90909% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90.90909% !important;
  }
  .u-pull-10\/11\@site_break {
    position: relative !important;
    right: 90.90909% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/11\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/11\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-11\/11\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/12\@site_break {
    width: 8.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 8.33333% !important;
  }
  .u-pull-1\/12\@site_break {
    position: relative !important;
    right: 8.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/12\@site_break {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-2\/12\@site_break {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/12\@site_break {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-3\/12\@site_break {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/12\@site_break {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-4\/12\@site_break {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/12\@site_break {
    width: 41.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 41.66667% !important;
  }
  .u-pull-5\/12\@site_break {
    position: relative !important;
    right: 41.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/12\@site_break {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-6\/12\@site_break {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/12\@site_break {
    width: 58.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 58.33333% !important;
  }
  .u-pull-7\/12\@site_break {
    position: relative !important;
    right: 58.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/12\@site_break {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-8\/12\@site_break {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/12\@site_break {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-9\/12\@site_break {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/12\@site_break {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-10\/12\@site_break {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/12\@site_break {
    width: 91.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 91.66667% !important;
  }
  .u-pull-11\/12\@site_break {
    position: relative !important;
    right: 91.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-12\/12\@site_break {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-12\/12\@site_break {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-12\/12\@site_break {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
}

@media (min-width: 60em) {
  .u-1\/1\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/1\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-1\/1\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/2\@desktop {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/2\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-1\/2\@desktop {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/2\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/2\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-2\/2\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/3\@desktop {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/3\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-1\/3\@desktop {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/3\@desktop {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/3\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-2\/3\@desktop {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/3\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/3\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-3\/3\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/4\@desktop {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/4\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-1\/4\@desktop {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/4\@desktop {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/4\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-2\/4\@desktop {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/4\@desktop {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/4\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-3\/4\@desktop {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/4\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/4\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-4\/4\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/5\@desktop {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/5\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-1\/5\@desktop {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/5\@desktop {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/5\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-2\/5\@desktop {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/5\@desktop {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/5\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-3\/5\@desktop {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/5\@desktop {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/5\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-4\/5\@desktop {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/5\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/5\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-5\/5\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/6\@desktop {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/6\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-1\/6\@desktop {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/6\@desktop {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/6\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-2\/6\@desktop {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/6\@desktop {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/6\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-3\/6\@desktop {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/6\@desktop {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/6\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-4\/6\@desktop {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/6\@desktop {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/6\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-5\/6\@desktop {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/6\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/6\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-6\/6\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/7\@desktop {
    width: 14.28571% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/7\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 14.28571% !important;
  }
  .u-pull-1\/7\@desktop {
    position: relative !important;
    right: 14.28571% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/7\@desktop {
    width: 28.57143% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/7\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 28.57143% !important;
  }
  .u-pull-2\/7\@desktop {
    position: relative !important;
    right: 28.57143% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/7\@desktop {
    width: 42.85714% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/7\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 42.85714% !important;
  }
  .u-pull-3\/7\@desktop {
    position: relative !important;
    right: 42.85714% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/7\@desktop {
    width: 57.14286% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/7\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 57.14286% !important;
  }
  .u-pull-4\/7\@desktop {
    position: relative !important;
    right: 57.14286% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/7\@desktop {
    width: 71.42857% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/7\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 71.42857% !important;
  }
  .u-pull-5\/7\@desktop {
    position: relative !important;
    right: 71.42857% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/7\@desktop {
    width: 85.71429% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/7\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 85.71429% !important;
  }
  .u-pull-6\/7\@desktop {
    position: relative !important;
    right: 85.71429% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/7\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/7\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-7\/7\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/8\@desktop {
    width: 12.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/8\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 12.5% !important;
  }
  .u-pull-1\/8\@desktop {
    position: relative !important;
    right: 12.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/8\@desktop {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/8\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-2\/8\@desktop {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/8\@desktop {
    width: 37.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/8\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 37.5% !important;
  }
  .u-pull-3\/8\@desktop {
    position: relative !important;
    right: 37.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/8\@desktop {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/8\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-4\/8\@desktop {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/8\@desktop {
    width: 62.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/8\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 62.5% !important;
  }
  .u-pull-5\/8\@desktop {
    position: relative !important;
    right: 62.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/8\@desktop {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/8\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-6\/8\@desktop {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/8\@desktop {
    width: 87.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/8\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 87.5% !important;
  }
  .u-pull-7\/8\@desktop {
    position: relative !important;
    right: 87.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/8\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/8\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-8\/8\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/9\@desktop {
    width: 11.11111% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/9\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 11.11111% !important;
  }
  .u-pull-1\/9\@desktop {
    position: relative !important;
    right: 11.11111% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/9\@desktop {
    width: 22.22222% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/9\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 22.22222% !important;
  }
  .u-pull-2\/9\@desktop {
    position: relative !important;
    right: 22.22222% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/9\@desktop {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/9\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-3\/9\@desktop {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/9\@desktop {
    width: 44.44444% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/9\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 44.44444% !important;
  }
  .u-pull-4\/9\@desktop {
    position: relative !important;
    right: 44.44444% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/9\@desktop {
    width: 55.55556% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/9\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 55.55556% !important;
  }
  .u-pull-5\/9\@desktop {
    position: relative !important;
    right: 55.55556% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/9\@desktop {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/9\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-6\/9\@desktop {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/9\@desktop {
    width: 77.77778% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/9\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 77.77778% !important;
  }
  .u-pull-7\/9\@desktop {
    position: relative !important;
    right: 77.77778% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/9\@desktop {
    width: 88.88889% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/9\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 88.88889% !important;
  }
  .u-pull-8\/9\@desktop {
    position: relative !important;
    right: 88.88889% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/9\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/9\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-9\/9\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/10\@desktop {
    width: 10% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 10% !important;
  }
  .u-pull-1\/10\@desktop {
    position: relative !important;
    right: 10% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/10\@desktop {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-2\/10\@desktop {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/10\@desktop {
    width: 30% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 30% !important;
  }
  .u-pull-3\/10\@desktop {
    position: relative !important;
    right: 30% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/10\@desktop {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-4\/10\@desktop {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/10\@desktop {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-5\/10\@desktop {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/10\@desktop {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-6\/10\@desktop {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/10\@desktop {
    width: 70% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 70% !important;
  }
  .u-pull-7\/10\@desktop {
    position: relative !important;
    right: 70% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/10\@desktop {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-8\/10\@desktop {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/10\@desktop {
    width: 90% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90% !important;
  }
  .u-pull-9\/10\@desktop {
    position: relative !important;
    right: 90% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/10\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/10\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-10\/10\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/11\@desktop {
    width: 9.09091% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 9.09091% !important;
  }
  .u-pull-1\/11\@desktop {
    position: relative !important;
    right: 9.09091% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/11\@desktop {
    width: 18.18182% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 18.18182% !important;
  }
  .u-pull-2\/11\@desktop {
    position: relative !important;
    right: 18.18182% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/11\@desktop {
    width: 27.27273% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 27.27273% !important;
  }
  .u-pull-3\/11\@desktop {
    position: relative !important;
    right: 27.27273% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/11\@desktop {
    width: 36.36364% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 36.36364% !important;
  }
  .u-pull-4\/11\@desktop {
    position: relative !important;
    right: 36.36364% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/11\@desktop {
    width: 45.45455% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 45.45455% !important;
  }
  .u-pull-5\/11\@desktop {
    position: relative !important;
    right: 45.45455% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/11\@desktop {
    width: 54.54545% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 54.54545% !important;
  }
  .u-pull-6\/11\@desktop {
    position: relative !important;
    right: 54.54545% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/11\@desktop {
    width: 63.63636% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 63.63636% !important;
  }
  .u-pull-7\/11\@desktop {
    position: relative !important;
    right: 63.63636% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/11\@desktop {
    width: 72.72727% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 72.72727% !important;
  }
  .u-pull-8\/11\@desktop {
    position: relative !important;
    right: 72.72727% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/11\@desktop {
    width: 81.81818% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 81.81818% !important;
  }
  .u-pull-9\/11\@desktop {
    position: relative !important;
    right: 81.81818% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/11\@desktop {
    width: 90.90909% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90.90909% !important;
  }
  .u-pull-10\/11\@desktop {
    position: relative !important;
    right: 90.90909% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/11\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/11\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-11\/11\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/12\@desktop {
    width: 8.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 8.33333% !important;
  }
  .u-pull-1\/12\@desktop {
    position: relative !important;
    right: 8.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/12\@desktop {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-2\/12\@desktop {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/12\@desktop {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-3\/12\@desktop {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/12\@desktop {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-4\/12\@desktop {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/12\@desktop {
    width: 41.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 41.66667% !important;
  }
  .u-pull-5\/12\@desktop {
    position: relative !important;
    right: 41.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/12\@desktop {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-6\/12\@desktop {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/12\@desktop {
    width: 58.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 58.33333% !important;
  }
  .u-pull-7\/12\@desktop {
    position: relative !important;
    right: 58.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/12\@desktop {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-8\/12\@desktop {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/12\@desktop {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-9\/12\@desktop {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/12\@desktop {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-10\/12\@desktop {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/12\@desktop {
    width: 91.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 91.66667% !important;
  }
  .u-pull-11\/12\@desktop {
    position: relative !important;
    right: 91.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-12\/12\@desktop {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-12\/12\@desktop {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-12\/12\@desktop {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
}

@media (min-width: 71.25em) {
  .u-1\/1\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/1\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-1\/1\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/2\@page_width {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/2\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-1\/2\@page_width {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/2\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/2\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-2\/2\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/3\@page_width {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/3\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-1\/3\@page_width {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/3\@page_width {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/3\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-2\/3\@page_width {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/3\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/3\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-3\/3\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/4\@page_width {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/4\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-1\/4\@page_width {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/4\@page_width {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/4\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-2\/4\@page_width {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/4\@page_width {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/4\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-3\/4\@page_width {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/4\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/4\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-4\/4\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/5\@page_width {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/5\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-1\/5\@page_width {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/5\@page_width {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/5\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-2\/5\@page_width {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/5\@page_width {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/5\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-3\/5\@page_width {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/5\@page_width {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/5\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-4\/5\@page_width {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/5\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/5\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-5\/5\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/6\@page_width {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/6\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-1\/6\@page_width {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/6\@page_width {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/6\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-2\/6\@page_width {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/6\@page_width {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/6\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-3\/6\@page_width {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/6\@page_width {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/6\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-4\/6\@page_width {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/6\@page_width {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/6\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-5\/6\@page_width {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/6\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/6\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-6\/6\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/7\@page_width {
    width: 14.28571% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/7\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 14.28571% !important;
  }
  .u-pull-1\/7\@page_width {
    position: relative !important;
    right: 14.28571% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/7\@page_width {
    width: 28.57143% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/7\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 28.57143% !important;
  }
  .u-pull-2\/7\@page_width {
    position: relative !important;
    right: 28.57143% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/7\@page_width {
    width: 42.85714% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/7\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 42.85714% !important;
  }
  .u-pull-3\/7\@page_width {
    position: relative !important;
    right: 42.85714% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/7\@page_width {
    width: 57.14286% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/7\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 57.14286% !important;
  }
  .u-pull-4\/7\@page_width {
    position: relative !important;
    right: 57.14286% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/7\@page_width {
    width: 71.42857% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/7\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 71.42857% !important;
  }
  .u-pull-5\/7\@page_width {
    position: relative !important;
    right: 71.42857% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/7\@page_width {
    width: 85.71429% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/7\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 85.71429% !important;
  }
  .u-pull-6\/7\@page_width {
    position: relative !important;
    right: 85.71429% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/7\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/7\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-7\/7\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/8\@page_width {
    width: 12.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/8\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 12.5% !important;
  }
  .u-pull-1\/8\@page_width {
    position: relative !important;
    right: 12.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/8\@page_width {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/8\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-2\/8\@page_width {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/8\@page_width {
    width: 37.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/8\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 37.5% !important;
  }
  .u-pull-3\/8\@page_width {
    position: relative !important;
    right: 37.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/8\@page_width {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/8\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-4\/8\@page_width {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/8\@page_width {
    width: 62.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/8\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 62.5% !important;
  }
  .u-pull-5\/8\@page_width {
    position: relative !important;
    right: 62.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/8\@page_width {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/8\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-6\/8\@page_width {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/8\@page_width {
    width: 87.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/8\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 87.5% !important;
  }
  .u-pull-7\/8\@page_width {
    position: relative !important;
    right: 87.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/8\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/8\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-8\/8\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/9\@page_width {
    width: 11.11111% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/9\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 11.11111% !important;
  }
  .u-pull-1\/9\@page_width {
    position: relative !important;
    right: 11.11111% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/9\@page_width {
    width: 22.22222% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/9\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 22.22222% !important;
  }
  .u-pull-2\/9\@page_width {
    position: relative !important;
    right: 22.22222% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/9\@page_width {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/9\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-3\/9\@page_width {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/9\@page_width {
    width: 44.44444% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/9\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 44.44444% !important;
  }
  .u-pull-4\/9\@page_width {
    position: relative !important;
    right: 44.44444% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/9\@page_width {
    width: 55.55556% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/9\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 55.55556% !important;
  }
  .u-pull-5\/9\@page_width {
    position: relative !important;
    right: 55.55556% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/9\@page_width {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/9\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-6\/9\@page_width {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/9\@page_width {
    width: 77.77778% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/9\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 77.77778% !important;
  }
  .u-pull-7\/9\@page_width {
    position: relative !important;
    right: 77.77778% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/9\@page_width {
    width: 88.88889% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/9\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 88.88889% !important;
  }
  .u-pull-8\/9\@page_width {
    position: relative !important;
    right: 88.88889% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/9\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/9\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-9\/9\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/10\@page_width {
    width: 10% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 10% !important;
  }
  .u-pull-1\/10\@page_width {
    position: relative !important;
    right: 10% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/10\@page_width {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-2\/10\@page_width {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/10\@page_width {
    width: 30% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 30% !important;
  }
  .u-pull-3\/10\@page_width {
    position: relative !important;
    right: 30% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/10\@page_width {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-4\/10\@page_width {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/10\@page_width {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-5\/10\@page_width {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/10\@page_width {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-6\/10\@page_width {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/10\@page_width {
    width: 70% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 70% !important;
  }
  .u-pull-7\/10\@page_width {
    position: relative !important;
    right: 70% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/10\@page_width {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-8\/10\@page_width {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/10\@page_width {
    width: 90% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90% !important;
  }
  .u-pull-9\/10\@page_width {
    position: relative !important;
    right: 90% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/10\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/10\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-10\/10\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/11\@page_width {
    width: 9.09091% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 9.09091% !important;
  }
  .u-pull-1\/11\@page_width {
    position: relative !important;
    right: 9.09091% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/11\@page_width {
    width: 18.18182% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 18.18182% !important;
  }
  .u-pull-2\/11\@page_width {
    position: relative !important;
    right: 18.18182% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/11\@page_width {
    width: 27.27273% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 27.27273% !important;
  }
  .u-pull-3\/11\@page_width {
    position: relative !important;
    right: 27.27273% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/11\@page_width {
    width: 36.36364% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 36.36364% !important;
  }
  .u-pull-4\/11\@page_width {
    position: relative !important;
    right: 36.36364% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/11\@page_width {
    width: 45.45455% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 45.45455% !important;
  }
  .u-pull-5\/11\@page_width {
    position: relative !important;
    right: 45.45455% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/11\@page_width {
    width: 54.54545% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 54.54545% !important;
  }
  .u-pull-6\/11\@page_width {
    position: relative !important;
    right: 54.54545% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/11\@page_width {
    width: 63.63636% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 63.63636% !important;
  }
  .u-pull-7\/11\@page_width {
    position: relative !important;
    right: 63.63636% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/11\@page_width {
    width: 72.72727% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 72.72727% !important;
  }
  .u-pull-8\/11\@page_width {
    position: relative !important;
    right: 72.72727% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/11\@page_width {
    width: 81.81818% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 81.81818% !important;
  }
  .u-pull-9\/11\@page_width {
    position: relative !important;
    right: 81.81818% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/11\@page_width {
    width: 90.90909% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90.90909% !important;
  }
  .u-pull-10\/11\@page_width {
    position: relative !important;
    right: 90.90909% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/11\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/11\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-11\/11\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/12\@page_width {
    width: 8.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 8.33333% !important;
  }
  .u-pull-1\/12\@page_width {
    position: relative !important;
    right: 8.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/12\@page_width {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-2\/12\@page_width {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/12\@page_width {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-3\/12\@page_width {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/12\@page_width {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-4\/12\@page_width {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/12\@page_width {
    width: 41.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 41.66667% !important;
  }
  .u-pull-5\/12\@page_width {
    position: relative !important;
    right: 41.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/12\@page_width {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-6\/12\@page_width {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/12\@page_width {
    width: 58.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 58.33333% !important;
  }
  .u-pull-7\/12\@page_width {
    position: relative !important;
    right: 58.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/12\@page_width {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-8\/12\@page_width {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/12\@page_width {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-9\/12\@page_width {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/12\@page_width {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-10\/12\@page_width {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/12\@page_width {
    width: 91.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 91.66667% !important;
  }
  .u-pull-11\/12\@page_width {
    position: relative !important;
    right: 91.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-12\/12\@page_width {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-12\/12\@page_width {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-12\/12\@page_width {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
}

@media (min-width: 81.25em) {
  .u-1\/1\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/1\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-1\/1\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/2\@wide {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/2\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-1\/2\@wide {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/2\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/2\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-2\/2\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/3\@wide {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/3\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-1\/3\@wide {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/3\@wide {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/3\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-2\/3\@wide {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/3\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/3\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-3\/3\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/4\@wide {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/4\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-1\/4\@wide {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/4\@wide {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/4\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-2\/4\@wide {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/4\@wide {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/4\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-3\/4\@wide {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/4\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/4\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-4\/4\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/5\@wide {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/5\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-1\/5\@wide {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/5\@wide {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/5\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-2\/5\@wide {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/5\@wide {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/5\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-3\/5\@wide {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/5\@wide {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/5\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-4\/5\@wide {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/5\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/5\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-5\/5\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/6\@wide {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/6\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-1\/6\@wide {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/6\@wide {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/6\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-2\/6\@wide {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/6\@wide {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/6\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-3\/6\@wide {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/6\@wide {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/6\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-4\/6\@wide {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/6\@wide {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/6\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-5\/6\@wide {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/6\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/6\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-6\/6\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/7\@wide {
    width: 14.28571% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/7\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 14.28571% !important;
  }
  .u-pull-1\/7\@wide {
    position: relative !important;
    right: 14.28571% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/7\@wide {
    width: 28.57143% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/7\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 28.57143% !important;
  }
  .u-pull-2\/7\@wide {
    position: relative !important;
    right: 28.57143% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/7\@wide {
    width: 42.85714% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/7\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 42.85714% !important;
  }
  .u-pull-3\/7\@wide {
    position: relative !important;
    right: 42.85714% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/7\@wide {
    width: 57.14286% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/7\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 57.14286% !important;
  }
  .u-pull-4\/7\@wide {
    position: relative !important;
    right: 57.14286% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/7\@wide {
    width: 71.42857% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/7\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 71.42857% !important;
  }
  .u-pull-5\/7\@wide {
    position: relative !important;
    right: 71.42857% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/7\@wide {
    width: 85.71429% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/7\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 85.71429% !important;
  }
  .u-pull-6\/7\@wide {
    position: relative !important;
    right: 85.71429% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/7\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/7\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-7\/7\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/8\@wide {
    width: 12.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/8\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 12.5% !important;
  }
  .u-pull-1\/8\@wide {
    position: relative !important;
    right: 12.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/8\@wide {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/8\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-2\/8\@wide {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/8\@wide {
    width: 37.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/8\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 37.5% !important;
  }
  .u-pull-3\/8\@wide {
    position: relative !important;
    right: 37.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/8\@wide {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/8\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-4\/8\@wide {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/8\@wide {
    width: 62.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/8\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 62.5% !important;
  }
  .u-pull-5\/8\@wide {
    position: relative !important;
    right: 62.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/8\@wide {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/8\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-6\/8\@wide {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/8\@wide {
    width: 87.5% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/8\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 87.5% !important;
  }
  .u-pull-7\/8\@wide {
    position: relative !important;
    right: 87.5% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/8\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/8\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-8\/8\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/9\@wide {
    width: 11.11111% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/9\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 11.11111% !important;
  }
  .u-pull-1\/9\@wide {
    position: relative !important;
    right: 11.11111% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/9\@wide {
    width: 22.22222% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/9\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 22.22222% !important;
  }
  .u-pull-2\/9\@wide {
    position: relative !important;
    right: 22.22222% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/9\@wide {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/9\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-3\/9\@wide {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/9\@wide {
    width: 44.44444% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/9\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 44.44444% !important;
  }
  .u-pull-4\/9\@wide {
    position: relative !important;
    right: 44.44444% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/9\@wide {
    width: 55.55556% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/9\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 55.55556% !important;
  }
  .u-pull-5\/9\@wide {
    position: relative !important;
    right: 55.55556% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/9\@wide {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/9\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-6\/9\@wide {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/9\@wide {
    width: 77.77778% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/9\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 77.77778% !important;
  }
  .u-pull-7\/9\@wide {
    position: relative !important;
    right: 77.77778% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/9\@wide {
    width: 88.88889% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/9\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 88.88889% !important;
  }
  .u-pull-8\/9\@wide {
    position: relative !important;
    right: 88.88889% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/9\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/9\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-9\/9\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/10\@wide {
    width: 10% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 10% !important;
  }
  .u-pull-1\/10\@wide {
    position: relative !important;
    right: 10% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/10\@wide {
    width: 20% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 20% !important;
  }
  .u-pull-2\/10\@wide {
    position: relative !important;
    right: 20% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/10\@wide {
    width: 30% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 30% !important;
  }
  .u-pull-3\/10\@wide {
    position: relative !important;
    right: 30% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/10\@wide {
    width: 40% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 40% !important;
  }
  .u-pull-4\/10\@wide {
    position: relative !important;
    right: 40% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/10\@wide {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-5\/10\@wide {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/10\@wide {
    width: 60% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 60% !important;
  }
  .u-pull-6\/10\@wide {
    position: relative !important;
    right: 60% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/10\@wide {
    width: 70% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 70% !important;
  }
  .u-pull-7\/10\@wide {
    position: relative !important;
    right: 70% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/10\@wide {
    width: 80% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 80% !important;
  }
  .u-pull-8\/10\@wide {
    position: relative !important;
    right: 80% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/10\@wide {
    width: 90% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90% !important;
  }
  .u-pull-9\/10\@wide {
    position: relative !important;
    right: 90% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/10\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/10\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-10\/10\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/11\@wide {
    width: 9.09091% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 9.09091% !important;
  }
  .u-pull-1\/11\@wide {
    position: relative !important;
    right: 9.09091% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/11\@wide {
    width: 18.18182% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 18.18182% !important;
  }
  .u-pull-2\/11\@wide {
    position: relative !important;
    right: 18.18182% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/11\@wide {
    width: 27.27273% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 27.27273% !important;
  }
  .u-pull-3\/11\@wide {
    position: relative !important;
    right: 27.27273% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/11\@wide {
    width: 36.36364% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 36.36364% !important;
  }
  .u-pull-4\/11\@wide {
    position: relative !important;
    right: 36.36364% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/11\@wide {
    width: 45.45455% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 45.45455% !important;
  }
  .u-pull-5\/11\@wide {
    position: relative !important;
    right: 45.45455% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/11\@wide {
    width: 54.54545% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 54.54545% !important;
  }
  .u-pull-6\/11\@wide {
    position: relative !important;
    right: 54.54545% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/11\@wide {
    width: 63.63636% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 63.63636% !important;
  }
  .u-pull-7\/11\@wide {
    position: relative !important;
    right: 63.63636% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/11\@wide {
    width: 72.72727% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 72.72727% !important;
  }
  .u-pull-8\/11\@wide {
    position: relative !important;
    right: 72.72727% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/11\@wide {
    width: 81.81818% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 81.81818% !important;
  }
  .u-pull-9\/11\@wide {
    position: relative !important;
    right: 81.81818% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/11\@wide {
    width: 90.90909% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 90.90909% !important;
  }
  .u-pull-10\/11\@wide {
    position: relative !important;
    right: 90.90909% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/11\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/11\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-11\/11\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
  .u-1\/12\@wide {
    width: 8.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-1\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 8.33333% !important;
  }
  .u-pull-1\/12\@wide {
    position: relative !important;
    right: 8.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-2\/12\@wide {
    width: 16.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-2\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 16.66667% !important;
  }
  .u-pull-2\/12\@wide {
    position: relative !important;
    right: 16.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-3\/12\@wide {
    width: 25% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-3\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 25% !important;
  }
  .u-pull-3\/12\@wide {
    position: relative !important;
    right: 25% !important;
    left: auto !important;
    /* [1] */
  }
  .u-4\/12\@wide {
    width: 33.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-4\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 33.33333% !important;
  }
  .u-pull-4\/12\@wide {
    position: relative !important;
    right: 33.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-5\/12\@wide {
    width: 41.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-5\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 41.66667% !important;
  }
  .u-pull-5\/12\@wide {
    position: relative !important;
    right: 41.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-6\/12\@wide {
    width: 50% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-6\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 50% !important;
  }
  .u-pull-6\/12\@wide {
    position: relative !important;
    right: 50% !important;
    left: auto !important;
    /* [1] */
  }
  .u-7\/12\@wide {
    width: 58.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-7\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 58.33333% !important;
  }
  .u-pull-7\/12\@wide {
    position: relative !important;
    right: 58.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-8\/12\@wide {
    width: 66.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-8\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 66.66667% !important;
  }
  .u-pull-8\/12\@wide {
    position: relative !important;
    right: 66.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-9\/12\@wide {
    width: 75% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-9\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 75% !important;
  }
  .u-pull-9\/12\@wide {
    position: relative !important;
    right: 75% !important;
    left: auto !important;
    /* [1] */
  }
  .u-10\/12\@wide {
    width: 83.33333% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-10\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 83.33333% !important;
  }
  .u-pull-10\/12\@wide {
    position: relative !important;
    right: 83.33333% !important;
    left: auto !important;
    /* [1] */
  }
  .u-11\/12\@wide {
    width: 91.66667% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-11\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 91.66667% !important;
  }
  .u-pull-11\/12\@wide {
    position: relative !important;
    right: 91.66667% !important;
    left: auto !important;
    /* [1] */
  }
  .u-12\/12\@wide {
    width: 100% !important;
  }
  /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */
  .u-push-12\/12\@wide {
    position: relative !important;
    right: auto !important;
    /* [1] */
    left: 100% !important;
  }
  .u-pull-12\/12\@wide {
    position: relative !important;
    right: 100% !important;
    left: auto !important;
    /* [1] */
  }
}

/* ==========================================================================
   #SPACINGS
   ========================================================================== */
/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 *   .u-padding-horizontal {}
 *   .u-padding-vertical-small {}
 */
/* stylelint-disable string-quotes */
.u-padding {
  padding: 24px !important;
}

.u-padding-tiny {
  padding: 6px !important;
}

.u-padding-small {
  padding: 12px !important;
}

.u-padding-large {
  padding: 48px !important;
}

.u-padding-huge {
  padding: 96px !important;
}

.u-padding-none {
  padding: 0 !important;
}

.u-padding-top {
  padding-top: 24px !important;
}

.u-padding-top-tiny {
  padding-top: 6px !important;
}

.u-padding-top-small {
  padding-top: 12px !important;
}

.u-padding-top-large {
  padding-top: 48px !important;
}

.u-padding-top-huge {
  padding-top: 96px !important;
}

.u-padding-top-none {
  padding-top: 0 !important;
}

.u-padding-right {
  padding-right: 24px !important;
}

.u-padding-right-tiny {
  padding-right: 6px !important;
}

.u-padding-right-small {
  padding-right: 12px !important;
}

.u-padding-right-large {
  padding-right: 48px !important;
}

.u-padding-right-huge {
  padding-right: 96px !important;
}

.u-padding-right-none {
  padding-right: 0 !important;
}

.u-padding-bottom {
  padding-bottom: 24px !important;
}

.u-padding-bottom-tiny {
  padding-bottom: 6px !important;
}

.u-padding-bottom-small {
  padding-bottom: 12px !important;
}

.u-padding-bottom-large {
  padding-bottom: 48px !important;
}

.u-padding-bottom-huge {
  padding-bottom: 96px !important;
}

.u-padding-bottom-none {
  padding-bottom: 0 !important;
}

.u-padding-left {
  padding-left: 24px !important;
}

.u-padding-left-tiny {
  padding-left: 6px !important;
}

.u-padding-left-small {
  padding-left: 12px !important;
}

.u-padding-left-large {
  padding-left: 48px !important;
}

.u-padding-left-huge {
  padding-left: 96px !important;
}

.u-padding-left-none {
  padding-left: 0 !important;
}

.u-padding-horizontal {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.u-padding-horizontal-tiny {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.u-padding-horizontal-small {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.u-padding-horizontal-large {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.u-padding-horizontal-huge {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.u-padding-horizontal-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.u-padding-vertical {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.u-padding-vertical-tiny {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.u-padding-vertical-small {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.u-padding-vertical-large {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.u-padding-vertical-huge {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.u-padding-vertical-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.u-margin {
  margin: 24px !important;
}

.u-margin-tiny {
  margin: 6px !important;
}

.u-margin-small {
  margin: 12px !important;
}

.u-margin-large {
  margin: 48px !important;
}

.u-margin-huge {
  margin: 96px !important;
}

.u-margin-none {
  margin: 0 !important;
}

.u-margin-top {
  margin-top: 24px !important;
}

.u-margin-top-tiny {
  margin-top: 6px !important;
}

.u-margin-top-small {
  margin-top: 12px !important;
}

.u-margin-top-large {
  margin-top: 48px !important;
}

.u-margin-top-huge {
  margin-top: 96px !important;
}

.u-margin-top-none {
  margin-top: 0 !important;
}

.u-margin-right {
  margin-right: 24px !important;
}

.u-margin-right-tiny {
  margin-right: 6px !important;
}

.u-margin-right-small {
  margin-right: 12px !important;
}

.u-margin-right-large {
  margin-right: 48px !important;
}

.u-margin-right-huge {
  margin-right: 96px !important;
}

.u-margin-right-none {
  margin-right: 0 !important;
}

.u-margin-bottom {
  margin-bottom: 24px !important;
}

.u-margin-bottom-tiny {
  margin-bottom: 6px !important;
}

.u-margin-bottom-small {
  margin-bottom: 12px !important;
}

.u-margin-bottom-large {
  margin-bottom: 48px !important;
}

.u-margin-bottom-huge {
  margin-bottom: 96px !important;
}

.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

.u-margin-left {
  margin-left: 24px !important;
}

.u-margin-left-tiny {
  margin-left: 6px !important;
}

.u-margin-left-small {
  margin-left: 12px !important;
}

.u-margin-left-large {
  margin-left: 48px !important;
}

.u-margin-left-huge {
  margin-left: 96px !important;
}

.u-margin-left-none {
  margin-left: 0 !important;
}

.u-margin-horizontal {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.u-margin-horizontal-tiny {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.u-margin-horizontal-small {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.u-margin-horizontal-large {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.u-margin-horizontal-huge {
  margin-left: 96px !important;
  margin-right: 96px !important;
}

.u-margin-horizontal-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.u-margin-vertical {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.u-margin-vertical-tiny {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.u-margin-vertical-small {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.u-margin-vertical-large {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.u-margin-vertical-huge {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.u-margin-vertical-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* stylelint-enable string-quotes */
/* ==========================================================================
   #PRINT
   ========================================================================== */
/**
 * Very crude, reset-like styles taken from the HTML5 Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/5.3.0/dist/doc/css.md#print-styles
 * https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css#L205-L282
 */
@media print {
  /**
   * 1. Black prints faster: http://www.sanbeiji.com/archives/953
   */
  *,
  *:before,
  *:after,
  *:first-letter,
  *:first-line {
    background: transparent !important;
    color: #000 !important;
    /* [1] */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  /**
   * Don't show links that are fragment identifiers, or use the `javascript:`
   * pseudo protocol.
   */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  /**
   * Printing Tables: http://css-discuss.incutio.com/wiki/Printing_Tables
   */
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-align--left {
  text-align: left;
}

.u-align--center {
  text-align: center;
}

.u-align--right {
  text-align: right;
}

.u-no-margin {
  margin: 0;
}

.u-nowrap {
  white-space: nowrap !important;
}

#social {
  background-color: #252525;
  padding-bottom: 5em;
}

#social h1 {
  position: relative;
  z-index: 6;
  background-color: #000;
  padding: 0.75em 1em;
}

.loading-gif {
  max-width: 229px;
  width: auto;
}

.wrap {
  max-width: 1200px;
  margin: 10rem auto;
  border: 1px solid #333;
  background: #eee;
  position: relative;
  z-index: 1;
}

.grid-container {
  overflow: hidden;
}

.grid {
  margin: 0;
  font-size: 0;
  line-height: 0;
  position: relative;
  z-index: 5;
  overflow: visible !important;
}

.grid__item {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  line-height: 1rem;
}

.grid__item:hover {
  z-index: 3;
}

[data-grid-width="1"] .grid__item {
  width: calc(100%/1);
}

[data-grid-width="2"] .grid__item {
  width: calc(100%/2);
}

[data-grid-width="3"] .grid__item {
  width: calc(100%/3);
}

[data-grid-width="4"] .grid__item {
  width: calc(100%/4);
}

[data-grid-width="5"] .grid__item {
  width: calc(100%/5);
}

[data-grid-width="6"] .grid__item {
  width: calc(100%/6);
}

[data-grid-width="7"] .grid__item {
  width: calc(100%/7);
}

.grid__item.hover .twine-item {
  pointer-events: none;
}

.grid__item.hover .twine-item .twine-item-overlay {
  transform: translateY(0);
}

.grid__item.hover .twine-item .twine-icon use {
  fill: #f28035;
}

.grid__item.hover .twine-item .twine-item__snipe use {
  fill: #f28035;
}

.grid__item.hover .twine-item .twine-item__arrow-active {
  display: block;
}

.grid__item.hover .twine-item .twine-item__arrow {
  display: none;
}

.grid__item.hover .twine-item:hover .twine-item-overlay {
  transform: translateY(0);
}

.grid__item.hover .twine-item:hover .twine-icon use {
  fill: #f28035;
}

.grid__item.hover .twine-item:hover .twine-item__snipe use {
  fill: #f28035;
}

.grid__item.hover .twine-item:hover .twine-item__arrow-active {
  display: block;
}

.grid__item.hover .twine-item:hover .twine-item__arrow {
  display: none;
}

.grid--flipper .grid__item:not(.hover) .twine-item-overlay {
  display: none !important;
}

.twine-item {
  position: relative;
  z-index: 1;
  perspective: 1000;
}

.twine-item,
.twine-item * {
  pointer-events: none;
}

.twine-item.hover {
  z-index: 999;
}

.twine-item a {
  cursor: pointer;
}

.twine-item:hover .twine-item-overlay {
  transform: translateY(0);
}

.twine-item:hover .twine-icon use {
  fill: #f28035;
}

.twine-item:hover .twine-item__snipe use {
  fill: #f28035;
}

.twine-item:hover .twine-item__arrow-active {
  display: block;
}

.twine-item:hover .twine-item__arrow {
  display: none;
}

.grid--flipper .twine-item .twine-item__foot {
  display: none;
}

.grid--flipper .twine-item:hover .twine-item-overlay {
  transform: translateY(-200%);
}

.grid--flipper .twine-item:hover .twine-icon use {
  fill: #ffffff;
}

.grid--flipper .twine-item:hover .twine-item__snipe use {
  fill: #ffffff;
}

.grid--flipper .twine-item:hover .twine-item__arrow-active {
  display: none;
}

.grid--flipper .twine-item:hover .twine-item__arrow {
  display: block;
}

.hexagon-container {
  height: 0;
  padding-bottom: 86.4%;
  position: relative;
  transition: transform 0.4s ease-in-out;
}

.hexagon-container svg {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hexagon-container svg * {
  pointer-events: all;
}

.twine-icon use {
  z-index: 200;
  transition: fill 500ms ease;
}

.twine-item__snipe use {
  transition: fill 500ms ease;
}

.twine-item__arrow-active {
  display: none;
}

.twine-item__head,
.twine-item__foot {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.twine-item__head text,
.twine-item__foot text {
  fill: #f28035;
  font-family: 'Open Sans Condensed', sans-serif;
}

.twine-item.hover .twine-item__head,
.twine-item:hover .twine-item__head, .twine-item.hover
.twine-item__foot,
.twine-item:hover
.twine-item__foot {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.twine-item__head .username {
  font-size: 5.2px;
  fill: #f28035;
  text-transform: uppercase;
}

@media (max-width: 40.615em) {
  .twine-item__head .username {
    font-size: 5.2px;
  }
}

.twine-item__head .time {
  font-size: 3px;
  fill: #fff;
}

.twine-item__head rect {
  transition: fill 0.2s linear;
}

.twine-item__head:hover rect {
  fill: #f28035;
}

.twine-item-overlay {
  background-color: rgba(37, 37, 37, 0.9);
  transition: 500ms ease;
  transform: translateY(-200%);
}

.twine-item__body {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.twine-item__body * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.twine-item__body p {
  overflow: hidden;
  height: 54.5px;
  font-size: 4.5px;
  line-height: 6px;
  color: #fff;
  padding: 0 1em;
  padding-bottom: 1em;
  text-align: left;
  box-sizing: border-box;
  margin: 0;
}

@media (max-width: 40.615em) {
  .twine-item__body p {
    font-size: 5.5px;
    line-height: 1.4;
  }
}

.twine-item__body .text-wrap-shape {
  display: block;
  height: 56.4px;
  width: 19.2px;
}

.twine-item__body .text-wrap-shape.left {
  float: left;
  shape-outside: polygon(0px 0px, 13.5px 0px, 0px 23.2px, 19.2px 56.4px, 0px 56.4px);
  margin-right: 0.5px;
}

.twine-item__body .text-wrap-shape.right {
  float: right;
  shape-outside: polygon(19.2px 0px, 19.2px 56.4px, 0px 56.4px, 19.2px 23.2px, 5.7px 0px);
  margin-left: 0.5px;
}

.twine-item.hover .twine-item__body,
.twine-item:hover .twine-item__body {
  opacity: 1;
}

.twine-item__body.svg-fallback {
  position: absolute;
  display: block;
  top: 23%;
  bottom: 11.6%;
  left: 19.5%;
  width: 61%;
  padding: 1rem;
  box-sizing: border-box;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #fff;
  overflow: hidden;
}

.no-svgforeignobject foreignObject {
  display: none;
}

.svgforeignobject .svg-fallback {
  display: none;
}

.twine-item__foot a {
  transition: fill 0.2s linear;
}

.twine-item__foot a:hover * {
  fill: #f28035;
}

.twine-item__snipe.snipe--head {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s linear;
}

.grid--flipper {
  padding-left: 6%;
  padding-right: 6%;
}

.grid--flipper svg {
  transition: all 1s linear;
}

.grid--flipper .grid__item:hover .twine-item__head,
.grid--flipper .grid__item:hover .twine-item__body,
.grid--flipper .grid__item:hover .twine-item__foot,
.grid--flipper .hover .twine-item__head,
.grid--flipper .hover .twine-item__body,
.grid--flipper .hover .twine-item__foot {
  opacity: 1;
}

.grid--zoom-in .hexagon-container {
  opacity: 0;
  transform: scale(0.75);
  transition: opacity 0.2s linear, transform 0.2s linear;
}

.grid--zoom-in .zoomed-in .hexagon-container {
  opacity: 1;
  transform: scale(1);
}

.load-more-container {
  position: relative;
  z-index: 10;
  background: #000;
  text-align: center;
}

.load-more-container:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-bottom: 3%;
  background: #000;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
}

.grid--load_more + .load-more-container:before {
  margin-bottom: 3px;
}

.load-more-button {
  transition: 250ms ease;
  color: #f28035;
  padding: 1em 2em 0;
  display: inline-block;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: bold;
  position: relative;
}

.load-more-button:hover {
  color: #fff;
}

.load-more-button:hover:after {
  border-color: #fff;
}

.load-more-button:after {
  transition: 250ms ease;
  content: '';
  border-style: solid;
  border-width: 0.17em 0.17em 0 0;
  display: block;
  height: 0.65em;
  left: 0.15em;
  position: relative;
  vertical-align: top;
  width: 0.65em;
  top: -5px;
  transform: rotate(135deg);
  border-color: #f28035;
  margin: 0 auto;
}

.o-icon--boxed {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  background-color: #f28035;
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 1px solid #f28035;
  cursor: pointer;
  margin-right: 5px;
}

.o-icon--boxed:hover {
  background-color: transparent;
}

.o-icon--boxed:hover .o-icon {
  fill: #f28035;
}

.o-icon--boxed .o-icon {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  width: 90%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  fill: #ffffff;
}

.o-icon--boxed:last-child {
  margin-right: 0;
}

.o-icon--inline-button {
  position: relative;
}

.o-icon--inline-button .o-icon--wrapper {
  width: 2em;
  height: 1.5em;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0;
  right: .5em;
  bottom: 0;
  margin: auto;
}

.o-icon--inline-button .o-icon--wrapper .o-icon {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  fill: #ffffff;
}

.o-icon--inline-button:hover {
  background-color: transparent;
}

.o-icon--inline-button:hover .o-icon {
  fill: #f28035;
}

.o-icon--inline-button--left.button {
  padding-left: 2.5em;
}

.o-icon--inline-button--right.button {
  padding-right: 2.5em;
}

.o-icon--inline-button--left .o-icon--wrapper {
  left: .5em;
  right: auto;
}

.o-icon--inline-button--flip .o-icon {
  transform: rotate(180deg);
}

.o-icon--facebook {
  width: 40px;
  height: 40px;
  background-color: #3b5998;
  border: 0;
  border-radius: 4px;
}

.o-icon--facebook:hover {
  background-color: #5f7ec1;
}

.o-icon--facebook:hover .o-icon {
  fill: #ffffff;
}

.o-icon--facebook .o-icon {
  left: 17%;
  top: 30%;
}

.o-icon--twitter {
  width: 40px;
  height: 40px;
  background-color: #1da1f2;
  border: 0;
  border-radius: 4px;
}

.o-icon--twitter:hover {
  background-color: #65bff6;
}

.o-icon--twitter:hover .o-icon {
  fill: #ffffff;
}

.o-icon--twitter .o-icon {
  padding: .5em;
}

.remodal.twine-modal {
  padding: 0;
  max-width: 900px;
}

@media screen and (min-width: 700px) {
  .remodal.twine-modal .twine-container {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .remodal.twine-modal .twine-row {
    display: table-row;
  }
  .remodal.twine-modal .twine-cell {
    display: table-cell;
  }
  .remodal.twine-modal .twine-media {
    width: 60%;
    vertical-align: middle;
  }
  .remodal.twine-modal .twine-content {
    width: 40%;
    vertical-align: top;
    height: 100%;
  }
  .remodal.twine-modal .remodal-close {
    top: auto;
    right: auto;
    left: 100%;
    bottom: 100%;
    background: #fff;
    color: #000;
  }
  .remodal.twine-modal .remodal-close:hover {
    background: #f28035;
  }
  .remodal.twine-modal .remodal-close:after {
    line-height: 1.1;
  }
}

.remodal.twine-modal .twine-content {
  text-align: left;
}

.remodal.twine-modal .liner {
  position: relative;
}

.twine-media {
  line-height: 0;
}

.twine-media .video-container {
  padding-bottom: 100%;
}

.twine-media img {
  max-width: 100%;
  height: auto;
  min-height: 400px;
}

.twine-content {
  position: relative;
}

.twine-content .profile {
  overflow: hidden;
}

.twine-content .profile-link {
  display: block;
}

.twine-content .profile-link:hover {
  background-color: #f28035;
  color: #fff;
  fill: #fff;
}

.twine-content .profile__image {
  width: auto;
  max-width: 20%;
  height: auto;
  float: left;
  margin-right: 2%;
}

.twine-content .profile__name {
  font-size: 2rem;
  line-height: 1.4;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  margin: 0 0 0.5rem;
}

.twine-content .profile__network-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-right: 2%;
  vertical-align: middle;
}

.twine-post {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.twine-post .post__actions {
  background: #ddd;
  padding: 5px 20px;
  line-height: 0;
}

.twine-post .post__actions a {
  display: inline-block;
  margin-right: 3%;
  height: 2rem;
  fill: #555;
}

.twine-post .post__actions a:hover {
  fill: #f28035;
}

.twine-post .post__actions a .icon--facebook {
  width: 2rem;
}

.twine-post .post__actions a .icon--twitter {
  width: 2.45rem;
}

.twine-post .post__actions a .icon--google-plus {
  width: 3.2rem;
}

.twine-post .post__actions a .icon--pinterest {
  width: 2rem;
}

.twine-post .post__body {
  padding: 5px 20px;
  box-sizing: border-box;
  font-size: 1.5rem;
  line-height: 2rem;
}

.twine-post .post__body p {
  margin-top: 0;
  word-wrap: break-word;
}

.twine-modal .box {
  padding: 20px;
}

.twine-media__cta {
  position: absolute;
  width: 4rem;
  height: 4rem;
  margin: -4rem;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  display: inline-block;
  transition: fill 0.2s linear, background-color 0.2s linear;
  background-color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  fill: #f28035;
}

.twine-media__cta:hover {
  background-color: #f28035;
  fill: #fff;
}

.post__cta {
  margin-top: 15px;
  padding: 20px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: none;
  overflow: hidden;
  background-color: #fff;
}

.post__cta .cta__img {
  width: auto;
  max-width: 30%;
  height: auto;
  float: left;
  margin-right: 2%;
}

.post__cta .cta__button {
  float: right;
  margin-top: 0.5em;
}

.iframe-wrapper {
  height: 0;
  padding-top: 56.25%;
}

.iframe-wrapper iframe {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 92%;
}

/*------------------------------------*    #BUTTONS:
\*------------------------------------*/
.button {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  border: 1px solid #f28035;
  -webkit-appearance: none;
  text-decoration: none;
  display: inline-block;
  background: #f28035;
  line-height: normal;
  padding: 6px 30px;
  border-radius: 0;
  font-size: 22px;
  color: #fff;
  margin: 0;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #fff;
  color: #f28035;
}

.button-send {
  padding: 5px 50px 5px 30px;
  display: inline-block;
  border: none;
  border-radius: 0;
  border-left: 1px solid #fff;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  background: #f28035 url("../img/bg-send.png") no-repeat 100% 100%;
  line-height: normal;
  margin: 0;
  text-decoration: none;
}

.button-send:hover {
  padding-right: 55px;
}

.button-ct {
  font-family: 'Open Sans Condensed', sans-serif;
  border: 1px solid #d2d2d2;
  text-align: center;
  background: #fff;
  color: #f28035;
}

.button-ct:hover,
.button-ct:active {
  border: 1px solid #f28035;
  background: #f28035;
  color: #fff;
}

.button-thin {
  font-family: 'Open Sans Condensed', sans-serif;
  padding: 6px 55px;
  font-size: 20px;
}

.button-play {
  position: absolute;
  max-width: 199px;
  max-height: 47px;
  margin: 18%;
}

.button-video {
  background: transparent;
  border: 0;
  padding: 80px !important;
}

.button-video:hover {
  background: transparent;
}

.banner-button {
  font-family: 'Open Sans Bold', sans-serif;
  line-height: 1.2;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
}

.banner-button--0 {
  margin: 27% 0 0 5%;
}

@media screen and (min-width: 650px) {
  .banner-button--0 {
    margin: 15% 0 0 10%;
  }
}

.banner-button--1 {
  margin: 20% 0 0 -13%;
}

@media screen and (min-width: 650px) {
  .banner-button--1 {
    margin: 9% 0 0 -44%;
  }
}

.banner-button--2 {
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
  top: auto;
}

.banner-button--3 {
  margin: 27% 0 0 -13%;
}

@media screen and (min-width: 650px) {
  .banner-button--3 {
    margin: 3% 0 0 16%;
  }
}

.page--ceros-circular #banner {
  min-height: calc(100vh - 200px - 160px);
}

.page--ceros-circular .container {
  padding: 0;
}

/*------------------------------------*    #HEADER/LOGO:
\*------------------------------------*/
#header {
  position: relative;
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
  text-align: right;
  z-index: 1001;
}

@media screen and (min-width: 820px) {
  #header {
    position: fixed;
  }
}

#header:before {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  content: '';
  display: none;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 150px;
  position: absolute;
  top: -150px;
  left: 0;
  z-index: 1;
  box-shadow: 0 0 100px 80px rgba(0, 0, 0, 0.7);
  opacity: 1;
  visibility: visible;
  pointer-events: none;
}

@media screen and (min-width: 820px) {
  #header:before {
    display: block;
  }
}

#header:after {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  background: #f28035;
  z-index: 1;
  height: 64px;
  padding-top: 20px;
  content: '';
  display: block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  -webkit-transform: rotate(1.2deg) skew(1.2deg) translateY(-12px);
  transform: rotate(1.2deg) skew(1.2deg) translateY(-12px);
  -ms-transform: rotate(1.2deg) skew(1.2deg) translateY(-12px);
}

@media screen and (min-width: 820px) {
  #header:after {
    opacity: 0;
    height: 200px;
    -webkit-transform: rotate(1.2deg) skew(1.2deg) translateY(-135px);
    transform: rotate(1.2deg) skew(1.2deg) translateY(-135px);
    -ms-transform: rotate(1.2deg) skew(1.2deg) translateY(-135px);
  }
}

@media screen and (min-width: 820px) {
  #header.background-visible:before {
    opacity: 0;
    visibility: hidden;
  }
  #header.background-visible:after {
    opacity: 1;
    -webkit-transform: rotate(1.2deg) skew(1.2deg) translateY(-125px);
    transform: rotate(1.2deg) skew(1.2deg) translateY(-125px);
    -ms-transform: rotate(1.2deg) skew(1.2deg) translateY(-125px);
  }
}

@media screen and (min-width: 820px) {
  #header + section:not(#header-video-container) {
    margin-top: 120px;
  }
}

#header nav {
  position: absolute;
  z-index: 999;
  right: 0;
}

#top-links li {
  margin: 0 8px 0 0;
}

#header #top-links {
  margin: 12px 0 0;
  padding-bottom: 7px;
}

#logo {
  position: absolute;
  background: #000;
  padding: 20px;
  width: 184px;
  height: 50px;
  z-index: 2;
  left: 10px;
  top: 15px;
  margin: 0;
}

@media screen and (min-width: 820px) {
  #logo {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

#logo.is-solid {
  background-color: black;
}

#logo .logo {
  width: 100%;
}

object.logo {
  pointer-events: none;
}

/*------------------------------------*    #VIDEO:
\*------------------------------------*/
#header-video-container {
  position: relative;
  height: 75vh;
  width: 100%;
  max-height: 50vw;
  overflow: hidden;
  margin-top: -18px;
}

@media screen and (min-width: 820px) {
  #header-video-container {
    margin-top: 0;
    min-height: 440px;
    max-height: 640px;
  }
}

#header-video-container video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

@media screen and (min-width: 680px) {
  #header-video-container video {
    height: auto;
  }
}

@media (max-width: 40.615em) {
  .c-header-video {
    max-height: 425px !important;
  }
}

.c-header-video .container {
  position: relative;
  z-index: 2;
  height: 100%;
}

.c-header-video__content {
  width: 100%;
  z-index: 2;
}

@media (min-width: 40.625em) {
  .c-header-video__content {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -ms-transform: translate(-50%, -50%);
    -ms-transform-origin: center center;
  }
}

.c-header-video__content h3 {
  max-width: 730px;
}

.c-header-video__content .hs-white {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.35);
}

.c-header-video__content .button {
  font-weight: 900;
  text-align: center;
}

.slanted-page-break {
  -webkit-backface-visibility: hidden;
  width: 100%;
  background-color: white;
  position: relative;
  will-change: content;
  padding: 0;
}

.slanted-page-break .slanted-page-break__line {
  -webkit-backface-visibility: hidden;
  content: '';
  display: block;
  width: 110%;
  height: 40px;
  transform: rotate(1.2deg);
  -ms-transform: rotate(1.2deg);
  background-color: #f28035;
  position: absolute;
  top: -40px;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.64);
  will-change: content;
}

.slanted-page-break .slanted-page-break__pattern {
  -webkit-backface-visibility: hidden;
  content: '';
  display: block;
  width: 150%;
  height: 200px;
  transform: rotate(1.2deg);
  -ms-transform: rotate(1.2deg);
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  left: -10px;
  margin: auto;
  overflow: hidden;
  will-change: content;
}

.slanted-page-break .slanted-page-break__pattern:before {
  -webkit-backface-visibility: hidden;
  content: "";
  position: absolute;
  bottom: 0;
  width: 250%;
  height: 200%;
  z-index: -1;
  background-image: url("img/bg-header.png");
  background-repeat: repeat;
  background-position: center bottom;
  transform: rotate(-0.5deg);
  will-change: content;
}

/*------------------------------------*    #MENU/DROP DWON:
\*------------------------------------*/
#mobile-menu-toggle {
  background-color: transparent;
  width: 50px;
  height: 35px;
  float: none;
  display: inline-block;
  margin-top: 17px;
}

#mobile-menu-toggle object, #mobile-menu-toggle svg {
  width: 100%;
  max-height: 100%;
  pointer-events: none;
  fill: #ffffff;
}

@media screen and (min-width: 820px) {
  #mobile-menu-toggle {
    display: none;
  }
}

.sb-toggle-left {
  margin-right: 0;
}

.sb-slide, #sb-site, .sb-site-container, .sb-slidebar {
  -webkit-transform: none;
  -moz-transform: none;
  -o-transform: none;
  transform: none;
}

#sb-site {
  overflow-x: hidden;
}

#menu li {
  margin: 0 .25em;
}

#menu li ul {
  display: none;
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  position: absolute;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#menu li:hover > ul {
  display: block;
}

#menu li > ul li {
  border-bottom: 1px solid rgba(150, 150, 150, 0.15);
  background: #3c3c3c;
  display: block;
  margin: 0;
}

#menu li > ul li:last-child {
  border-bottom: none;
}

#menu li > ul li:after {
  content: "";
}

#menu li > ul a {
  display: block;
  padding: 15px 20px;
}

#menu li > ul a:hover {
  color: #f3f3f3;
  background: #000;
}

@media screen and (max-width: 820px) {
  #mobile-menu {
    display: block;
  }
  #header #top-links,
  #header #menu {
    display: none;
  }
  #logo {
    padding: 10px;
    width: 128px;
    height: 46px;
    left: 10px;
    margin: 0;
    top: 10px;
  }
  #header nav li:first-child {
    font-size: 14px;
    margin: 0 0 5px;
    display: block;
  }
}

/*------------------------------------*    #CEROS OVERRIDES:
\*------------------------------------*/
@media screen and (min-width: 720px) {
  body.page--ceros-circular {
    height: 100%;
  }
}

.page--ceros-circular #header {
  margin-bottom: 40px;
}

.page--ceros-circular #header.background-visible:not(#foo) {
  position: relative;
  display: block;
}

@media screen and (max-width: 820px) {
  .page--ceros-circular #header.background-visible:not(#foo) {
    height: 27px;
  }
}

.page--ceros-circular #header.background-visible:not(#foo):after {
  height: 55px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  box-shadow: 0 0 8px 0 black;
}

@media screen and (min-width: 820px) {
  .page--ceros-circular #header.background-visible:not(#foo):after {
    height: 75px;
  }
}

.underline-link {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding-bottom: .25em;
}

.underline-link:after {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  height: 2px;
  background-color: #f28035;
}

.underline-link:hover:after {
  width: 100%;
  background-color: #f7ad7d;
}

.underline-link--light:hover {
  color: #ffffff;
}

.underline-link--bold {
  font-family: 'Open Sans Bold', sans-serif;
}

.category-links {
  background-color: #f28035;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  padding-bottom: 32px;
  position: relative;
  z-index: 100;
}

.category-links * {
  box-sizing: border-box;
}

@media screen and (min-width: 650px) {
  .category-links {
    padding: 2em 0;
  }
}

.category-links__heading {
  font-size: 2em;
  margin: .5em 0;
  margin-bottom: 1em;
}

.category-links__row {
  display: block;
  font-size: 0;
  max-width: 1140px;
  margin: 0 auto;
}

.category-links__row.category-links__row--graphics {
  display: none;
}

@media screen and (min-width: 650px) {
  .category-links__row.category-links__row--graphics {
    display: block;
  }
}

.category-links__link-wrapper {
  font-size: 2rem;
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  padding: 1.5rem;
  margin-top: -1em;
}

@media screen and (min-width: 650px) {
  .category-links__link-wrapper {
    width: 18%;
    font-size: 2vw;
    margin-top: -.75em;
  }
}

@media screen and (min-width: 960px) {
  .category-links__link-wrapper {
    width: 14.2857142857%;
    font-size: 2.3rem;
  }
}

.category-links__link-text {
  background-color: #000000;
  display: block;
  padding: .5em 0;
  margin-top: -.25em;
  border: 1px solid #000000;
  line-height: 1.1;
}

.category-links__link-text:hover {
  color: #f28035;
  background-color: #ffffff;
}

@media screen and (min-width: 650px) {
  .category-links__link-text {
    display: block;
    margin-top: 0;
    background-color: transparent;
    border: none;
    padding: .75em 0;
    margin-bottom: -2rem;
  }
  .category-links__link-text:hover {
    color: #000000;
    background-color: transparent;
  }
}

.category-links__link-graphic {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.08);
  font-size: 0;
  padding: 1rem;
  display: block;
}

.category-links__link-graphic:hover {
  border: 1px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.3);
}

.category-links__link-graphic object, .category-links__link-graphic svg {
  width: 100%;
  pointer-events: none;
}

.home-news {
  padding-top: 32px;
}

.home-news * {
  box-sizing: border-box;
}

.home-news .container {
  display: table;
}

@media screen and (min-width: 960px) {
  .home-news .container {
    display: block;
    padding-top: 16px;
  }
}

.home-news .home-news__column {
  position: relative;
  float: none;
  width: 100%;
  margin-left: 0;
  z-index: 1;
  display: table-footer-group;
}

@media screen and (min-width: 960px) {
  .home-news .home-news__column {
    display: block;
    float: left;
    width: 30.6666666667%;
  }
}

.home-news .home-news__column:last-child {
  display: table-header-group;
}

@media screen and (min-width: 960px) {
  .home-news .home-news__column:last-child {
    width: 65.3333333333%;
    margin-left: 4%;
  }
}

.home-news__card {
  padding: 1em 0;
  position: relative;
}

@media screen and (min-width: 960px) {
  .home-news__card {
    height: 320px;
    padding: 0;
    margin-bottom: 3em;
  }
}

.home-news__card__image {
  width: 100%;
  height: 120px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.home-news__card--first .home-news__card__image {
  background-image: url("img/landing_page_pod_1_v2_guetzli.jpg");
}

.home-news__card--second .home-news__card__image {
  background-image: url("img/landing_page_pod_2_v2_guetzli.jpg");
}

.home-news__card--featured .home-news__card__image {
  height: 240px;
}

@media screen and (min-width: 960px) {
  .home-news__card--featured .home-news__card__image {
    height: 100%;
  }
}

@media screen and (min-width: 960px) {
  .home-news__card__image {
    height: 100%;
  }
}

.home-news__card__chevron-icon {
  display: none;
  position: absolute;
  top: .45em;
  right: .9em;
}

.home-news__card__chevron-icon object {
  width: 14px;
  height: 14px;
}

@media screen and (min-width: 960px) {
  .home-news__card__chevron-icon {
    display: block;
  }
}

.home-news__card__content {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.9);
  padding: .45em;
}

@media screen and (min-width: 960px) {
  .home-news__card__content {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.75);
    width: calc(100% - 20px);
    height: 35px;
    overflow: hidden;
  }
  .home-news__card:hover .home-news__card__content {
    height: calc(100% - 20px);
  }
}

.home-news__card__content__heading {
  text-transform: uppercase;
  margin-bottom: .35em;
  color: #f28035;
  font-size: 1.75em;
}

@media screen and (min-width: 960px) {
  .home-news__card__content__heading {
    font-size: 1.3em;
  }
}

@media screen and (min-width: 1100px) {
  .home-news__card__content__heading {
    font-size: 1.5em;
  }
}

.home-news__card__content__body {
  line-height: 1.45;
  color: #ffffff;
  margin-bottom: 2em;
}

.home-news__card__content__link {
  text-transform: uppercase;
  color: #f28035;
}

.home-news__card__content__link:hover {
  color: #f7ad7d;
}

.home-news__card--featured {
  position: relative;
}

@media screen and (min-width: 960px) {
  .home-news__card--featured {
    height: 685px;
  }
}

.home-news__feature__heading {
  text-transform: uppercase;
  margin-bottom: .25em;
  font-size: 2.5em;
}

@media screen and (min-width: 640px) {
  .home-news__feature__heading {
    font-size: 3.5em;
  }
}

.home-news__feature__lsa-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -ms-transform: translate(-50%, -50%);
  -ms-transform-origin: center center;
  width: 60%;
  max-width: 200px;
  z-index: 1;
}

@media screen and (min-width: 960px) {
  .home-news__feature__lsa-logo {
    -moz-transform: none;
    -o-transform: none;
    -webkit-transform: none;
    transform: none;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -ms-transform: none;
    -ms-transform-origin: center center;
    top: auto;
    left: auto;
    right: 1em;
    bottom: 1em;
    max-width: 250px;
  }
}

.home-news__feature__list {
  margin-bottom: 2em;
  text-align: left;
}

@media screen and (min-width: 640px) {
  .home-news__feature__list {
    width: 60%;
    margin-bottom: 5em;
  }
}

.home-news__feature__list__bullet {
  position: relative;
  font-size: 1.2em;
  padding-left: 1.5em;
  line-height: 1.2;
  margin-bottom: .3em;
}

@media screen and (min-width: 640px) {
  .home-news__feature__list__bullet {
    font-size: 1.6em;
  }
}

.home-news__feature__list__bullet:before {
  content: '';
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #f28035;
  top: 3px;
  left: 6px;
}

@media screen and (min-width: 640px) {
  .home-news__feature__list__bullet:before {
    top: 6px;
  }
}

.home-news__feature__drill {
  position: absolute;
  display: none;
}

@media screen and (min-width: 640px) {
  .home-news__feature__drill {
    display: block;
    width: 45%;
    top: 13%;
    right: -10%;
  }
}

@media screen and (min-width: 760px) {
  .home-news__feature__drill {
    width: 40%;
    top: 8%;
    right: -1%;
  }
}

@media screen and (min-width: 960px) {
  .home-news__feature__drill {
    width: 55%;
    top: 11%;
    right: -15%;
  }
}

.join-team {
  width: 100%;
  background-color: #f28035;
  text-align: center;
}

.join-team * {
  box-sizing: border-box;
}

.join-team__heading,
.join-team__form,
.join-team__form__field-group,
.join-team__form__submit {
  display: block;
  position: relative;
}

@media screen and (min-width: 640px) {
  .join-team__heading,
  .join-team__form,
  .join-team__form__field-group,
  .join-team__form__submit {
    display: inline-block;
    vertical-align: bottom;
  }
}

.join-team__heading {
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: .75em;
}

.join-team__form {
  padding: 1.5em;
  margin-bottom: 1em;
}

@media (min-width: 39.9375em) {
  .join-team__form {
    padding: .5em;
  }
}

.join-team__form__label {
  text-align: left;
  font-size: .8em;
  margin-top: 0;
  margin-bottom: .6em;
}

@media screen and (min-width: 640px) {
  .join-team__form__field-group {
    margin-left: .5em;
    margin-right: .5em;
  }
}

.join-team__form__field {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  width: 100%;
  height: 51.25px;
  display: block;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.4);
  outline: none;
  box-shadow: none;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.2em;
  padding: .75em;
  line-height: 1;
  margin-bottom: 1.5em;
  cursor: pointer;
  border-radius: 0;
  -webkit-appearance: none;
}

.join-team__form__field:focus, .join-team__form__field:hover {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.6);
}

@media screen and (min-width: 640px) {
  .join-team__form__field {
    margin-bottom: 0;
    min-width: 220px;
  }
}

@media screen and (min-width: 960px) {
  .join-team__form__field {
    min-width: 300px;
  }
}

.join-team__form__field--select {
  min-width: 220px;
}

.join-team__form__field-group__caret-icon {
  position: absolute;
  bottom: .5em;
  right: 1em;
  pointer-events: none;
}

.join-team__form__field-group__caret-icon object {
  width: 15px;
  height: 20px;
}

@-moz-document url-prefix() {
  .join-team__form__field-group__caret-icon {
    display: none;
  }
}

.join-team__form__submit {
  display: inline-block;
  margin-bottom: 0;
  background-color: #ffffff;
  color: #f28035;
}

.join-team__form__submit:hover {
  background-color: #f28035;
  border-color: #ffffff;
  color: #ffffff;
}

@media screen and (min-width: 640px) {
  .join-team__form__submit {
    width: auto;
    min-width: 0;
    margin-left: .5em;
    margin-right: .5em;
  }
}

.join-team__social {
  display: inline-block;
  margin: 0;
  margin-bottom: 1.7em;
}

.join-team__social__link {
  display: inline-block;
  margin: 0;
  margin-right: .25em;
}

.join-team__social__link a {
  display: block;
  width: 100%;
  height: 100%;
}

.join-team__social__link object {
  width: 35px;
  height: 35px;
}

.join-team__social__link:last-child {
  margin-right: 0;
}

#videos {
  padding-top: 26px;
}

.video-module-featured-video {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.video-module-featured-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-module-featured-video__title {
  padding: .75em;
  background-color: rgba(0, 0, 0, 0.9);
  color: #f28035;
  font-size: 1.7em;
}

.video-module {
  border-top: 4px solid #e6e6e6;
  box-sizing: border-box;
}

.video-module * {
  box-sizing: border-box;
}

.video-module__filters {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-align: center;
  margin: 1em 0;
  height: 36px;
  overflow: hidden;
  background-color: #e6e6e6;
  position: relative;
}

.video-module__filters > span {
  line-height: 0;
  display: block;
}

@media (min-width: 60em) {
  .video-module__filters > span {
    margin-top: 10px;
  }
}

@media (max-width: 59.99em) {
  .video-module__filters.is-active {
    height: 216px;
  }
}

@media (min-width: 60em) {
  .video-module__filters {
    height: auto;
    overflow: visible;
    background-color: transparent;
  }
}

.video-module__filters__chevron-icon {
  position: absolute;
  top: 8px;
  right: 10px;
}

.video-module__filters__chevron-icon object {
  width: 15px;
  height: 15px;
}

@media (min-width: 60em) {
  .video-module__filters__chevron-icon {
    display: none;
  }
}

.video-module__filters__filter {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  padding: .5em 0;
}

@media (min-width: 60em) {
  .video-module__filters__filter {
    display: inline-block;
    margin: 0 1em;
    padding: 0;
    padding-bottom: .1em;
    border-bottom: 2px solid transparent;
    position: relative;
    transform: none !important;
  }
  .video-module__filters__filter.mixitup-control-active {
    border-bottom: 2px solid #f28035;
  }
  .video-module__filters__filter:last-child:before {
    display: none;
  }
  .video-module__filters__filter:before {
    content: '';
    display: block;
    width: 2px;
    height: 100%;
    position: absolute;
    top: -.1em;
    right: -1.1em;
    background-color: #aaaaaa;
  }
}

.video-module__filters__filter:hover {
  color: #f28035;
}

.video-module__filters__filter.mixitup-control-active {
  color: #f28035;
}

.video-module__videos__video-wrapper {
  width: 100%;
  margin: 1em 0;
  position: relative;
  border-bottom: 6px solid #e6e6e6;
  overflow: hidden;
  display: inline-block;
  background-size: cover;
  background-position: center -18px;
  background-repeat: no-repeat;
}

@media (min-width: 40.625em) {
  .video-module__videos__video-wrapper {
    background-position: center -21px;
  }
}

.video-module__videos__video-wrapper:after {
  content: '';
  display: block;
  padding-top: 80%;
}

.video-module__videos__video-title {
  font-size: 1.2em;
  background-color: #f1f1f1;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 1em;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 40.625em) {
  .video-module__videos__video-title {
    font-size: 1.4em;
  }
}

.video-module__videos__video-timestamp {
  position: absolute;
  bottom: 56px;
  right: 5px;
  background-color: #000000;
  color: #ffffff;
  padding: .25em .5em;
}

.video-module__filters__youtube-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -ms-transform: translate(-50%, -50%);
  -ms-transform-origin: center center;
  top: 43%;
}

.video-module__filters__youtube-icon object {
  width: 50px;
  height: 50px;
}

.teamridgid-header {
  margin-top: -20px;
  background-image: url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image: -webkit-linear-gradient(transparent 0%, black 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image: -moz-linear-gradient(transparent 0%, black 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image: -o-linear-gradient(transparent 0%, black 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image: -ms-linear-gradient(transparent 0%, black 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image: linear-gradient(transparent 0%, black 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-size: cover;
  -moz-background-size: cover;
  /* Firefox 3.6 */
  background-position: center;
  /* Internet Explorer 7/8 */
}

@media (min-width: 40.625em) {
  .teamridgid-header {
    padding-top: 200px;
  }
}

@media (min-width: 60em) {
  .teamridgid-header {
    padding-top: 260px;
  }
}

.teamridgid-header * {
  -webkit-font-smoothing: antialiased;
}

.teamridgid-header button {
  font-size: 1.3em;
}

.teamridgid-header h1,
.teamridgid-header h2,
.teamridgid-header h3,
.teamridgid-header h4,
.teamridgid-header h5,
.teamridgid-header h6 {
  font-family: 'Open Sans Bold', sans-serif;
  margin-bottom: 0;
}

.teamridgid-header p {
  color: #fff;
  margin-top: 1em;
  margin-bottom: 2em;
}

@media (min-width: 40.625em) {
  .teamridgid-header p {
    margin-top: 2.5em;
    margin-bottom: 2.7em;
  }
}

@media (min-width: 60em) {
  .teamridgid-header p {
    width: 65%;
  }
}

.teamridgid-header h1 {
  line-height: 0.8;
  font-size: 3.5em;
  color: #f28035;
  margin-top: 4px;
}

@media (min-width: 40.625em) {
  .teamridgid-header h1 {
    font-size: 5.5em;
  }
}

.teamridgid-header h2 {
  color: white;
  padding: 20px 0;
  font-size: 2em;
}

@media (min-width: 40.625em) {
  .teamridgid-header h2 {
    font-size: 3.2em;
    margin-bottom: .35em;
  }
}

.teamridgid-header .container {
  padding: 110px 0 45px;
  padding-top: 6em;
  padding-bottom: 1.2em;
  padding-left: .75em;
  padding-right: .75em;
}

@media (min-width: 25em) {
  .teamridgid-header .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 40.625em) {
  .teamridgid-header .container {
    padding-bottom: 45px;
  }
}

.teamridgid-header span {
  background-color: #f28035;
  font-weight: bold;
  padding: 0 5px;
  color: #000;
  font-size: 1em;
}

@media (min-width: 40.625em) {
  .teamridgid-header span {
    font-size: 1.4em;
  }
}

.teamridgid-header button {
  font-family: 'Open Sans Bold', sans-serif;
  background: none;
  display: block;
  margin: 0 auto;
  color: white;
  outline: none;
  border: 0;
}

.teamridgid-header button:hover {
  color: #f28035;
}

.teamridgid-video__chevron-icon {
  text-align: center;
  cursor: pointer;
}

.teamridgid-video__chevron-icon object {
  width: 40px;
  height: 40px;
}

.video-wrapper {
  position: relative;
  cursor: pointer;
}

.teamridgid-video__modal-trigger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-sizing: border-box;
  z-index: 10;
}

.teamridgid-video__youtube-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -ms-transform: translate(-50%, -50%);
  -ms-transform-origin: center center;
  text-align: center;
  pointer-events: none;
}

.teamridgid-video__youtube-icon object {
  opacity: .85;
  width: 50%;
  height: 50%;
  max-width: 200px;
  pointer-events: none;
}

.teamridgid-video__mobile {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}

@media (min-width: 40.625em) {
  .teamridgid-video__mobile {
    display: none;
  }
}

.teamridgid-video__mobile iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  visibility: hidden;
}

.teamridgid-video__desktop {
  display: none;
}

@media (min-width: 40.625em) {
  .teamridgid-video__desktop {
    display: block;
  }
}

.teamridgid-video__desktop iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ridgid-line-container {
  overflow: hidden;
  width: 100%;
}

.ridgid-line {
  height: 0;
  width: 100%;
  margin: -10px 0 50px -1800px;
  border-top: 80px solid #f28035;
  border-left: 4000px solid transparent;
  -webkit-filter: drop-shadow(1px 1px 28px rgba(0, 0, 0, 0.6));
  -moz-filter: drop-shadow(1px 1px 28px rgba(0, 0, 0, 0.6));
  -ms-filter: drop-shadow(1px 1px 28px rgba(0, 0, 0, 0.6));
  -o-filter: drop-shadow(1px 1px 28px rgba(0, 0, 0, 0.6));
  filter: drop-shadow(1px 1px 28px rgba(0, 0, 0, 0.6));
}

.ridgid-grad {
  width: 100%;
  height: 110px;
  margin-top: -100px;
  margin-bottom: -50px;
  background-image: url("img/_sons_of_sawdust/grad-bg.jpg");
}

.button-ct:hover {
  background: #000;
}

.teamridgid-video__modal {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  width: 95%;
  max-width: 900px;
  min-height: 0 !important;
}

.modal {
  min-height: 0 !important;
}

.teamridgid-video__modal__video-wrapper {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}

.teamridgid-video__modal__video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.teamridgid__story {
  margin-bottom: 60px;
}

@media (min-width: 40.625em) {
  .teamridgid__story {
    margin-top: 50px;
    margin-bottom: 70px;
  }
}

@media (min-width: 40.625em) {
  .teamridgid__story__section-left {
    float: left;
    width: calc(100% - 300px);
  }
}

.teamridgid__story__section-right a {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

@media (min-width: 40.625em) {
  .teamridgid__story__section-right a {
    width: auto;
  }
}

@media (min-width: 40.625em) {
  .teamridgid__story__section-right {
    float: right;
    text-align: center;
    width: 300px;
  }
}

.teamridgid__story-header {
  margin-bottom: .5em;
}

.remodal[data-remodal-id='form-modal'] {
  padding: 0;
}

.hr--thick {
  border-top-width: 5px;
}

.hr--brand {
  border-top-color: #f28035;
}

.c-talk-shop .button {
  font-size: 18px;
}

.c-talk-shop * {
  box-sizing: border-box;
}

.c-talk-shop__section-wrapper {
  padding: 20px;
  text-align: center;
}

.c-talk-shop__section {
  max-width: 1140px;
  display: inline-block;
}

.c-talk-shop__subscribe-link {
  margin-bottom: .7em;
}

.c-talk-shop__section-wrapper--header {
  background-image: url("../img/talk-shop/repeating_bricks.jpg");
  background-position: center center;
  padding: 20px;
  text-align: center;
  margin-top: -50px;
  padding-top: 60px;
}

.c-talk-shop__section-wrapper--header .c-talk-shop__section {
  margin-top: -50px;
  padding-top: 60px;
  max-width: 960px;
}

@media (min-width: 51.25em) {
  .c-talk-shop__section-wrapper--header .c-talk-shop__section {
    padding-top: 180px;
  }
}

@media (min-width: 60em) {
  .c-talk-shop__section-wrapper--header .c-talk-shop__section {
    padding-top: 200px;
  }
}

.c-talk-shop__header__logo {
  width: 70%;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
  display: block;
}

@media (min-width: 60em) {
  .c-talk-shop__header__logo {
    width: 100%;
    max-width: 100%;
  }
}

.c-talk-shop__header__text {
  text-align: center;
  font-size: 3.3em;
  margin-bottom: 30px;
}

@media (min-width: 40.625em) {
  .c-talk-shop__header__text {
    font-size: 4vw;
  }
}

@media (min-width: 60em) {
  .c-talk-shop__header__text {
    text-align: left;
    font-size: 4rem;
  }
}

@media (min-width: 71.25em) {
  .c-talk-shop__header__text {
    font-size: 4.6rem;
  }
}

@media (min-width: 60em) {
  .c-talk-shop__header__button-wrapper {
    text-align: left;
  }
}

.c-talk-shop__header__button {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media (min-width: 40.625em) {
  .c-talk-shop__header__button {
    max-width: 300px;
  }
}

.c-talk-shop__section-wrapper--media-banner {
  background-color: #222222;
  padding: 0;
  margin: 0;
  font-size: 0;
  overflow: hidden;
}

.c-talk-shop__section-wrapper--media-banner.c-talk-shop__section-wrapper--media-banner--detail-page {
  padding-top: 80px;
  margin-top: -120px;
}

@media (min-width: 40.625em) {
  .c-talk-shop__section-wrapper--media-banner.c-talk-shop__section-wrapper--media-banner--detail-page {
    margin-top: -60px;
    padding-top: 0;
  }
}

@media (min-width: 60em) {
  .c-talk-shop__section-wrapper--media-banner.c-talk-shop__section-wrapper--media-banner--detail-page {
    margin-top: 0;
  }
}

.c-talk-shop__section-wrapper--media-banner.c-talk-shop__section-wrapper--media-banner--detail-page .c-talk-shop__section {
  padding-top: 80px;
}

@media (min-width: 60em) {
  .c-talk-shop__section-wrapper--media-banner.c-talk-shop__section-wrapper--media-banner--detail-page {
    padding-top: 0;
  }
}

.c-talk-shop__section-wrapper--media-banner .c-talk-shop__section {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  position: relative;
  font-size: 1rem;
  height: auto;
  padding: 20px;
  box-shadow: 0 0 200px 50px #000000;
  max-width: 1360px;
}

@media (min-width: 40.625em) {
  .c-talk-shop__section-wrapper--media-banner .c-talk-shop__section {
    width: 100%;
  }
}

@media (min-width: 60em) {
  .c-talk-shop__section-wrapper--media-banner .c-talk-shop__section {
    padding: 0;
    height: 545px;
  }
}

.c-talk-shop__media-banner__inner {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1140px;
  position: relative;
}

.c-talk-shop__media-banner__info {
  margin-top: 100px;
  position: relative;
  display: inline-block;
  max-width: 580px;
}

@media (min-width: 40.625em) {
  .c-talk-shop__media-banner__info {
    margin-top: 200px;
  }
}

@media (min-width: 60em) {
  .c-talk-shop__media-banner__info {
    position: absolute;
    bottom: 50px;
    left: 50px;
  }
}

.c-talk-shop__media-banner__info__heading, .c-talk-shop__media-banner__info__subheading {
  position: relative;
  margin: 0;
  line-height: 1;
  display: inline-block;
  color: #f28035;
  text-align: left;
}

.c-talk-shop__media-banner__info__heading {
  padding: 10px 8px 12px 6px;
  background-color: rgba(0, 0, 0, 0.64);
}

.c-talk-shop__media-banner__info__heading:before, .c-talk-shop__media-banner__info__heading:after {
  content: '';
  display: block;
  position: absolute;
  width: calc(100% - 14px);
  height: 3px;
  background-color: #f28035;
  top: 8px;
  left: 1px;
  right: 0;
  margin: auto;
}

.c-talk-shop__media-banner__info__heading:after {
  top: auto;
  bottom: 8px;
}

@media (min-width: 60em) {
  .c-talk-shop__media-banner__info__heading {
    font-size: 5em;
  }
}

.c-talk-shop__media-banner__info__subheading {
  padding: 1px 6px 1px 6px;
  top: 5px;
  z-index: 1;
  letter-spacing: 1px;
}

.c-talk-shop__media-banner__info__subheading:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -5px;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.64);
}

.c-talk-shop__media-banner__info__paragraph {
  color: #e6e6e6;
  margin-top: 20px;
  padding: 6px;
  font-size: 1.3rem;
  margin-top: 10px;
  padding: 6px;
  line-height: 2;
}

.c-talk-shop__media-banner__info__paragraph span {
  background-color: rgba(0, 0, 0, 0.64);
  box-shadow: 6px 0 0 rgba(0, 0, 0, 0.64), -6px 0 0 rgba(0, 0, 0, 0.64);
  padding: 4px 0;
}

.c-talk-shop__media-banner__buttons {
  position: relative;
  margin-bottom: 20px;
}

.c-talk-shop__media-banner__buttons .c-talk-shop__media-banner__button {
  margin-bottom: .5em;
}

.c-talk-shop__media-banner__buttons .c-talk-shop__media-banner__button:last-child {
  margin-bottom: 0;
}

.c-talk-shop__media-banner__buttons .c-talk-shop__media-banner__button:hover .o-icon {
  fill: #f28035;
}

@media (min-width: 60em) {
  .c-talk-shop__media-banner__buttons {
    margin-bottom: 0;
    position: absolute;
    bottom: 80px;
    right: 50px;
  }
  .c-talk-shop__media-banner__buttons .c-talk-shop__media-banner__button {
    width: 100%;
  }
}

.c-talk-shop__section-wrapper--expandable {
  padding: 20px 0;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.c-talk-shop__section-wrapper--expandable .c-talk-shop__section {
  width: 100%;
  padding: .5em;
}

@media (min-width: 40.625em) {
  .c-talk-shop__section-wrapper--expandable .c-talk-shop__section {
    padding: 1em;
  }
}

.c-talk-shop__expandable {
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  border: 1px solid #f28035;
  border-top: 10px solid #f28035;
  padding: 1em;
  max-height: 91px;
  overflow: hidden;
}

.c-talk-shop__expandable .c-talk-shop__video-grid {
  margin-top: 2em;
}

.c-talk-shop__expandable .c-talk-shop__video-grid__item {
  margin-bottom: 2em;
}

@media (min-width: 40.625em) {
  .c-talk-shop__expandable {
    max-height: 96px;
  }
}

@media (min-width: 60em) {
  .c-talk-shop__expandable {
    max-height: 111px;
    padding: 1.5em 3em;
  }
}

.c-talk-shop__expandable__icon, .c-talk-shop__expandable__heading {
  text-align: left;
  color: #f28035;
}

.c-talk-shop__expandable__heading {
  font-size: 2.5em;
}

.c-talk-shop__expandable__icon {
  width: 100%;
  height: 50px;
  display: none;
  position: relative;
}

.c-talk-shop__expandable__icon .o-icon {
  width: 120px;
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  fill: #f28035;
}

@media (min-width: 40.625em) {
  .c-talk-shop__expandable__icon {
    display: inline-block;
  }
}

.c-talk-shop__expandable__action-text, .c-talk-shop__expandable__action-icon {
  display: inline-block;
  vertical-align: middle;
}

.c-talk-shop__expandable__action-text {
  text-align: right;
  color: #f28035;
  padding-right: 10px;
  display: none;
}

@media (min-width: 40.625em) {
  .c-talk-shop__expandable__action-text {
    display: inline-block;
  }
}

.c-talk-shop__expandable__action-icon {
  margin-left: auto;
  display: block;
}

.c-talk-shop__expandable.is-expanded .c-talk-shop__expandable__action-icon .o-icon {
  transform: rotate(180deg);
}

@media (min-width: 40.625em) {
  .c-talk-shop__expandable__action-icon {
    display: inline-block;
  }
}

.c-talk-shop__section-wrapper--subscribe-box {
  padding: 20px 0;
  padding-top: 10px;
  padding-bottom: 60px;
  background-color: #f1f1f1;
  background-image: url("../img/talk-shop/repeating_hex.png");
  background-position: center center;
  background-size: 24px;
  position: relative;
}

.c-talk-shop__section-wrapper--subscribe-box:before {
  content: '';
  display: block;
  width: 100%;
  max-width: 1300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 30%, white 70%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 30%, white 70%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 30%, white 70%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
}

.c-talk-shop__section-wrapper--subscribe-box .c-talk-shop__section {
  width: 100%;
  padding: .5em;
}

@media (min-width: 40.625em) {
  .c-talk-shop__section-wrapper--subscribe-box .c-talk-shop__section {
    padding: 1em;
  }
}

.c-talk-shop__subscribe-box {
  margin-top: 1em;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.64);
  box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}

.c-talk-shop__subscribe-box .c-talk-shop__video-grid {
  margin-top: 2em;
}

@media (min-width: 60em) {
  .c-talk-shop__subscribe-box {
    padding: 1.5em 3em;
  }
}

.c-talk-shop__subscribe-box__icon, .c-talk-shop__subscribe-box__heading {
  text-align: left;
  color: #f28035;
}

.c-talk-shop__subscribe-box__heading {
  font-size: 2.5em;
  margin-bottom: .5em;
}

.c-talk-shop__subscribe-box__paragraph {
  line-height: 1.2;
  font-size: 1.5em;
}

.c-talk-shop__subscribe-box__icon {
  width: 100%;
  height: 50px;
  display: none;
  position: relative;
}

.c-talk-shop__subscribe-box__icon .o-icon {
  width: 80px;
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  fill: #f28035;
}

@media (min-width: 40.625em) {
  .c-talk-shop__subscribe-box__icon {
    display: inline-block;
  }
}

.c-talk-shop__subscribe-box__action {
  font-size: 1.7em;
  text-align: center;
}

.c-talk-shop__video {
  text-align: left;
  margin: 1.5em 0;
}

.c-talk-shop__video__image {
  width: 100%;
  padding-top: 56.25%;
  height: 0;
  background-position: center center;
  background-size: cover;
  position: relative;
}

.c-talk-shop__video__action {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.c-talk-shop__video__text {
  font-size: 1.5em;
  margin: 0;
  margin-top: .5em;
  line-height: 1.25;
}

.c-talk-shop__section-wrapper--details {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.c-talk-shop__section-wrapper--details .c-talk-shop__section {
  width: 100%;
}

.c-talk-shop__details__podbean {
  margin: 2em 0;
  margin-bottom: .5em;
  padding: 20px;
  background-color: rgba(242, 128, 53, 0.3);
  border-bottom: 5px solid #f28035;
  border-radius: 4px;
}

.c-talk-shop__details__guest-image {
  margin-bottom: 2em;
}

p.c-footer {
  margin: 3rem 0 1rem 0;
  color: #444444;
  font-family: 'Open Sans Condensed', sans-serif;
  text-transform: none;
}

.c-panorama__title {
  margin: 1rem 0;
}

.c-mixed {
  text-transform: none;
  margin: 1rem 0 3rem 0;
}

.c-panorama-wrapper {
  height: 375px;
  position: relative;
}

.c-panorama-wrapper:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

.c-panorama-wrapper > .c-panorama {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media screen and (min-width: 768px) {
  .c-panorama-wrapper {
    height: auto;
  }
}

.c-panorama-wrapper--mobile {
  min-height: 1000px;
}

.c-panorama-wrapper--mobile .pnlm-hotspot {
  height: 75px;
  width: 75px;
  border-radius: 40px;
  background-size: cover;
}

.c-panorama-wrapper--mobile .pnlm-hotspot.pnlm-info {
  background-position: 0 -300px;
}

.c-panorama-wrapper--mobile .c-overlay.is-visible {
  width: 70%;
}

.c-panorama-wrapper--mobile .c-overlay__info__title {
  font-size: 2em;
}

.c-panorama-wrapper--mobile .c-overlay__info__description {
  font-size: 2em;
}

.c-panorama {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: 1rem auto .5rem auto;
  width: 100%;
  position: relative;
  margin-top: 0;
}

.c-panorama.is-fullwindow {
  width: 100%;
  height: 100%;
  transform: none;
}

.c-panorama__img {
  z-index: 10;
  position: absolute;
}

.c-panorama__img img {
  display: block;
  max-width: 100vw;
  width: calc(100vw * .35);
  position: absolute;
  margin-left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: 50%;
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .c-panorama__img img {
    display: none;
  }
}

@media screen and (min-width: 600px) {
  .c-panorama__img img {
    width: calc(100vw * .2);
  }
}

@media screen and (min-width: 1000px) {
  .c-panorama__img img {
    width: calc(1140px * .17);
  }
}

.c-panorama__img img.buy-kit {
  width: calc(100vw * .6);
}

@media screen and (min-width: 600px) {
  .c-panorama__img img.buy-kit {
    width: calc(100vw * .5);
  }
}

@media screen and (min-width: 1000px) {
  .c-panorama__img img.buy-kit {
    width: calc(1140px * .45);
  }
}

.c-panorama__img img.buy-kit-pc {
  width: calc(100vw * .6);
}

@media screen and (min-width: 600px) {
  .c-panorama__img img.buy-kit-pc {
    width: calc(100vw * .25);
  }
}

@media screen and (min-width: 1000px) {
  .c-panorama__img img.buy-kit-pc {
    width: calc(1140px * .23);
  }
}

.c-panorama__img img.get-one {
  width: calc(100vw * .35);
}

@media screen and (min-width: 600px) {
  .c-panorama__img img.get-one {
    width: calc(100vw * .3);
  }
}

@media screen and (min-width: 1000px) {
  .c-panorama__img img.get-one {
    width: calc(1140px * .23);
  }
}

.c-panorama__img img.buy-this {
  width: calc(100vw * .35);
}

@media screen and (min-width: 600px) {
  .c-panorama__img img.buy-this {
    width: calc(100vw * .2);
  }
}

@media screen and (min-width: 1000px) {
  .c-panorama__img img.buy-this {
    width: calc(1140px * .17);
  }
}

.c-panorama__panorama {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.c-panorama.is-fullwindow .c-panorama__panorama {
  width: 100%;
  height: 100%;
}

.c-panorama__control--fullwindow-toggle {
  -webkit-transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  /* easeOutQuart */
  display: none;
  width: 33px;
  height: 33px;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffffff;
  cursor: pointer;
}

.c-panorama__control--fullwindow-toggle.is-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.c-overlay {
  -webkit-transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
  /* easeOutQuart */
  -ms-overflow-style: none;
  overflow: auto;
  width: 300px;
  position: absolute;
  top: 0;
  right: -300px;
  z-index: 10;
  max-width: 90%;
  padding: 10px 0;
}

.c-overlay.is-visible {
  right: 0;
}

.c-overlay p {
  margin: 2rem 0 1rem 0;
}

.c-overlay__image-wrapper {
  padding: 15px;
  text-align: center;
}

.c-panorama__overlay-container {
  background-color: #fff;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) .c-panorama__overlay-container {
    display: flex;
    height: auto;
  }
  .c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) .c-panorama__overlay-container.is-visible {
    width: 100%;
  }
  .c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) .c-panorama__overlay-container .c-overlay__close {
    width: 50px;
    height: 30px;
    padding-top: 5px;
  }
  .c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) .c-panorama__overlay-container .c-overlay__info {
    padding: 0 10px 10px;
  }
  .c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) .c-panorama__overlay-container .c-mixed {
    font-size: 1em;
    margin: 1rem 0;
  }
  .c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) .c-panorama__overlay-container .c-overlay__image-wrapper {
    padding: 5px;
  }
  .c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) .c-panorama__overlay-container .button {
    padding: 6px 10px;
  }
  .c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) .c-panorama__overlay-container .c-overlay__image-wrapper {
    background-color: #fff;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) .c-panorama__overlay-container .c-overlay__info__link {
    margin-top: 1em;
    font-size: 0.75em;
  }
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container {
  display: flex;
  height: auto;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container.is-visible {
  width: 100%;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container .c-overlay__close {
  width: 100px;
  height: 60px;
  padding-top: 5px;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container .c-overlay__info {
  padding: 0 10px 10px;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container .c-mixed {
  font-size: 3em;
  margin: 1rem 0;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container .c-overlay__image-wrapper {
  padding: 5px;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container .button {
  padding: 6px 10px;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container .c-overlay__image-wrapper {
  background-color: #fff;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container .c-overlay__info__description.is-nlp {
  padding-left: 100px;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container .c-overlay__info__description.is-nlp:before {
  width: 84px;
  height: 84px;
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) .c-panorama__overlay-container .c-overlay__info__link {
  margin-top: 1em;
  font-size: 2.25em;
}

.c-overlay__info__link {
  display: block;
  text-align: center;
  margin-top: 4rem;
}

.c-overlay__close {
  width: 30px;
  height: 30px;
  float: right;
  fill: #fc1526;
  cursor: pointer;
}

.c-overlay__image {
  width: auto;
  max-height: 150px;
  text-align: center;
}

@media screen and (min-width: 1000px) {
  .c-overlay__image {
    max-height: 200px;
  }
  .c-panorama-wrapper--mobile .c-overlay__image {
    max-height: 300px;
  }
}

.c-overlay__info {
  padding: 10px 20px 20px 20px;
  background-color: #fff;
}

.c-overlay__info__title {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 5px;
  position: relative;
}

.c-overlay__info__title.is-new {
  padding-left: 0;
  min-height: 40px;
  display: flex;
  flex-direction: column;
}

.c-overlay__info__title.is-new:before {
  content: "";
  background-image: url(../img/360/new.svg);
  background-size: contain;
  background-position: center;
  display: block;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  width: 70px;
  height: 30px;
}

@media screen and (max-width: 768px) {
  .c-overlay__info__title.is-new:before {
    height: 30px;
  }
}

.c-overlay__info__title.is-new ~ .c-overlay__info__link {
  display: none;
}

.c-overlay__info__title.is-new ~ .c-overlay__info__description:after {
  content: "";
  background-image: url(../img/360/comingsoon.svg);
  background-size: contain;
  background-position: center;
  display: block;
  left: 0;
  top: 0;
  background-repeat: no-repeat;
  width: 95%;
  margin: 0 auto;
  text-align: center;
  height: 30px;
  margin-left: 0;
}

@media screen and (min-width: 768px) {
  .c-overlay__info__title.is-new ~ .c-overlay__info__description:after {
    margin-top: 40px;
  }
  .c-panorama-wrapper--mobile .c-overlay__info__title.is-new ~ .c-overlay__info__description:after {
    margin-top: 0;
  }
}

@media screen and (min-width: 1000px) {
  .c-overlay__info__title.is-new ~ .c-overlay__info__description:after {
    margin-top: 50px;
  }
  .c-panorama-wrapper--mobile .c-overlay__info__title.is-new ~ .c-overlay__info__description:after {
    margin-top: 0;
  }
}

.c-overlay__info__description {
  font-size: 1.5em;
  font-weight: normal;
  position: relative;
}

.c-overlay__info__description.is-nlp {
  padding-left: 50px;
}

.c-overlay__info__description.is-nlp:before {
  content: "";
  background-image: url(../img/360/NLP.svg);
  background-size: contain;
  background-position: center;
  width: 42px;
  height: 42px;
  display: block;
  position: absolute;
  left: 0;
  top: 1px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .c-overlay__info__description.is-nlp:before {
    height: 30px;
  }
}

.c-overlay__info__description.is-sb {
  padding-left: 50px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.c-overlay__info__description.is-sb:before {
  content: "";
  background-image: url(../img/360/special-buy.svg);
  background-size: contain;
  background-position: center;
  width: 42px;
  height: 42px;
  display: block;
  position: absolute;
  left: 0;
  top: 1px;
  background-repeat: no-repeat;
}

@media screen and (max-width: 768px) {
  .c-overlay__info__description.is-sb:before {
    height: 30px;
  }
}

.tuff-shed {
  display: inline-block;
  width: 150px;
  height: auto;
  margin-top: -5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .tuff-shed {
    margin-top: -9px;
  }
  .c-panorama-wrapper--mobile .tuff-shed {
    margin-top: -5px;
  }
}

.c-overlay__image--mobile {
  display: none;
}

.bogo-on .c-overlay, .bogoTool-on .c-overlay {
  width: 90%;
  overflow: visible;
  padding-bottom: 0;
}

@media screen and (min-width: 375px) {
  .bogo-on .c-overlay, .bogoTool-on .c-overlay {
    width: 75%;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay {
    width: 90%;
  }
}

@media screen and (min-width: 500px) {
  .bogo-on .c-overlay, .bogoTool-on .c-overlay {
    width: 60%;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay {
    width: 90%;
  }
}

@media screen and (min-width: 580px) {
  .bogo-on .c-overlay, .bogoTool-on .c-overlay {
    width: 50%;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay {
    width: 90%;
  }
}

@media screen and (min-width: 768px) {
  .bogo-on .c-overlay, .bogoTool-on .c-overlay {
    width: 300px;
    overflow: scroll;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay {
    width: 90%;
    overflow: visible;
  }
}

.bogo-on .c-overlay.is-visible, .bogoTool-on .c-overlay.is-visible {
  right: 5%;
}

@media screen and (min-width: 375px) {
  .bogo-on .c-overlay.is-visible, .bogoTool-on .c-overlay.is-visible {
    right: 15%;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay.is-visible, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay.is-visible {
    right: 5%;
  }
}

@media screen and (min-width: 500px) {
  .bogo-on .c-overlay.is-visible, .bogoTool-on .c-overlay.is-visible {
    right: 20%;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay.is-visible, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay.is-visible {
    right: 5%;
  }
}

@media screen and (min-width: 580px) {
  .bogo-on .c-overlay.is-visible, .bogoTool-on .c-overlay.is-visible {
    right: 25%;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay.is-visible, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay.is-visible {
    right: 5%;
  }
}

@media screen and (min-width: 768px) {
  .bogo-on .c-overlay.is-visible, .bogoTool-on .c-overlay.is-visible {
    right: 0;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay.is-visible, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay.is-visible {
    right: 5%;
  }
}

.bogo-on .c-panorama__overlay-container, .bogoTool-on .c-panorama__overlay-container {
  box-shadow: 0px 4px 15px 1px #333;
}

.bogo-on .c-overlay__info__title, .bogo-on .c-overlay__info__description, .bogoTool-on .c-overlay__info__title, .bogoTool-on .c-overlay__info__description {
  display: none;
}

@media screen and (min-width: 768px) {
  .bogo-on .c-overlay__info__title, .bogo-on .c-overlay__info__description, .bogoTool-on .c-overlay__info__title, .bogoTool-on .c-overlay__info__description {
    display: block;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay__info__title, .c-panorama-wrapper--mobile .bogo-on .c-overlay__info__description, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__info__title, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__info__description {
    display: none;
  }
}

.bogo-on .c-overlay__image, .bogoTool-on .c-overlay__image {
  display: none;
}

@media screen and (min-width: 768px) {
  .bogo-on .c-overlay__image, .bogoTool-on .c-overlay__image {
    display: block;
    margin: 0 auto;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay__image, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__image {
    display: none;
  }
}

.bogo-on .c-overlay__image--mobile, .bogoTool-on .c-overlay__image--mobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .bogo-on .c-overlay__image--mobile, .bogoTool-on .c-overlay__image--mobile {
    display: none;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay__image--mobile, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__image--mobile {
    display: block;
  }
}

.bogo-on .c-overlay__info, .bogoTool-on .c-overlay__info {
  padding: 0;
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .bogo-on .c-overlay__info, .bogoTool-on .c-overlay__info {
    bottom: 0;
    text-align: left;
    position: relative;
    padding: 10px 20px 20px 20px;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay__info, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__info {
    bottom: -25px;
    text-align: center;
    position: absolute;
    padding: 0;
  }
}

.bogo-on .c-overlay__info__link, .bogoTool-on .c-overlay__info__link {
  margin-top: 0;
  display: inline-block;
  position: relative;
  top: 15px;
  box-shadow: 0px 4px 15px 1px #333;
}

@media screen and (min-width: 768px) {
  .bogo-on .c-overlay__info__link, .bogoTool-on .c-overlay__info__link {
    display: block;
    top: 0;
    box-shadow: none;
    margin-top: 4rem;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay__info__link, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__info__link {
    display: inline-block;
    top: 15px;
    box-shadow: 0px 4px 15px 1px #333;
    margin-top: 0;
  }
}

.bogo-on .c-overlay__image--mobile, .bogoTool-on .c-overlay__image--mobile {
  display: block;
  margin: 0 auto;
}

@media screen and (min-width: 580px) {
  .bogo-on .c-overlay__image--mobile, .bogoTool-on .c-overlay__image--mobile {
    max-width: 220px;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay__image--mobile, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__image--mobile {
    max-width: auto;
  }
}

@media screen and (min-width: 768px) {
  .bogo-on .c-overlay__image--mobile, .bogoTool-on .c-overlay__image--mobile {
    max-width: 180px;
    display: none;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay__image--mobile, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__image--mobile {
    max-width: auto;
    display: block;
  }
}

@media screen and (min-width: 960px) {
  .bogo-on .c-overlay__image--mobile, .bogoTool-on .c-overlay__image--mobile {
    max-width: 270px;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay__image--mobile, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__image--mobile {
    max-width: auto;
    display: block;
  }
}

@media screen and (min-width: 1100px) {
  .bogo-on .c-overlay__image--mobile, .bogoTool-on .c-overlay__image--mobile {
    max-width: 300px;
  }
  .c-panorama-wrapper--mobile .bogo-on .c-overlay__image--mobile, .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__image--mobile {
    max-width: auto;
    display: block;
  }
}

.bogo-on .c-panorama__star, .bogoTool-on .c-panorama__star {
  visibility: visible;
}

.bogo-on .c-panorama__star-inner-tool, .bogoTool-on .c-panorama__star-inner-tool {
  display: none;
}

.bogo-on.for-5pc .img-5pc, .bogoTool-on.for-5pc .img-5pc {
  visibility: visible !important;
}

.bogo-on.for-2pc .img-2pc, .bogoTool-on.for-2pc .img-2pc {
  visibility: visible !important;
}

.bogoTool-on .c-overlay__image {
  display: none;
}

@media screen and (min-width: 768px) {
  .bogoTool-on .c-overlay__image {
    display: block;
    margin: 0 auto;
  }
  .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__image {
    display: none;
  }
}

.bogoTool-on .c-overlay__image--mobile {
  display: block;
}

@media screen and (min-width: 768px) {
  .bogoTool-on .c-overlay__image--mobile {
    display: none;
  }
  .c-panorama-wrapper--mobile .bogoTool-on .c-overlay__image--mobile {
    display: block;
  }
}

.bogoTool-on .c-panorama__star {
  visibility: visible !important;
}

.bogoTool-on .c-panorama__star-inner {
  display: none;
  visibility: hidden !important;
}

.bogoTool-on .c-panorama__star-inner-tool {
  display: none;
  visibility: hidden !important;
}

@media screen and (min-width: 768px) {
  .bogoTool-on .c-panorama__star-inner-tool {
    display: block;
    visibility: visible !important;
  }
}

.bogoTool-on.for-5pc .img-5pc {
  visibility: visible !important;
}

.bogoTool-on.for-2pc .img-2pc {
  visibility: visible !important;
}

.bogoTool-on .img-x4 {
  visibility: visible !important;
}

@media screen and (max-width: 768px) {
  .bogo-on .c-panorama__star, .bogoTool-on .c-panorama__star {
    visibility: hidden !important;
  }
}

.bogo-on .c-panorama__star .c-panorama__star-inner-tool, .bogoTool-on .c-panorama__star .c-panorama__star-inner-tool {
  display: block;
}

.c-panorama__star {
  color: #f28035;
  padding-left: 5px;
  visibility: hidden;
  font-size: 3em;
  z-index: 0;
}

.c-panorama__star-inner {
  color: #f28035;
  position: absolute;
  z-index: -5;
  top: -19px;
  left: -7px;
}

.c-panorama__star-inner-tool {
  color: #f28035;
  display: none;
  position: absolute;
  z-index: -5;
  top: -19px;
  left: -7px;
}

.img-5pc, .img-2pc, .img-x4 {
  visibility: hidden !important;
}

.pnlm-graphic {
  background-image: none;
}

.pnlm-graphic:hover {
  background: none;
}

.strikethrough {
  text-decoration: line-through;
}
