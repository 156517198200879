.load-more-container {
  position:relative;
  z-index:10;
  background:#000;
  text-align: center;
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding-bottom: 3%;
    background: #000;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
  }
}

.grid--load_more + .load-more-container {
  &:before {
    margin-bottom:3px;
  }
}

.load-more-button {
  transition: 250ms ease;
  color: $orange;
  padding: 1em 2em 0;
  display: inline-block;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size:1.5em;
  font-weight: bold;
  position: relative;

  &:hover {
    color: #fff;

    &:after {
      border-color: #fff;
    }
  }

  &:after {
    transition: 250ms ease;
    content: '';
    border-style: solid;
    border-width: 0.17em 0.17em 0 0;
    display: block;
    height: 0.65em;
    left: 0.15em;
    position: relative;
    vertical-align: top;
    width: 0.65em;
    top: -5px;
  	transform: rotate(135deg);
    border-color: $orange;
    margin: 0 auto;
  }
}
