.iframe-wrapper {
  height: 0;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 92%;
  }
}