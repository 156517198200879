.c-talk-shop__section-wrapper--subscribe-box {
  padding: 20px 0;
  padding-top: 10px;
  padding-bottom: 60px;
  background-color: #f1f1f1;
  background-image: url("../img/talk-shop/repeating_hex.png");
  background-position: center center;
  background-size: 24px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 100%;
    max-width: 1300px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%, rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 30%, rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  }

  .c-talk-shop__section {
    width: 100%;
    padding: .5em;

    @include mq($from: 'tablet') {
      padding: 1em;
    }
  }
}

.c-talk-shop__subscribe-box {
  margin-top: 1em;
  padding: 1em;
  background-color: rgba($white, .64);
  box-shadow: 0 2px 6px 1px rgba($black, .2);
  position: relative;
  z-index: 1;

  .c-talk-shop__video-grid {
    margin-top: 2em;
  }

  @include mq($from: 'desktop') {
    padding: 1.5em 3em;
  }
}

.c-talk-shop__subscribe-box__icon, .c-talk-shop__subscribe-box__heading {
  text-align: left;
  color: $orange;
}

.c-talk-shop__subscribe-box__heading {
  font-size: 2.5em;
  margin-bottom: .5em;
}

.c-talk-shop__subscribe-box__paragraph {
  line-height: 1.2;
  font-size: 1.5em;
}

.c-talk-shop__subscribe-box__icon {
  width: 100%;
  height: 50px;
  display: none;
  position: relative;

  .o-icon {
    width: 80px;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    fill: $orange;
  }

  @include mq($from: 'tablet') {
    display: inline-block;
  }
}

.c-talk-shop__subscribe-box__action {
  font-size: 1.7em;
  text-align: center;
}