.remodal.twine-modal {
  padding: 0;
  max-width: 900px;

  @media screen and (min-width: 700px) {
    .twine-container {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
    .twine-row {
      display: table-row;
    }
    .twine-cell {
      display: table-cell;
    }
    .twine-media {
      width: 60%;
      vertical-align: middle;
    }
    .twine-content {
      width: 40%;
      vertical-align: top;
      height: 100%;
    }
    .remodal-close {
      top: auto;
      right: auto;
      left: 100%;
      bottom: 100%;
      background:#fff;
      color: #000;
      &:hover {
        background: $orange;
      }
      &:after {
        line-height: 1.1;
      }
    }
  }

  .twine-content {
    text-align: left;
  }

  .liner {
    position: relative;
  }
}

.twine-media {
  line-height: 0;
  .video-container {
    padding-bottom: 100%;
  }
  img {
    max-width: 100%;
    height: auto;
    min-height: 400px;
  }
}

.twine-content {
  position: relative;
  .profile {
    overflow: hidden;
  }
  .profile-link {
    display: block;
    &:hover {
      background-color: $orange;
      color: #fff;
      fill: #fff;
    }
  }
  .profile__image {
    width: auto;
    max-width: 20%;
    height: auto;
    float:left;
    margin-right: 2%;
  }
  .profile__name {
    font-size: 2rem;
    line-height: 1.4;
    overflow: hidden;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    margin: 0 0 0.5rem;
  }
  .profile__network-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 2%;
    vertical-align: middle;
  }
}

.twine-post {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  .post__actions {
    background:#ddd;
    padding: 5px 20px;
    line-height: 0;
    a {
      display: inline-block;
      margin-right: 3%;
      height: 2rem;
      fill: #555;
      &:hover {
        fill: $orange;
      }
      .icon--facebook {
        width: 2rem;
      }
      .icon--twitter {
        width: 2.45rem;
      }
      .icon--google-plus {
        width: 3.2rem;
      }
      .icon--pinterest {
        width: 2rem;
      }
    }
  }
  .post__body {
    padding: 5px 20px;
    box-sizing: border-box;
    font-size: 1.5rem;
    line-height: 2rem;
    p {
      margin-top: 0;
      word-wrap: break-word;
    }
  }
}

.twine-modal .box {
  padding: 20px;
}

.twine-media__cta {
  position: absolute;
  width: 4rem;
  height: 4rem;
  margin: -4rem;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
  display: inline-block;
  transition: fill 0.2s linear, background-color 0.2s linear;
  background-color: rgba(255,255,255,0.7);
  cursor: pointer;
  fill: $orange;
  &:hover {
    background-color: $orange;
    fill: #fff;
  }
}

.post__cta {
  margin-top: 15px;
  padding: 20px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: none;
  overflow: hidden;
  background-color:#fff;
  .cta__img {
    width: auto;
    max-width: 30%;
    height: auto;
    float:left;
    margin-right: 2%;
  }

  .cta__button {
    float:right;
    margin-top: 0.5em;
  }
}
