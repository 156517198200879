/*------------------------------------*\
    #BUTTONS:
\*------------------------------------*/
.button {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  border: 1px solid $orange;
  -webkit-appearance: none;
  text-decoration: none;
  display: inline-block;
  background: $orange;
  line-height: normal;
  padding: 6px 30px;
  border-radius: 0;
  font-size: 22px;
  color: #fff;
  margin: 0;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #fff;
  color: $orange;
}

.button-send {
  padding: 5px 50px 5px 30px;
  display: inline-block;
  border: none;
  border-radius: 0;
  border-left: 1px solid #fff;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
  background: $orange url('../img/bg-send.png') no-repeat 100% 100%;
  line-height: normal;
  margin: 0;
  text-decoration: none;
}
.button-send:hover {
  padding-right: 55px;
}

.button-ct {
  font-family: 'Open Sans Condensed', sans-serif;
  border: 1px solid #d2d2d2;
  text-align: center;
  background: #fff;
  color: $orange;
}
.button-ct:hover,
.button-ct:active {
  border: 1px solid $orange;
  background: $orange;
  color: #fff;
}

.button-thin {
  font-family: 'Open Sans Condensed', sans-serif;
  padding: 6px 55px;
  font-size: 20px;
}

.button-play {
  position: absolute;
  max-width: 199px;
  max-height: 47px;
  margin: 18%;
}

.button-video {
  background: transparent;
  border: 0;
  padding: 80px !important;
}
.button-video:hover {
  background: transparent;
}

.banner-button {
  font-family: 'Open Sans Bold', sans-serif;
  line-height: 1.2;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
}

.banner-button--0 {
  margin: 27% 0 0 5%;

  @media screen and (min-width: 650px) {
    margin: 15% 0 0 10%;
  }
}

.banner-button--1 {
  margin: 20% 0 0 -13%;

  @media screen and (min-width: 650px) {
    margin: 9% 0 0 -44%;
  }
}

.banner-button--2 {
    left: 50%;
    transform: translateX(-50%);
    bottom: 10%;
    top: auto;
}

.banner-button--3 {
  margin: 27% 0 0 -13%;

  @media screen and (min-width: 650px) {
    margin: 3% 0 0 16%;
  }
}
