// generic header section
.c-talk-shop__section-wrapper--header {
  background-image: url("../img/talk-shop/repeating_bricks.jpg");
  background-position: center center;
  padding: 20px;
  text-align: center;
  margin-top: -50px;
  padding-top: 60px;

  .c-talk-shop__section {
    margin-top: -50px;
    padding-top: 60px;
    max-width: map-get($mq-breakpoints, 'desktop');

    @include mq($from: 'site_break') {
      padding-top: 180px;
    }

    @include mq($from: 'desktop') {
      padding-top: 200px;
    }
  }
}

.c-talk-shop__header__logo {
  width: 70%;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 20px;
  display: block;

  @include mq($from: 'desktop') {
    width: 100%;
    max-width: 100%;
  }
}

.c-talk-shop__header__text {
  text-align: center;
  font-size: 3.3em;
  margin-bottom: 30px;

  @include mq($from: 'tablet') {
    font-size: 4vw;
  }

  @include mq($from: 'desktop') {
    text-align: left;
    font-size: 4rem;
  }

  @include mq($from: 'page_width') {
    font-size: 4.6rem;
  }
}

.c-talk-shop__header__button-wrapper {

  @include mq($from: 'desktop') {
    text-align: left;
  }
}

.c-talk-shop__header__button {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  display: block;

  @include mq($from: 'tablet') {
    max-width: 300px;
  }
}