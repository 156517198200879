// .ceros-experience {
//   width: 100%;
//   max-height: 88%;
//   box-shadow: 0 0 26px 0px rgba(0, 0, 0, 0.6);
//   height: 155vw;
// }
//
// .ceros-desktop {
//   height: 64vw;
// }

.page--ceros-circular #banner {
  min-height: calc(100vh - 200px - 160px);
}

.page--ceros-circular .container {
  padding: 0;
}

// .page--ceros-circular #header {
//   @media screen and (min-width: 820px) {
//
//     &:before {
//       opacity: 0;
//       visibility: hidden;
//     }
//
//     &:after {
//       opacity: 1;
//       -webkit-transform: rotate(1.2deg) skew(1.2deg) translateY(-125px);
//       transform: rotate(1.2deg) skew(1.2deg) translateY(-125px);
//       -ms-transform: rotate(1.2deg) skew(1.2deg) translateY(-125px);
//     }
//   }
// }
//
// .page--ceros-circular #logo {
//   background-color: rgba(0,0,0,1);
// }
//
// .page--ceros-circular .category-links__heading {
//   padding: 30px 10px;
//   width: 100%;
//   text-align: center;
// }
