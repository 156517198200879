// this is for the banners that show title, image, play button, etc at the tops of video pages and
// the middle of the landing page
.c-talk-shop__section-wrapper--media-banner {
  background-color: #222222;
  padding: 0;
  margin: 0;
  font-size: 0;
  overflow: hidden;

  &.c-talk-shop__section-wrapper--media-banner--detail-page  {
    padding-top: 80px;
    margin-top: -120px;

    @include mq($from: 'tablet') {
      margin-top: -60px;
      padding-top: 0;
    }

    @include mq($from: 'desktop') {
      margin-top: 0;
    }

    .c-talk-shop__section {
      padding-top: 80px;
    }

    @include mq($from: 'desktop') {
      padding-top: 0;
    }
  }

  .c-talk-shop__section {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: left;
    position: relative;
    font-size: 1rem;
    height: auto;
    padding: 20px;
    box-shadow: 0 0 200px 50px $black;
    max-width: 1360px;

    @include mq($from: 'tablet') {
      width: 100%;
    }

    @include mq($from: 'desktop') {
      padding: 0;
      height: 545px;
    }
  }
}

.c-talk-shop__media-banner__inner {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: map-get($mq-breakpoints, 'page_width');
  position: relative;
}

.c-talk-shop__media-banner__info {
  margin-top: 100px;
  position: relative;
  display: inline-block;
  max-width: 580px;

  @include mq($from: 'tablet') {
    margin-top: 200px;
  }

  @include mq($from: 'desktop') {
    position: absolute;
    bottom: 50px;
    left: 50px;
  }
}

.c-talk-shop__media-banner__info__heading, .c-talk-shop__media-banner__info__subheading {
  position: relative;
  margin: 0;
  line-height: 1;
  display: inline-block;
  color: $orange;
  text-align: left;
}

.c-talk-shop__media-banner__info__heading {
  padding: 10px 8px 12px 6px;
  background-color: rgba($black, .64);

  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 14px);
    height: 3px;
    background-color: $orange;
    top: 8px;
    left: 1px;
    right: 0;
    margin: auto;
  }

  &:after {
    top: auto;
    bottom: 8px;
  }

  @include mq($from: 'desktop') {
    font-size: 5em;
  }
}

.c-talk-shop__media-banner__info__subheading {
  padding: 1px 6px 1px 6px;
  top: 5px;
  z-index: 1;
  letter-spacing: 1px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -5px;
    left: 0;
    z-index: -1;
    background-color: rgba($black, .64);
  }
}

.c-talk-shop__media-banner__info__paragraph {
  color: $gray-light;
  margin-top: 20px;
  padding: 6px;
  font-size: 1.3rem;
  margin-top: 10px;
  padding: 6px;
  line-height: 2;

  span {
    background-color: rgba($black, .64);
    box-shadow: 6px 0 0 rgba($black, .64), -6px 0 0 rgba($black, .64);
    padding: 4px 0;
  }
}

.c-talk-shop__media-banner__buttons {
  position: relative;
  margin-bottom: 20px;

  .c-talk-shop__media-banner__button {
    margin-bottom: .5em;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover .o-icon {
      fill: $orange;
    }
  }

  @include mq($from: 'desktop') {
    margin-bottom: 0;
    position: absolute;
    bottom: 80px;
    right: 50px;

    .c-talk-shop__media-banner__button {
      width: 100%;
    }
  }
}