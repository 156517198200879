// join the team sign up section on the landing page
// TODO we need to start creating more reusable pieces of code, but it's not part of this
// project's scope so I'm creating the ugly, specific stuff you see below

.join-team {
  width: 100%;
  background-color: $orange;
  text-align: center;

  * {
    box-sizing: border-box;
  }
}

.join-team__heading,
.join-team__form,
.join-team__form__field-group,
.join-team__form__submit {
  display: block;
  position: relative;

  @media screen and (min-width: 640px) {
    display: inline-block;
    vertical-align: bottom;
  }
}

.join-team__heading {
  color: $white;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: .75em;
}

.join-team__form {
  padding: 1.5em;
  margin-bottom: 1em;

  @include mq($from: 639px) {
    padding: .5em;
  }
}

.join-team__form__label {
  text-align: left;
  font-size: .8em;
  margin-top: 0;
  margin-bottom: .6em;
}

.join-team__form__field-group {

  @media screen and (min-width: 640px) {
    margin-left: .5em;
    margin-right: .5em;
  }
}

.join-team__form__field {
  @include transition();
  width: 100%;
  height: 51.25px;
  display: block;
  background-color: rgba($white, .15);
  border: 1px solid rgba($white, .4);
  outline: none;
  box-shadow: none;
  color: $white;
  text-transform: uppercase;
  font-size: 1.2em;
  padding: .75em;
  line-height: 1;
  margin-bottom: 1.5em;
  cursor: pointer;
  border-radius: 0;
  -webkit-appearance: none;

  &:focus, &:hover {
    background-color: rgba($white, .3);
    border: 1px solid rgba($white, .6);
  }

  @media screen and (min-width: 640px) {
    margin-bottom: 0;
    min-width: 220px;
  }

  @media screen and (min-width: 960px) {
    min-width: 300px;
  }
}

.join-team__form__field--select {
  min-width: 220px;
}

.join-team__form__field-group__caret-icon {
  position: absolute;
  bottom: .5em;
  right: 1em;
  pointer-events: none;

  object {
    width: 15px;
    height: 20px;
  }
}

@-moz-document url-prefix() {
  .join-team__form__field-group__caret-icon {
     display: none;
  }
}

.join-team__form__submit {
  display: inline-block;
  margin-bottom: 0;
  background-color: $white;
  color: $orange;

  &:hover {
    background-color: $orange;
    border-color: $white;
    color: $white;
  }

  @media screen and (min-width: 640px) {
    width: auto;
    min-width: 0;
    margin-left: .5em;
    margin-right: .5em;
  }
}

.join-team__social {
  display: inline-block;
  margin: 0;
  margin-bottom: 1.7em;
}

.join-team__social__link {
  display: inline-block;
  margin: 0;
  margin-right: .25em;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  object {
    width: 35px;
    height: 35px;
  }

  &:last-child {
    margin-right: 0;
  }
}