$col-min:            1;
$col-max:            7;
$spacing:            1%;
$speed:              0.4s;

@function get-hexagon-dimension($grid_size, $param){
  @if $param == "R" {
    @return 100%/($grid_size + ($grid_size - 1)*0.5 + 0.75)/2;
  } @else if $param == "r" {
    @return get-hexagon-dimension($grid_size, R) * 0.86602540378;
  } @else if $param == "R-r" {
    @return get-hexagon-dimension($grid_size, R) - get-hexagon-dimension($grid_size, r);
  } @else if $param == "q" {
    @return get-hexagon-dimension($grid_size, R) * 1.5;
  } @else {
    @return 1%;
  }
}

@mixin hexagon-grid-math ($property, $param, $multiplier: 1) {
  @for $i from $col-min through $col-max {
    [data-grid-width="#{$i}"] & {
      #{$property}: get-hexagon-dimension($i,$param) * $multiplier;
    }
  }
}

.wrap {
  max-width: 1200px;
  margin: 10rem auto;
  border:1px solid #333;
  background:#eee;
  position: relative;
  z-index: 1;
}

// GRID
.grid-container {
  overflow: hidden;
}
.grid {
  margin: 0;
  font-size: 0;
  line-height: 0;
  position:relative;
  z-index:5;
  overflow:visible !important; // ignore jQuery animation overflow
}
.grid__item {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  line-height:1rem;

  &:hover {
    z-index: 3;
  }

  @for $i from $col-min through $col-max {
    [data-grid-width="#{$i}"] & {
      width: calc(100%/#{$i});
    }
  }

  &.hover .twine-item {
    pointer-events: none;
    
    .twine-item-overlay {
      transform: translateY(0);
    }

    .twine-icon use {
      fill: $orange;
    }

    .twine-item__snipe use {
      fill: $orange;
    }

    .twine-item__arrow-active {
      display: block;
    }

    .twine-item__arrow {
      display: none;
    }

    &:hover {
      .twine-item-overlay {
        transform: translateY(0);
      }

      .twine-icon use {
        fill: $orange;
      }

      .twine-item__snipe use {
        fill: $orange;
      }

      .twine-item__arrow-active {
        display: block;
      }

      .twine-item__arrow {
        display: none;
      }
    }
  }
}

.grid--flipper .grid__item:not(.hover) {
  .twine-item-overlay {
    display: none !important;
  }
}

.twine-item {
  position: relative;
  z-index:1;
  perspective:1000;
  &,
  & * {
    pointer-events: none;
  }
  &.hover {
    z-index: 999;
  }
  a {
    cursor: pointer;
  }

  &:hover {
    .twine-item-overlay {
      transform: translateY(0);
    }

    .twine-icon use {
      fill: $orange;
    }

    .twine-item__snipe use {
      fill: $orange;
    }

    .twine-item__arrow-active {
      display: block;
    }

    .twine-item__arrow {
      display: none;
    }
  }

  .grid--flipper & {

    .twine-item__foot {
      display: none;
    }

    &:hover {
      .twine-item-overlay {
        transform: translateY(-200%);
      }

      .twine-icon use {
        fill: $white;
      }

      .twine-item__snipe use {
        fill: $white;
      }

      .twine-item__arrow-active {
        display: none;
      }

      .twine-item__arrow {
        display: block;
      }
    }
  }
}

// HEXAGON
.hexagon-container {
  height: 0;
  padding-bottom: 86.4%;
  position:relative;
  transition: transform $speed ease-in-out;

  svg {
    position:absolute;
    display:block;
    top:0;
    left:0;
    width:100%;
    height:100%;
    * {
      pointer-events:all;
    }
  }
}

.twine-icon use {
  z-index: 200;
  transition: fill 500ms ease;
}

.twine-item__snipe use {
  transition: fill 500ms ease;
}

.twine-item__arrow-active {
  display: none;
}

.twine-item__head,
.twine-item__foot {
  opacity: 1;
  transition: opacity $speed/2 linear;

  text {
    fill: $orange;
    font-family: 'Open Sans Condensed', sans-serif;
  }
  .twine-item.hover &,
  .twine-item:hover & {
    opacity: 1;
    transition: opacity $speed/2 linear;
  }
}

.twine-item__head {
  .username{
    font-size:5.2px;
    fill: $orange;
    text-transform: uppercase;

    @include mq($until: "tablet") {
      font-size: 5.2px;
    }
  }
  .time{
    font-size:3px;
    fill:#fff;
  }
  rect {
    transition: fill 0.2s linear;
  }
  &:hover rect {
    fill: $orange;
  }
}

.twine-item-overlay {
  background-color: rgba(37, 37, 37, 0.9);
  transition: 500ms ease;
  transform: translateY(-200%);
}

.twine-item__body {
  opacity: 1;
  transition: opacity 0.2s linear;
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  p {
    overflow:hidden;
    height:54.5px;
    font-size:4.5px;
    line-height:6px;
    color:#fff;
    padding: 0 1em;
    padding-bottom: 1em;
    text-align: left;
    box-sizing: border-box;
    margin: 0;

    @include mq($until: "tablet") {
      font-size: 5.5px;
      line-height: 1.4;
    }
  }
  .text-wrap-shape {
    display: block;
    height:56.4px;
    width: 19.2px;
    &.left {
      float:left;
      shape-outside: polygon(0px 0px, 13.5px 0px, 0px 23.2px, 19.2px 56.4px, 0px 56.4px);
      margin-right: 0.5px;
    }
    &.right {
      float:right;
      shape-outside: polygon(19.2px 0px, 19.2px 56.4px, 0px 56.4px, 19.2px 23.2px, 5.7px 0px);
      margin-left: 0.5px;
    }
  }

  .twine-item.hover &,
  .twine-item:hover & {
    opacity: 1;
  }
}

.twine-item__body.svg-fallback {
  position: absolute;
  display: block;
  top: 23%;
  bottom: 11.6%;
  left: 19.5%;
  width: 61%;
  padding: 1rem;
  box-sizing: border-box;
  font-size:1.5rem;
  line-height: 2rem;
  color:#fff;
  overflow:hidden;
}

.no-svgforeignobject foreignObject { display: none; }
.svgforeignobject .svg-fallback { display: none; }

.twine-item__foot {
  a {
    transition: fill 0.2s linear;
    &:hover * {
      fill: $orange;
    }
  }
}

.twine-item__snipe.snipe--head {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s linear;
}
