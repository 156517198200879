.c-talk-shop__section-wrapper--expandable {
  padding: 20px 0;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 2px rgba($black, .1);

  .c-talk-shop__section {
    width: 100%;
    padding: .5em;

    @include mq($from: 'tablet') {
      padding: 1em;
    }
  }
}

.c-talk-shop__expandable {
  @include transition();
  border: 1px solid $orange;
  border-top: 10px solid $orange;
  padding: 1em;
  max-height: 91px;
  overflow: hidden;

  .c-talk-shop__video-grid {
    margin-top: 2em;
  }

  .c-talk-shop__video-grid__item {
    margin-bottom: 2em;
  }

  @include mq($from: 'tablet') {
    max-height: 96px;
  }

  @include mq($from: 'desktop') {
    max-height: 111px;
    padding: 1.5em 3em;
  }
}

.c-talk-shop__expandable__icon, .c-talk-shop__expandable__heading {
  text-align: left;
  color: $orange;
}

.c-talk-shop__expandable__heading {
  font-size: 2.5em;
}

.c-talk-shop__expandable__icon {
  width: 100%;
  height: 50px;
  display: none;
  position: relative;

  .o-icon {
    width: 120px;
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    fill: $orange;
  }

  @include mq($from: 'tablet') {
    display: inline-block;
  }
}

.c-talk-shop__expandable__action-text, .c-talk-shop__expandable__action-icon {
  display: inline-block;
  vertical-align: middle;
}

.c-talk-shop__expandable__action-text {
  text-align: right;
  color: $orange;
  padding-right: 10px;
  display: none;

  @include mq($from: 'tablet') {
    display: inline-block;
  }
}

.c-talk-shop__expandable__action-icon {
  margin-left: auto;
  display: block;

  .o-icon {

    .c-talk-shop__expandable.is-expanded & {
      transform: rotate(180deg);
    }
  }

  @include mq($from: 'tablet') {
    display: inline-block;
  }
}