// announcement/news section on the landing page
// TODO we need to start creating more reusable pieces of code, but it's not part of this
// project's scope so I'm creating the ugly, specific stuff you see below

.home-news {
  padding-top: 32px;

  * {
    box-sizing: border-box;
  }

  .container {
    display: table;

    @media screen and (min-width: 960px) {
      display: block;
      padding-top: 16px;
    }
  }

  .home-news__column {
    position: relative;
    float: none;
    width: 100%;
    margin-left: 0;
    z-index: 1;
    display: table-footer-group;

    @media screen and (min-width: 960px) {
      display: block;
      float: left;
      width: 30.6666666667%;
    }

    &:last-child {
      display: table-header-group;

      @media screen and (min-width: 960px) {
        width: 65.3333333333%;
        margin-left: 4%;
      }
    }
  }
}

.home-news__card {
  padding: 1em 0;
  position: relative;

  @media screen and (min-width: 960px) {
    height: 320px;
    padding: 0;
    margin-bottom: 3em;
  }
}

.home-news__card__image {
  width: 100%;
  height: 120px;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .home-news__card--first & {
    background-image: url("img/landing_page_pod_1_v2_guetzli.jpg");
  }

  .home-news__card--second & {
    background-image: url("img/landing_page_pod_2_v2_guetzli.jpg");
  }

  .home-news__card--featured & {
    height: 240px;
    background-image: url("img/landing_page_pod_featured_guetzli.jpg");

    @media screen and (min-width: 960px) {
      height: 100%;
    }
  }

  @media screen and (min-width: 960px) {
    height: 100%;
  }
}

.home-news__card__chevron-icon {
  display: none;
  position: absolute;
  top: .45em;
  right: .9em;

  object {
    width: 14px;
    height: 14px;
  }

  @media screen and (min-width: 960px) {
    display: block;
  }
}

.home-news__card__content {
  @include transition();
  background-color: rgba($black, .9);
  padding: .45em;

  @media screen and (min-width: 960px) {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    background-color: rgba($black, .75);
    width: calc(100% - 20px);
    height: 35px;
    overflow: hidden;

    .home-news__card:hover & {
      height: calc(100% - 20px);
    }
  }
}

.home-news__card__content__heading {
  text-transform: uppercase;
  margin-bottom: .35em;
  color: $orange;
  font-size: 1.75em;

  @media screen and (min-width: 960px) {
    font-size: 1.3em;
  }

  @media screen and (min-width: 1100px) {
    font-size: 1.5em;
  }
}

.home-news__card__content__body {
  line-height: 1.45;
  color: $white;
  margin-bottom: 2em;
}

.home-news__card__content__link {
  text-transform: uppercase;
  color: $orange;

  &:hover {
    color: lighten($orange, 15);
  }
}

.home-news__card--featured {
  position: relative;

  @media screen and (min-width: 960px) {
    height: 685px;
  }
}

.home-news__feature__heading {
  text-transform: uppercase;
  margin-bottom: .25em;
  font-size: 2.5em;

  @media screen and (min-width: 640px) {
    font-size: 3.5em;
  }
}

.home-news__feature__lsa-logo {
  @include center-content();
  width: 60%;
  max-width: 200px;
  z-index: 1;

  @media screen and (min-width: 960px) {
    @include transform(none);
    top: auto;
    left: auto;
    right: 1em;
    bottom: 1em;
    max-width: 250px;
  }
}

.home-news__feature__list {
  margin-bottom: 2em;
  text-align: left;

  @media screen and (min-width: 640px) {
    width: 60%;
    margin-bottom: 5em;
  }
}

.home-news__feature__list__bullet {
  position: relative;
  font-size: 1.2em;
  padding-left: 1.5em;
  line-height: 1.2;
  margin-bottom: .3em;

  @media screen and (min-width: 640px) {
  font-size: 1.6em;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #f28035;
    top: 3px;
    left: 6px;

    @media screen and (min-width: 640px) {
      top: 6px;
    }
  }
}

.home-news__feature__drill {
  position: absolute;
  display: none;

  @media screen and (min-width: 640px) {
    display: block;
    width: 45%;
    top: 13%;
    right: -10%;
  }

  @media screen and (min-width: 760px) {
    width: 40%;
    top: 8%;
    right: -1%;
  }

  @media screen and (min-width: 960px) {
    width: 55%;
    top: 11%;
    right: -15%;
  }
}