@mixin transition($time: 250ms) {
  -webkit-transition: all $time ease-in-out;
  -moz-transition: all $time ease-in-out;
  -o-transition: all $time ease-in-out;
  transition: all $time ease-in-out;
}

@mixin transform($transform...) {
  -moz-transform: $transform;
  -o-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -ms-transform: $transform;
  -ms-transform-origin: center center;
}

// center unknown dimension element within another element, works with ie9+
@mixin center-content() {
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
}