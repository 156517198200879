/*------------------------------------*\
    #HEADER/LOGO:
\*------------------------------------*/

#header {
  position: relative;
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
  text-align: right;
  z-index: 1001;

  @media screen and (min-width: 820px) {
    position: fixed;
  }

  &:before {
    @include transition();
    content: '';
    display: none;
    background-color: rgba(0,0,0, .8);
    width: 100%;
    height: 150px;
    position: absolute;
    top: -150px;
    left: 0;
    z-index: 1;
    box-shadow: 0 0 100px 80px  rgba(0,0,0, .7);
    opacity: 1;
    visibility: visible;
    pointer-events: none;

    @media screen and (min-width: 820px) {
      display: block;
    }
  }

  &:after {
    @include transition();
    background: $orange;
    z-index: 1;
    height: 64px;
    padding-top: 20px;
    content: '';
    display: block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 0 20px 0 rgba($black, 0.2);
    -webkit-transform: rotate(1.2deg) skew(1.2deg) translateY(-12px);
    transform: rotate(1.2deg) skew(1.2deg) translateY(-12px);
    -ms-transform: rotate(1.2deg) skew(1.2deg) translateY(-12px);

    @media screen and (min-width: 820px) {
      opacity: 0;
      height: 200px;
      -webkit-transform: rotate(1.2deg) skew(1.2deg) translateY(-135px);
      transform: rotate(1.2deg) skew(1.2deg) translateY(-135px);
      -ms-transform: rotate(1.2deg) skew(1.2deg) translateY(-135px);
    }
  }

  &.background-visible {

    @media screen and (min-width: 820px) {

      &:before {
        opacity: 0;
        visibility: hidden;
      }

      &:after {
        opacity: 1;
        -webkit-transform: rotate(1.2deg) skew(1.2deg) translateY(-125px);
        transform: rotate(1.2deg) skew(1.2deg) translateY(-125px);
        -ms-transform: rotate(1.2deg) skew(1.2deg) translateY(-125px);
      }
    }
  }

  & + section:not(#header-video-container) {
    @media screen and (min-width: 820px) {
      margin-top: 120px;
    }
  }
}

#header nav {
  position: absolute;
  z-index: 999;
  right: 0;
}

#top-links li { margin: 0 8px 0 0; }
#header #top-links { margin: 12px 0 0;
padding-bottom: 7px; }

#logo {
  position: absolute;
  background: #000;
  padding: 20px;
  width: 184px;
  height: 50px;
  z-index: 2;
  left: 10px;
  top: 15px;
  margin: 0;

  @media screen and (min-width: 820px) {
    background-color: rgba(0,0,0,.5);
  }

  &.is-solid {
    background-color: rgba(0,0,0,1);
  }

  .logo {
    // makes ie9 respect aspect ratio
    width: 100%;
  }
}
object.logo {
  pointer-events: none;
}

/*------------------------------------*\
    #VIDEO:
\*------------------------------------*/

#header-video-container {
  position: relative;
  height: 75vh;
  width: 100%;
  max-height: 50vw;
  overflow: hidden;
  margin-top: -18px;

  @media screen and (min-width: 820px) {
    margin-top: 0;
    min-height: 440px;
    max-height: 640px;
  }

  video {
    position: absolute;
    // z-index: 1;
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    @media screen and (min-width: 680px) {
      height: auto;
    }
  }
}

// VIDEO WITH TEXT CONTENT
.c-header-video {
  @include mq($until: tablet) {
    max-height: 425px !important;
  }
  .container {
    position: relative;
    z-index: 2;
    height: 100%;
  }
  // Black dot grid overlay for video
  // &::after {
  //   content: "";
  //   background-image: linear-gradient(45deg, #000, #000 25%, transparent 0, transparent 75%, transparent 0, transparent),
  //   linear-gradient(45deg, transparent 25%, transparent 0, transparent 75%, #000 0, #000);
  //   background-size: 2px 2px;
  //   background-position: 0 0,
  //   1px 1px;
  //   opacity: .35;
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  // }
}

.c-header-video__content {
  width: 100%;
  z-index: 2;

  @include mq($from: tablet) {
    @include center-content;
  }

  h3 {
    max-width: 730px;
  }

  .hs-white {
    text-shadow: 0 2px 2px rgba(0,0,0,0.35);
  }

  .button {
    font-weight: 900;
    text-align: center;
  }
}

.slanted-page-break {
  -webkit-backface-visibility: hidden;
  width: 100%;
  background-color: white;
  position: relative;
  will-change: content;
  padding: 0;

  .slanted-page-break__line {
    -webkit-backface-visibility: hidden;
    content: '';
    display: block;
    width: 110%;
    height: 40px;
    transform: rotate(1.2deg);
    -ms-transform: rotate(1.2deg);
    background-color: $orange;
    position: absolute;
    top: -40px;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 2;
    box-shadow: 0 0 30px 2px rgba(0,0,0,.64);
    will-change: content;
  }

  .slanted-page-break__pattern {
    -webkit-backface-visibility: hidden;
    content: '';
    display: block;
    width: 150%;
    height: 200px;
    transform: rotate(1.2deg);
    -ms-transform: rotate(1.2deg);
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    left: -10px;
    margin: auto;
    overflow: hidden;
    will-change: content;

    &:before {
      -webkit-backface-visibility: hidden;
      content: "";
      position: absolute;
      bottom: 0;
      width: 250%;
      height: 200%;
      z-index: -1;
      background-image: url("img/bg-header.png");
      background-repeat: repeat;
      background-position: center bottom;
      transform: rotate(-.5deg);
      will-change: content;
    }
  }
}

/*------------------------------------*\
    #MENU/DROP DWON:
\*------------------------------------*/

#mobile-menu-toggle {
  background-color: transparent;
  width: 50px;
  height: 35px;
  float: none;
  display: inline-block;
  margin-top: 17px;

  object, svg {
    width: 100%;
    max-height: 100%;
    pointer-events: none;
    fill: $white;
  }

  @media screen and (min-width: 820px) {
    display: none;
  }
}

// not sure why the menu button was bumped over so far in the plugin, but I removed that
.sb-toggle-left {
  margin-right: 0;
}

// overriding the slidebar rule that adds a transform over the content of the site. this makes a new
// coordinate system which breaks any children that try to use posiiton fixed, which is bad
.sb-slide, #sb-site, .sb-site-container, .sb-slidebar {
	-webkit-transform: none;
	   -moz-transform: none;
	     -o-transform: none;
	        transform: none;
}

#sb-site {
  // overflow: hidden;
  overflow-x: hidden;
}

#menu {
  li {
    margin: 0 .25em;

    ul {
      display: none;
      margin: 0;
      padding: 0;
      border: 0;
      list-style: none;
      line-height: 1;
      position: absolute;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    &:hover > ul {
      display: block;
    }

    & > ul {
      li {
        border-bottom: 1px solid rgba(150, 150, 150, 0.15);
        background: #3c3c3c;
        display: block;
        margin:0;

        &:last-child {
          border-bottom: none;
        }

        &:after {
          content: "";
        }
      }

      a {
        display: block;
        padding: 15px 20px;

        &:hover {
          color: #f3f3f3;
          background: #000;
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  #mobile-menu { display: block; }

  #header #top-links,
  #header #menu {
    display: none;
  }

  #logo {
    padding: 10px;
    width: 128px;
    height: 46px;
    left: 10px;
    margin: 0;
    top: 10px;
  }

  #header nav li:first-child {
    font-size: 14px;
    margin: 0 0 5px;
    display: block;
  }
}

/*------------------------------------*\
    #CEROS OVERRIDES:
\*------------------------------------*/

@media screen and (min-width: 720px) {
  body.page--ceros-circular {
    height: 100%;
  }
}

.page--ceros-circular #header {
  margin-bottom: 40px;
}

.page--ceros-circular #header.background-visible:not(#foo) {
  position: relative;
  display: block;
}

@media screen and (max-width: 820px) {
  .page--ceros-circular #header.background-visible:not(#foo) {
    height: 27px;
  }
}

.page--ceros-circular #header.background-visible:not(#foo):after {
  height: 55px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  box-shadow: 0 0 8px 0 black;
}

@media screen and (min-width: 820px) {
  .page--ceros-circular #header.background-visible:not(#foo):after {
    height: 75px;
  }
}
