// TODO we need to start creating more reusable pieces of code, but it's not part of this
// project's scope

#videos {
  padding-top: 26px;
}

.video-module-featured-video {
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.video-module-featured-video__title {
  padding: .75em;
  background-color: rgba($black, .9);
  color: $orange;
  font-size: 1.7em;
}

.video-module {
  border-top: 4px solid #e6e6e6;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
}

.video-module__filters {
  @include transition();
  text-align: center;
  margin: 1em 0;
  height: 36px;
  overflow: hidden;
  background-color: $gray-light;
  position: relative;

  & > span {
    line-height: 0;
    display: block;

    @include mq($from: "desktop") {
      margin-top: 10px;
    }
  }

  @include mq($until: "desktop") {
    &.is-active {
      height: 216px;
    }
  }

  @include mq($from: "desktop") {
    height: auto;
    overflow: visible;
    background-color: transparent;
  }
}

.video-module__filters__chevron-icon {
  position: absolute;
  top: 8px;
  right: 10px;

  object {
    width: 15px;
    height: 15px;
  }

  @include mq($from: "desktop") {
    display: none;
  }
}

.video-module__filters__filter {
  @include transition();
  cursor: pointer;
  padding: .5em 0;

  @include mq($from: "desktop") {
    display: inline-block;
    margin: 0 1em;
    padding: 0;
    padding-bottom: .1em;
    border-bottom: 2px solid transparent;
    position: relative;
    transform: none !important;

    &.mixitup-control-active {
      border-bottom: 2px solid $orange;
    }

    &:last-child:before {
      display: none;
    }

    &:before {
      content: '';
      display: block;
      width: 2px;
      height: 100%;
      position: absolute;
      top: -.1em;
      right: -1.1em;
      background-color: #aaaaaa;
    }
  }

  &:hover {
    color: $orange;
  }

  &.mixitup-control-active {
    color: $orange;
  }
}

.video-module__videos__video-wrapper {
  width: 100%;
  margin: 1em 0;
  position: relative;
  border-bottom: 6px solid #e6e6e6;
  overflow: hidden;
  display: inline-block;
  background-size: cover;
  background-position: center -18px;
  background-repeat: no-repeat;

  @include mq($from: "tablet") {
    background-position: center -21px;
  }

  &:after {
    content: '';
    display: block;
    padding-top: 80%;
  }
}

.video-module__videos__video-title {
  font-size: 1.2em;
  background-color: #f1f1f1;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  padding: 1em;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include mq($from: "tablet") {
    font-size: 1.4em;
  }
}

.video-module__videos__video-timestamp {
  position: absolute;
  bottom: 56px;
  right: 5px;
  background-color: $black;
  color: $white;
  padding: .25em .5em;
}

.video-module__filters__youtube-icon {
  display: inline-block;
  @include center-content();
  top: 43%;

  object {
    width: 50px;
    height: 50px;
  }
}