$break_small: 600px;

@mixin transition() {
  -webkit-transition: all 350ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -moz-transition: all 350ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  -o-transition: all 350ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
  transition: all 350ms cubic-bezier(0.165, 0.840, 0.440, 1.000); /* easeOutQuart */
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .c-panorama {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

p.c-footer {
  margin: 3rem 0 1rem 0;
  color: #444444;
  font-family: 'Open Sans Condensed', sans-serif;
  text-transform: none;
}

.c-panorama__title {
  margin: 1rem 0;
}

.c-mixed {
  text-transform: none;
  margin: 1rem 0 3rem 0;
}

.c-panorama-wrapper {
  height: 375px;
  @include aspect-ratio(16, 9);
  @media screen and (min-width: 768px) {
    height: auto;
  }
}

.c-panorama-wrapper--mobile {
  min-height: 1000px;

  .pnlm-hotspot {
    height: 75px;
    width: 75px;
    border-radius: 40px;
    background-size: cover;
  }
  .pnlm-hotspot.pnlm-info {
    background-position: 0 -300px;
  }

  .c-overlay.is-visible {
    width: 70%;
  }

  .c-overlay__info__title {
    font-size: 2em;
  }

  .c-overlay__info__description {
    font-size: 2em;
  }
}

.c-panorama {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: 1rem auto .5rem auto;
  width: 100%;
  position: relative;
  margin-top: 0;

  &.is-fullwindow {
    width: 100%;
    height: 100%;
    transform: none;
  }
}

.c-panorama__img {
  z-index: 10;
  position: absolute;

  img {
    display: block;
    max-width: 100vw;
    width: calc(100vw * .35);
    position: absolute;
    margin-left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-top: 50%;
    pointer-events: none;

    @media screen and (max-width: 768px) {
      display: none;
    }

    @media screen and (min-width: 600px) {
      width: calc(100vw * .2);
    }

    @media screen and (min-width: 1000px) {
      width: calc(1140px * .17);
    }

    &.buy-kit {
      width: calc(100vw * .6);

      @media screen and (min-width: 600px) {
        width: calc(100vw * .5);
      }

      @media screen and (min-width: 1000px) {
        width: calc(1140px * .45);
      }
    }

    &.buy-kit-pc {
      width: calc(100vw * .6);

      @media screen and (min-width: 600px) {
        width: calc(100vw * .25);
      }

      @media screen and (min-width: 1000px) {
        width: calc(1140px * .23);
      }
    }

    &.get-one {
      width: calc(100vw * .35);

      @media screen and (min-width: 600px) {
        width: calc(100vw * .3);
      }

      @media screen and (min-width: 1000px) {
        width: calc(1140px * .23);
      }
    }

    &.buy-this {
      width: calc(100vw * .35);

      @media screen and (min-width: 600px) {
        width: calc(100vw * .2);
      }

      @media screen and (min-width: 1000px) {
        width: calc(1140px * .17);
      }
    }
  }
}

.c-panorama__panorama {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  margin: auto;

  .c-panorama.is-fullwindow & {
    width: 100%;
    height: 100%;
  }
}

.c-panorama__control--fullwindow-toggle {
  @include transition();
  display: none;
  width: 33px;
  height: 33px;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffffff;
  cursor: pointer;

  &.is-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

.c-overlay {
  @include transition();
  -ms-overflow-style: none;
  overflow: auto;
  width: 300px;
  position: absolute;
  top: 0;
  right: -300px;
  z-index: 10;
  max-width: 90%;
  padding: 10px 0;

  &.is-visible {
    right: 0;
  }

  p {
    margin: 2rem 0 1rem 0;
  }
}

.c-overlay__image-wrapper {
  padding: 15px;
  text-align: center;
}

.c-panorama__overlay-container {
  background-color: #fff;
  height: 100%;
}

.c-panorama:not(.bogo-on):not(.bogoTool-on):not(.c-panorama--mobile) {
  .c-panorama__overlay-container {

    @media screen and (max-width: 768px) {
      display: flex;
      height: auto;

      &.is-visible {
        width: 100%;
      }

      .c-overlay__close {
        width: 50px;
        height: 30px;
        padding-top: 5px;
      }

      .c-overlay__info {
        padding: 0 10px 10px;
      }

      .c-mixed {
        font-size: 1em;
        margin: 1rem 0;
      }

      .c-overlay__image-wrapper {
        padding: 5px;
      }

      .button {
        padding: 6px 10px;
      }

      .c-overlay__image-wrapper {
        background-color: #fff;
        width: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .c-overlay__info__link {
        margin-top: 1em;
        font-size: 0.75em;
      }
    }
  }
}

.c-panorama--mobile:not(.bogo-on):not(.bogoTool-on) {
  .c-panorama__overlay-container {
    display: flex;
    height: auto;

    &.is-visible {
      width: 100%;
    }

    .c-overlay__close {
      width: 100px;
      height: 60px;
      padding-top: 5px;
    }

    .c-overlay__info {
      padding: 0 10px 10px;
    }

    .c-mixed {
      font-size: 3em;
      margin: 1rem 0;
    }

    .c-overlay__image-wrapper {
      padding: 5px;
    }

    .button {
      padding: 6px 10px;
    }

    .c-overlay__image-wrapper {
      background-color: #fff;
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .c-overlay__info__description.is-nlp {
      padding-left: 100px;
    }

    .c-overlay__info__description.is-nlp:before {
      width: 84px;
      height: 84px;
    }

    .c-overlay__info__link {
      margin-top: 1em;
      font-size: 2.25em;
    }
  }
}


.c-overlay__info__link {
  display: block;
  text-align: center;
  margin-top: 4rem;
}

.c-overlay__close {
  width: 30px;
  height: 30px;
  float: right;
  fill: #fc1526;
  cursor: pointer;
}

.c-overlay__image {
  width: auto;
  max-height: 150px;
  text-align: center;

  @media screen and (min-width: 1000px) {
    max-height: 200px;

    .c-panorama-wrapper--mobile & {
      max-height: 300px;
    }
  }
}

.c-overlay__info {
  padding: 10px 20px 20px 20px;
  background-color: #fff;
}

.c-overlay__info__title {
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 5px;
  position: relative;

  &.is-new {
    padding-left: 0;
    min-height: 40px;
    display: flex;
    flex-direction: column;

    &:before {
      content: "";
      background-image: url(../img/360/new.svg);
      background-size: contain;
      background-position: center;
      display: block;
      left: 0;
      top: 0;
      background-repeat: no-repeat;
      width: 70px;
      height: 30px;

      @media screen and (max-width: 768px) {
        height: 30px;
      }
    }

    & ~ .c-overlay__info__link {
      display: none;
    }

    & ~ .c-overlay__info__description {
      &:after {
        content: "";
        background-image: url(../img/360/comingsoon.svg);
        background-size: contain;
        background-position: center;
        display: block;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        width: 95%;
        margin: 0 auto;
        text-align: center;
        height: 30px;
        margin-left: 0;
        @media screen and (min-width: 768px) {
          margin-top: 40px;
          .c-panorama-wrapper--mobile & {
            margin-top: 0;
          }
        }
        @media screen and (min-width: 1000px) {
          margin-top: 50px;
          .c-panorama-wrapper--mobile & {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.c-overlay__info__description {
  font-size: 1.5em;
  font-weight: normal;
  position: relative;

  &.is-nlp {
    padding-left: 50px;

    &:before {
      content: "";
      background-image: url(../img/360/NLP.svg);
      background-size: contain;
      background-position: center;
      width: 42px;
      height: 42px;
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      background-repeat: no-repeat;

      @media screen and (max-width: 768px) {
        height: 30px;
      }
    }
  }

  &.is-sb {
    padding-left: 50px;
    min-height: 40px;
    display: flex;
    align-items: center;

    &:before {
      content: "";
      background-image: url(../img/360/special-buy.svg);
      background-size: contain;
      background-position: center;
      width: 42px;
      height: 42px;
      display: block;
      position: absolute;
      left: 0;
      top: 1px;
      background-repeat: no-repeat;

      @media screen and (max-width: 768px) {
        height: 30px;
      }
    }
  }
}

.tuff-shed {
  display: inline-block;
  width: 150px;
  height: auto;
  margin-top: -5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;

  @media screen and (min-width: 768px) {
    margin-top: -9px;
    .c-panorama-wrapper--mobile & {
      margin-top: -5px;
    }
  }
}

.c-overlay__image--mobile {
  display: none;
}

.bogo-on, .bogoTool-on {
  .c-overlay {
    width: 90%;
    overflow: visible;
    padding-bottom: 0;

    @media screen and (min-width: 375px) {
      width: 75%;
      .c-panorama-wrapper--mobile & {
        width: 90%;
      }
    }

    @media screen and (min-width: 500px) {
      width: 60%;
      .c-panorama-wrapper--mobile & {
        width: 90%;
      }
    }
    @media screen and (min-width: 580px) {
      width: 50%;
      .c-panorama-wrapper--mobile & {
        width: 90%;
      }
    }

    @media screen and (min-width: 768px) {
      width: 300px;
      overflow: scroll;
      .c-panorama-wrapper--mobile & {
        width: 90%;
        overflow: visible;
      }
    }
  }

  .c-overlay.is-visible {
    right: 5%;

    @media screen and (min-width: 375px) {
      right: 15%;
      .c-panorama-wrapper--mobile & {
        right: 5%;
      }
    }

    @media screen and (min-width: 500px) {
      right: 20%;
      .c-panorama-wrapper--mobile & {
        right: 5%;
      }
    }
    @media screen and (min-width: 580px) {
      right: 25%;
      .c-panorama-wrapper--mobile & {
        right: 5%;
      }
    }

    @media screen and (min-width: 768px) {
      right: 0;
      .c-panorama-wrapper--mobile & {
        right: 5%;
      }
    }
  }

  .c-panorama__overlay-container {
    box-shadow: 0px 4px 15px 1px #333;
  }

  .c-overlay__info__title, .c-overlay__info__description {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      .c-panorama-wrapper--mobile & {
        display: none;
      }
    }
  }

  .c-overlay__image {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      .c-panorama-wrapper--mobile & {
        display: none;
      }
    }
  }

  .c-overlay__image--mobile {
    display: block;
    @media screen and (min-width: 768px) {
      display: none;
      .c-panorama-wrapper--mobile & {
        display: block;
      }
    }
  }

  .c-overlay__info {
    padding: 0;
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    text-align: center;
    @media screen and (min-width: 768px) {
      bottom: 0;
      text-align: left;
      position: relative;
      padding: 10px 20px 20px 20px;
      .c-panorama-wrapper--mobile & {
        bottom: -25px;
        text-align: center;
        position: absolute;
        padding: 0;
      }
    }
  }

  .c-overlay__info__link {
    margin-top: 0;
    display: inline-block;
    position: relative;
    top: 15px;
    box-shadow: 0px 4px 15px 1px #333;

    @media screen and (min-width: 768px) {
      display: block;
      top: 0;
      box-shadow: none;
      margin-top: 4rem;
      .c-panorama-wrapper--mobile & {
        display: inline-block;
        top: 15px;
        box-shadow: 0px 4px 15px 1px #333;
        margin-top: 0;
      }
    }
  }

  .c-overlay__image--mobile {
    display: block;
    margin: 0 auto;

    @media screen and (min-width: 580px) {
      max-width: 220px;
      .c-panorama-wrapper--mobile & {
        max-width: auto;
      }
    }

    @media screen and (min-width: 768px) {
      max-width: 180px;
      display: none;
      .c-panorama-wrapper--mobile & {
        max-width: auto;
        display: block;
      }
    }
    @media screen and (min-width: 960px) {
      max-width: 270px;
      .c-panorama-wrapper--mobile & {
        max-width: auto;
        display: block;
      }
    }

    @media screen and (min-width: 1100px) {
      max-width: 300px;
      .c-panorama-wrapper--mobile & {
        max-width: auto;
        display: block;
      }
    }
  }

  .c-panorama__star {
    visibility: visible;
  }

  .c-panorama__star-inner-tool {
    display: none;
  }

  &.for-5pc .img-5pc {
    visibility: visible ! important;
  }

  &.for-2pc .img-2pc {
    visibility: visible ! important;
  }
}

.bogoTool-on {
  .c-overlay__image {
    display: none;
    @media screen and (min-width: 768px) {
      display: block;
      margin: 0 auto;
      .c-panorama-wrapper--mobile & {
        display: none;
      }
    }
  }

  .c-overlay__image--mobile {
    display: block;
    @media screen and (min-width: 768px) {
      display: none;
      .c-panorama-wrapper--mobile & {
        display: block;
      }
    }
  }

  .c-panorama__star {
    visibility: visible !important;
  }

  .c-panorama__star-inner {
    display: none;
    visibility: hidden !important;
  }

  .c-panorama__star-inner-tool {
    display: none;
    visibility: hidden ! important;

    @media screen and (min-width: 768px) {
      display: block;
      visibility: visible !important;
    }
  }

  &.for-5pc .img-5pc {
    visibility: visible ! important;
  }

  &.for-2pc .img-2pc {
    visibility: visible ! important;
  }

  .img-x4 {
    visibility: visible !important;
  }
}

.bogo-on .c-panorama__star, .bogoTool-on .c-panorama__star {
  @media screen and (max-width: 768px) {
    visibility: hidden !important;
  }

  .c-panorama__star-inner-tool {
    display: block;
  }
}

.c-panorama__star {
  color: #f28035;
  padding-left: 5px;
  visibility: hidden;
  font-size: 3em;
  z-index: 0;
}

.c-panorama__star-inner {
  color: #f28035;
  position: absolute;
  z-index: -5;
  top: -19px;
  left: -7px;
}

.c-panorama__star-inner-tool {
  color: #f28035;
  display: none;
  position: absolute;
  z-index: -5;
  top: -19px;
  left: -7px;
}

.img-5pc, .img-2pc, .img-x4 {
  visibility: hidden ! important;
}

.pnlm-graphic {
  background-image: none;

  &:hover {
    background: none;
  }
}

.strikethrough {
  text-decoration: line-through;
}
