.teamridgid-header {
  margin-top: -20px;
  background-image:  url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image:  -webkit-linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image:  -moz-linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image:  -o-linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image:  -ms-linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-image:  linear-gradient(rgba(0,0,0, 0) 0%,rgba(0,0,0, 1) 100%), url("img/_sons_of_sawdust/_header/sons_of_sawdust_header.large.jpg");
  background-size: cover;
  -moz-background-size: cover;  /* Firefox 3.6 */
  background-position: center;  /* Internet Explorer 7/8 */

  @include mq($from: "tablet") {
    padding-top: 200px;
  }

  @include mq($from: "desktop") {
    padding-top: 260px;
  }

  * {
    -webkit-font-smoothing: antialiased;
  }

  button {
    font-size: 1.3em;
  }
}

.teamridgid-header h1,
.teamridgid-header h2,
.teamridgid-header h3,
.teamridgid-header h4,
.teamridgid-header h5,
.teamridgid-header h6 {
  font-family: 'Open Sans Bold', sans-serif;
  margin-bottom: 0;
}

.teamridgid-header p {
  color:#fff;
  margin-top: 1em;
  margin-bottom: 2em;

  @include mq($from: "tablet") {
    margin-top: 2.5em;
    margin-bottom: 2.7em;
  }

  @include mq($from: "desktop") {
    width: 65%;
  }
}

.teamridgid-header h1 {
  line-height: 0.8;
  font-size: 3.5em;
  color: #f28035;
  margin-top: 4px;

  @include mq($from: "tablet") {
    font-size: 5.5em;
  }
}

.teamridgid-header h2 {
  color:white;
  padding: 20px 0;
  font-size: 2em;

  @include mq($from: "tablet") {
    font-size: 3.2em;
    margin-bottom: .35em;
  }
}

.teamridgid-header .container {
  padding: 110px 0 45px;
  padding-top: 6em;
  padding-bottom: 1.2em;
  padding-left: .75em;
  padding-right: .75em;

  @include mq($from: "mobile") {
    padding-left: 0;
    padding-right: 0;
  }

  @include mq($from: "tablet") {
    padding-bottom: 45px;
  }
}

.teamridgid-header span {
  background-color: #f28035;
  font-weight: bold;
  padding: 0 5px;
  color:#000;
  font-size: 1em;

  @include mq($from: "tablet") {
    font-size: 1.4em;
  }
}

.teamridgid-header button {
  font-family: 'Open Sans Bold', sans-serif;
  background: none;
  display: block;
  margin: 0 auto;
  color: white;
  outline: none;
  border: 0;
}

.teamridgid-header button:hover {
  color: #f28035;
}

.teamridgid-video__chevron-icon {
  text-align: center;
  cursor: pointer;

  object {
    width: 40px;
    height: 40px;
  }
}

.video-wrapper {
  position: relative;
  cursor: pointer;
}

.teamridgid-video__modal-trigger {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-sizing: border-box;
  z-index: 10;
}

.teamridgid-video__youtube-icon {
  @include center-content();
  text-align: center;
  pointer-events: none;

  object {
    opacity: .85;
    width: 50%;
    height: 50%;
    max-width: 200px;
    pointer-events: none;
  }
}

.video-wrapper {

}

.teamridgid-video__mobile {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 56.25%;

  @include mq($from: "tablet") {
    display: none;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    visibility: hidden;
  }
}

.teamridgid-video__desktop {
  display: none;

  @include mq($from: "tablet") {
    display: block;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.ridgid-line-container {
  overflow: hidden;
  width: 100%;
}

.ridgid-line {
  height: 0;
  width: 100%;
  margin: -10px 0 50px -1800px;
  border-top: 80px solid #f28035;
  border-left: 4000px solid transparent;
  -webkit-filter: drop-shadow(1px 1px 28px rgba(0,0,0,0.60));
  -moz-filter: drop-shadow(1px 1px 28px rgba(0,0,0,0.60));
  -ms-filter: drop-shadow(1px 1px 28px rgba(0,0,0,0.60));
  -o-filter: drop-shadow(1px 1px 28px rgba(0,0,0,0.60));
  filter: drop-shadow(1px 1px 28px rgba(0,0,0,0.60));
}

.ridgid-grad {
  width: 100%;
  height: 110px;
  margin-top: -100px;
  margin-bottom: -50px;
  background-image:  url("img/_sons_of_sawdust/grad-bg.jpg");
}

// input[type="email"],
// input[type="number"],
// input[type="search"],
// input[type="text"],
// input[type="tel"],
// input[type="url"],
// input[type="password"],
// textarea,
// select {
//   color: #fff;
//   background: #f68f43;
//   border: 1px solid #fff;
//   box-shadow: inset 0 0 0 transparent;
// }

.button-ct:hover {
  background: #000;
}

.teamridgid-video__modal {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  width: 95%;
  max-width: 900px;
  min-height: 0 !important;
}

.modal {
  min-height: 0 !important;
}

.teamridgid-video__modal__video-wrapper {
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.teamridgid__story {
  margin-bottom: 60px;

  @include mq($from: "tablet") {
    margin-top: 50px;
    margin-bottom: 70px;
  }
}

.teamridgid__story__section-left {

  @include mq($from: "tablet") {
    float: left;
    width: calc(100% - 300px);
  }
}

.teamridgid__story__section-right {

  a {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    display: inline-block;
    cursor: pointer;

    @include mq($from: "tablet") {
      width: auto;
    }
  }

  @include mq($from: "tablet") {
    float: right;
    text-align: center;
    width: 300px;
  }
}

.teamridgid__story-header {
  margin-bottom: .5em;
}

.remodal[data-remodal-id='form-modal'] {
  padding: 0;
}