.underline-link {
  @include transition();
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding-bottom: .25em;

  &:after {
    @include transition();
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 2px;
    background-color: $orange;
  }

  &:hover {

    &:after {
      width: 100%;
      background-color: lighten($orange, 15);
    }
  }
}

.underline-link--light {
  &:hover {
    color: $white;
  }
}

.underline-link--bold {
  font-family: 'Open Sans Bold', sans-serif;
}